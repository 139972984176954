#cookie-law-info-bar {
    font-size: 15px;
    margin: 0 auto;
    padding: 12px 10px;
    position: absolute;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    z-index: 9999;
    display: none;
    left: 0px;
    font-weight: 300;
    box-shadow: 0 -1px 10px 0 rgba(172, 171, 171, 0.3);
}

#cookie-law-info-again {
    font-size: 10pt;
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    z-index: 9999;
    cursor: pointer;
    box-shadow: #161616 2px 2px 5px 2px;
}

#cookie-law-info-bar span {
    vertical-align: middle;
}

.cli-plugin-button,
.cli-plugin-button:visited {
    display: inline-block;
    padding: 9px 12px;
    color: #fff;
    text-decoration: none;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    margin-left: 5px;
}

.cli-plugin-main-link {
    margin-left: 0px;
    font-weight: 550;
    text-decoration: underline;
}

.cli-plugin-button:hover {
    background-color: #111;
    color: #fff;
    text-decoration: none;
}

.small.cli-plugin-button,
.small.cli-plugin-button:visited {
    font-size: 11px;
}

.cli-plugin-button,
.cli-plugin-button:visited,
.medium.cli-plugin-button,
.medium.cli-plugin-button:visited {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.large.cli-plugin-button,
.large.cli-plugin-button:visited {
    font-size: 14px;
    padding: 8px 14px 9px;
}

.super.cli-plugin-button,
.super.cli-plugin-button:visited {
    font-size: 34px;
    padding: 8px 14px 9px;
}

.pink.cli-plugin-button,
.magenta.cli-plugin-button:visited {
    background-color: #e22092;
}

.pink.cli-plugin-button:hover {
    background-color: #c81e82;
}

.green.cli-plugin-button,
.green.cli-plugin-button:visited {
    background-color: #91bd09;
}

.green.cli-plugin-button:hover {
    background-color: #749a02;
}

.red.cli-plugin-button,
.red.cli-plugin-button:visited {
    background-color: #e62727;
}

.red.cli-plugin-button:hover {
    background-color: #cf2525;
}

.orange.cli-plugin-button,
.orange.cli-plugin-button:visited {
    background-color: #ff5c00;
}

.orange.cli-plugin-button:hover {
    background-color: #d45500;
}

.blue.cli-plugin-button,
.blue.cli-plugin-button:visited {
    background-color: #2981e4;
}

.blue.cli-plugin-button:hover {
    background-color: #2575cf;
}

.yellow.cli-plugin-button,
.yellow.cli-plugin-button:visited {
    background-color: #ffb515;
}

.yellow.cli-plugin-button:hover {
    background-color: #fc9200;
}

.cli-plugin-button {
    margin-top: 5px;
}

.cli-bar-popup {
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 20px;
}

.cli-powered_by_p {
    width: 100% !important;
    display: block !important;
    color: #333;
    clear: both;
    font-style: italic !important;
    font-size: 12px !important;
    margin-top: 15px !important;
}

.cli-powered_by_a {
    color: #333;
    font-weight: 600 !important;
    font-size: 12px !important;
}

.cli-plugin-main-link.cli-plugin-button {
    text-decoration: none;
}

.cli-plugin-main-link.cli-plugin-button {
    margin-left: 5px;
}

.gdpr-container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.gdpr-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.gdpr-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.gdpr-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.gdpr-align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.gdpr-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.gdpr-px-0 {
    padding-left: 0;
    padding-right: 0;
}

.modal-backdrop.show {
    opacity: 0.8;
}

.modal-open {
    overflow: hidden;
}

.modal-open .gdpr-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.gdpr-modal.fade .gdpr-modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.gdpr-modal.show .gdpr-modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1039;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.gdpr-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.gdpr-modal a {
    text-decoration: none;
}

.gdpr-modal .gdpr-modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

@media (min-width: 576px) {
    .gdpr-modal .gdpr-modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
        min-height: calc(100% - (1.75rem * 2));
    }
}

@media (min-width: 992px) {
    .gdpr-modal .gdpr-modal-dialog {
        max-width: 900px;
    }
}

.gdpr-modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.gdpr-modal .row {
    margin: 0 -15px;
}

.gdpr-modal .modal-body {
    padding: 0;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.gdpr-modal .close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}

.gdpr-modal .close:focus {
    outline: 0;
}

.gdpr-switch {
    display: inline-block;
    position: relative;
    min-height: 1px;
    padding-left: 70px;
    font-size: 14px;
}

.gdpr-switch input[type='checkbox'] {
    display: none;
}

.gdpr-switch .gdpr-slider {
    background-color: #e3e1e8;
    height: 24px;
    width: 50px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.gdpr-switch .gdpr-slider:before {
    background-color: #fff;
    bottom: 2px;
    content: '';
    height: 20px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 20px;
}

.gdpr-switch input:checked + .gdpr-slider {
    background-color: #63b35f;
}

.gdpr-switch input:checked + .gdpr-slider:before {
    transform: translateX(26px);
}

.gdpr-switch .gdpr-slider {
    border-radius: 34px;
}

.gdpr-switch .gdpr-slider:before {
    border-radius: 50%;
}

.gdpr-tab-content > .gdpr-tab-pane {
    display: none;
}

.gdpr-tab-content > .active {
    display: block;
}

.gdpr-fade {
    transition: opacity 0.15s linear;
}

.gdpr-nav-pills {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    align-items: stretch !important;
    -ms-align-items: stretch !important;
}

.nav.gdpr-nav-pills,
.gdpr-tab-content {
    width: 100%;
    padding: 30px;
}

.nav.gdpr-nav-pills {
    background: #f3f3f3;
}

.nav.gdpr-nav-pills .gdpr-nav-link {
    border: 1px solid #0070ad;
    margin-bottom: 10px;
    color: #0070ad;
    font-size: 14px;
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.nav.gdpr-nav-pills .gdpr-nav-link.active,
.nav.gdpr-nav-pills .show > .gdpr-nav-link {
    background-color: #0070ad;
    border: 1px solid #0070ad;
}

.nav.gdpr-nav-pills .gdpr-nav-link.active {
    color: #fff;
}

.gdpr-tab-content .gdpr-button-wrapper {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #d6d6d6;
}

.gdpr-tab-content .gdpr-button-wrapper .btn-gdpr {
    background-color: #0070ad;
    border-color: #0070ad;
    color: #fff;
    font-size: 14px;
    font-size: 1rem;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.gdpr-tab-content p {
    color: #343438;
    font-size: 14px;
    margin-top: 0;
}

.gdpr-tab-content h4 {
    font-size: 20px;
    margin-bottom: 0.5rem;
    margin-top: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.cli-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.cli-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cli-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.cli-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.cli-align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.cli-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.cli-px-0 {
    padding-left: 0;
    padding-right: 0;
}

.cli-btn {
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 1.25rem;
    line-height: 1;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
}

.cli-btn:hover {
    opacity: 0.8;
}

.cli-read-more-link {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

.cli-btn:focus {
    outline: 0;
}

.cli-modal-backdrop.cli-show {
    opacity: 0.8;
}

.cli-modal-open {
    overflow: hidden;
}

.cli-barmodal-open {
    overflow: hidden;
}

.cli-modal-open .cli-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.cli-modal.cli-fade .cli-modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.cli-modal.cli-show .cli-modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.cli-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    display: none;
}

.cli-modal-backdrop.cli-fade {
    opacity: 0;
}

.cli-modal-backdrop.cli-show {
    opacity: 0.5;
    display: block;
}

.cli-modal.cli-show {
    display: block;
}

.cli-modal a {
    text-decoration: none;
}

.cli-modal .cli-modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

@media (min-width: 576px) {
    .cli-modal .cli-modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
        min-height: calc(100% - (1.75rem * 2));
    }
}

@media (min-width: 992px) {
    .cli-modal .cli-modal-dialog {
        max-width: 900px;
    }
}

.cli-modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
}

.cli-modal .row {
    margin: 0 -15px;
}

.cli-modal .modal-body {
    padding: 0;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.cli-modal .cli-modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    padding: 0;
    background-color: transparent !important;
    border: 0;
    -webkit-appearance: none;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
}

.cli-modal .cli-modal-close:focus {
    outline: 0;
}

.cli-switch {
    display: inline-block;
    position: relative;
    min-height: 1px;
    padding-left: 70px;
    font-size: 14px;
}

.cli-switch input[type='checkbox'] {
    display: none;
}

.cli-switch .cli-slider {
    background-color: #e3e1e8;
    height: 24px;
    width: 50px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.cli-switch .cli-slider:before {
    background-color: #fff;
    bottom: 2px;
    content: '';
    height: 20px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 20px;
}

.cli-switch input:checked + .cli-slider {
    background-color: #00acad;
}

.cli-switch input:checked + .cli-slider:before {
    transform: translateX(26px);
}

.cli-switch .cli-slider {
    border-radius: 34px;
}

.cli-switch .cli-slider:before {
    border-radius: 50%;
}

.cli-tab-content {
    background: #fff;
}

.cli-tab-content > .cli-active {
    display: block;
}

.cli-fade {
    transition: opacity 0.15s linear;
}

.cli-nav-pills {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    -ms-flex-direction: column;
    flex-direction: column;
}

.cli-nav-pills,
.cli-tab-content {
    width: 100%;
    padding: 30px;
}

@media (max-width: 767px) {
    .cli-nav-pills,
    .cli-tab-content {
        padding: 30px 10px;
    }
}

.cli-nav-pills {
    background: #f3f3f3;
}

.cli-nav-pills .cli-nav-link {
    border: 1px solid #00acad;
    margin-bottom: 10px;
    color: #00acad;
    font-size: 14px;
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.cli-nav-pills .cli-nav-link.cli-active,
.cli-nav-pills .cli-show > .cli-nav-link {
    background-color: #00acad;
    border: 1px solid #00acad;
}

.cli-nav-pills .cli-nav-link.cli-active {
    color: #fff;
}

.cli-tab-content .cli-button-wrapper {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #d6d6d6;
}

.cli-tab-content p {
    color: #343438;
    font-size: 14px;
    margin-top: 0;
}

.cli-tab-content h4 {
    font-size: 20px;
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.cli-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.cli-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cli-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.cli-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    flex-direction: column;
}

.cli-align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.cli-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}

.cli-px-0 {
    padding-left: 0;
    padding-right: 0;
}

.cli-btn {
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 1.25rem;
    line-height: 1;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;
}

.cli-btn:hover {
    opacity: 0.8;
}

.cli-read-more-link {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

.cli-btn:focus {
    outline: 0;
}

.cli-modal-backdrop.cli-show {
    opacity: 0.8;
}

.cli-modal-open {
    overflow: hidden;
}

.cli-barmodal-open {
    overflow: hidden;
}

.cli-modal-open .cli-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.cli-modal.cli-fade .cli-modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.cli-modal.cli-show .cli-modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.cli-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: opacity ease-in-out 0.5s;
}

.cli-modal-backdrop.cli-fade {
    opacity: 0;
}

.cli-modal-backdrop.cli-show {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.cli-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    transform: scale(0);
    overflow: hidden;
    outline: 0;
    display: none;
}

.cli-modal a {
    text-decoration: none;
}

.cli-modal .cli-modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

@media (min-width: 576px) {
    .cli-modal .cli-modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
        min-height: calc(100% - (1.75rem * 2));
    }
}

.cli-modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.2rem;
    box-sizing: border-box;
    outline: 0;
}

.cli-modal .row {
    margin: 0 -15px;
}

.cli-modal .modal-body {
    padding: 0;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.cli-modal .cli-modal-close:focus {
    outline: 0;
}

.cli-switch {
    display: inline-block;
    position: relative;
    min-height: 1px;
    padding-left: 38px;
    font-size: 14px;
}

.cli-switch input[type='checkbox'] {
    display: none;
}

.cli-switch .cli-slider {
    background-color: #e3e1e8;
    height: 20px;
    width: 38px;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.cli-switch .cli-slider:before {
    background-color: #fff;
    bottom: 2px;
    content: '';
    height: 15px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
    width: 15px;
}

.cli-switch input:checked + .cli-slider {
    background-color: #61a229;
}

.cli-switch input:checked + .cli-slider:before {
    transform: translateX(18px);
}

.cli-switch .cli-slider {
    border-radius: 34px;
    font-size: 0;
}

.cli-switch .cli-slider:before {
    border-radius: 50%;
}

.cli-tab-content {
    background: #fff;
}

.cli-nav-pills {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    -ms-flex-direction: column;
    flex-direction: column;
}

.cli-nav-pills,
.cli-tab-content {
    width: 100%;
    padding: 5px 30px 5px 5px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .cli-nav-pills,
    .cli-tab-content {
        padding: 30px 10px;
    }
}

.cli-nav-pills {
    background: #fff;
}

.cli-nav-pills .cli-nav-link {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    color: #2a2a2a;
    font-size: 14px;
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.cli-nav-pills .cli-nav-link.cli-active,
.cli-nav-pills .cli-show > .cli-nav-link {
    background-color: #f6f6f9;
    border: 1px solid #ccc;
}

.cli-nav-pills .cli-nav-link.cli-active {
    color: #2a2a2a;
}

.cli-tab-content .cli-button-wrapper {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #d6d6d6;
}

.cli-tab-content p {
    color: #343438;
    font-size: 14px;
    margin-top: 0;
}

.cli-tab-content h4 {
    font-size: 20px;
    margin-bottom: 1.5rem;
    margin-top: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

#cookie-law-info-bar .cli-nav-pills,
#cookie-law-info-bar .cli-tab-content,
#cookie-law-info-bar .cli-nav-pills .cli-show > .cli-nav-link,
#cookie-law-info-bar a.cli-nav-link.cli-active {
    background: transparent;
}

#cookie-law-info-bar .cli-nav-pills .cli-nav-link.cli-active,
#cookie-law-info-bar .cli-nav-link,
#cookie-law-info-bar .cli-tab-container p,
#cookie-law-info-bar span.cli-necessary-caption,
#cookie-law-info-bar .cli-switch .cli-slider:after {
    color: inherit;
}

#cookie-law-info-bar .cli-tab-header a:before {
    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
}

#cookie-law-info-bar .cli-row {
    margin-top: 20px;
}

#cookie-law-info-bar .cli-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

#cookie-law-info-bar .cli-col-8 {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.cli-wrapper {
    max-width: 90%;
    float: none;
    margin: 0 auto;
}

#cookie-law-info-bar .cli-tab-content h4 {
    margin-bottom: 0.5rem;
}

#cookie-law-info-bar .cli-nav-pills .cli-nav-link {
    padding: 0.2rem 0.5rem;
}

#cookie-law-info-bar .cli-tab-container {
    display: none;
    text-align: left;
}

.cli-tab-footer .cli-btn {
    background-color: #00acad;
    padding: 10px 15px;
    text-decoration: none;
}

.cli-tab-footer .wt-cli-privacy-accept-btn {
    background-color: #61a229;
    color: #fff;
    border-radius: 0;
}

.cli-tab-footer {
    width: 100%;
    text-align: right;
    padding: 20px 0;
}

.cli-col-12 {
    width: 100%;
}

.cli-tab-header {
    display: flex;
    justify-content: space-between;
}

.cli-tab-header a:before {
    width: 10px;
    height: 2px;
    left: 0;
    top: calc(50% - 1px);
}

.cli-tab-header a:after {
    width: 2px;
    height: 10px;
    left: 4px;
    top: calc(50% - 5px);
    -webkit-transform: none;
    transform: none;
}

.cli-tab-header a:before {
    width: 7px;
    height: 7px;
    border-right: 1px solid #4a6e78;
    border-bottom: 1px solid #4a6e78;
    content: ' ';
    transform: rotate(-45deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
}

.cli-tab-header a.cli-nav-link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    text-transform: capitalize;
}

.cli-tab-header.cli-tab-active .cli-nav-link:before {
    transform: rotate(45deg);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.cli-tab-header {
    border-radius: 5px;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease-out 0.3s, color 0.2s ease-out 0s;
    background-color: #f2f2f2;
}

.cli-modal .cli-modal-close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    -webkit-appearance: none;
    width: 40px;
    height: 40px;
    padding: 0;
    padding: 10px;
    border-radius: 50%;
    background: transparent;
    border: none;
    min-width: 40px;
}

.cli-tab-container h4,
.cli-tab-container h1 {
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 15px;
    margin: 10px 0;
}

#cliSettingsPopup .cli-tab-section-container {
    padding-top: 12px;
}

.cli-tab-container p,
.cli-privacy-content-text {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 0;
    padding: 0;
    color: #000;
}

.wt-cli-privacy {
    display: none;
}

.cli-tab-content {
    display: none;
}

.cli-tab-section .cli-tab-content {
    padding: 10px 20px 5px 20px;
}

.cli-tab-section {
    margin-top: 5px;
}

@media (min-width: 992px) {
    .cli-modal .cli-modal-dialog {
        max-width: 645px;
    }
}

.cli-switch .cli-slider:after {
    content: attr(data-cli-disable);
    position: absolute;
    right: 50px;
    color: #000;
    font-size: 12px;
    text-align: right;
    min-width: 80px;
}

.cli-switch input:checked + .cli-slider:after {
    content: attr(data-cli-enable);
}

.cli-privacy-overview:not(.cli-collapsed) .cli-privacy-content {
    max-height: 60px;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

a.cli-privacy-readmore {
    font-size: 12px;
    margin-top: 12px;
    display: inline-block;
    padding-bottom: 0;
    cursor: pointer;
    color: #000;
    text-decoration: underline;
}

.cli-modal-footer {
    position: relative;
}

a.cli-privacy-readmore:before {
    content: attr(data-readmore-text);
}

.cli-collapsed a.cli-privacy-readmore:before {
    content: attr(data-readless-text);
}

.cli-collapsed .cli-privacy-content {
    transition: max-height 0.25s ease-in;
}

.cli-privacy-content p {
    margin-bottom: 0;
}

.cli-modal-close svg {
    fill: #000;
}

span.cli-necessary-caption {
    color: #000;
    font-size: 12px;
}

.cli-tab-section.cli-privacy-tab {
    display: none;
}

#cookie-law-info-bar .cli-tab-section.cli-privacy-tab {
    display: block;
}

#cookie-law-info-bar .cli-privacy-overview {
    display: none;
}

.cli-tab-container .cli-row {
    max-height: 500px;
    overflow-y: auto;
}

.cli-modal.cli-blowup.cli-out {
    z-index: -1;
}

.cli-modal.cli-blowup {
    z-index: 999999;
    transform: scale(1);
}

.cli-modal.cli-blowup .cli-modal-dialog {
    animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.cli-modal.cli-blowup.cli-out .cli-modal-dialog {
    animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes blowUpContent {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    99.9% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(0);
    }
}

@keyframes blowUpContentTwo {
    0% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blowUpModalTwo {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.cli-tab-section .cookielawinfo-row-cat-table td,
.cli-tab-section .cookielawinfo-row-cat-table th {
    font-size: 12px;
}

.cli_settings_button {
    cursor: pointer;
}

.wt-cli-sr-only {
    display: none;
    font-size: 16px;
}

a.wt-cli-element.cli_cookie_close_button {
    text-decoration: none;
    color: #333;
    font-size: 22px;
    line-height: 22px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
}

.cli-bar-container {
    float: none;
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.cli-bar-btn_container {
    margin-left: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    flex-wrap: nowrap;
}

.cli-style-v2 a {
    cursor: pointer;
}

.cli-bar-btn_container a {
    white-space: nowrap;
}

.cli-style-v2 .cli-plugin-main-link {
    font-weight: inherit;
}

.cli-style-v2 {
    font-size: 11pt;
    line-height: 18px;
    font-weight: normal;
}

#cookie-law-info-bar[data-cli-type='widget'] .cli-bar-container,
#cookie-law-info-bar[data-cli-type='popup'] .cli-bar-container {
    display: block;
}

.cli-style-v2 .cli-bar-message {
    width: 70%;
    text-align: left;
}

#cookie-law-info-bar[data-cli-type='widget'] .cli-bar-message,
#cookie-law-info-bar[data-cli-type='popup'] .cli-bar-message {
    width: 100%;
}

#cookie-law-info-bar[data-cli-type='widget'] .cli-style-v2 .cli-bar-btn_container {
    margin-top: 8px;
    margin-left: 0px;
    flex-wrap: wrap;
}

#cookie-law-info-bar[data-cli-type='popup'] .cli-style-v2 .cli-bar-btn_container {
    margin-top: 8px;
    margin-left: 0px;
}

#cookie-law-info-bar[data-cli-style='cli-style-v2'] .cli_messagebar_head {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 16px;
}

.cli-style-v2 .cli-bar-message .wt-cli-ccpa-element,
.cli-style-v2 .cli-bar-message .wt-cli-ccpa-checkbox {
    margin-top: 5px;
}

.cli-style-v2 .cli-bar-btn_container .cli_action_button,
.cli-style-v2 .cli-bar-btn_container .cli-plugin-main-link,
.cli-style-v2 .cli-bar-btn_container .cli_settings_button {
    margin-left: 5px;
}

.wt-cli-ccpa-checkbox label {
    font-size: inherit;
    cursor: pointer;
    margin: 0px 0px 0px 5px;
}

#cookie-law-info-bar[data-cli-style='cli-style-v2'] {
    padding: 14px 25px;
}

#cookie-law-info-bar[data-cli-style='cli-style-v2'][data-cli-type='widget'] {
    padding: 32px 30px;
}

#cookie-law-info-bar[data-cli-style='cli-style-v2'][data-cli-type='popup'] {
    padding: 32px 45px;
}

.cli-style-v2 .cli-plugin-main-link:not(.cli-plugin-button),
.cli-style-v2 .cli_settings_button:not(.cli-plugin-button),
.cli-style-v2 .cli_action_button:not(.cli-plugin-button) {
    text-decoration: underline;
}

.cli-style-v2 .cli-bar-btn_container .cli-plugin-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

a.wt-cli-ccpa-opt-out {
    white-space: nowrap;
    text-decoration: underline;
}

.wt-cli-necessary-checkbox {
    display: none !important;
}

@media (max-width: 985px) {
    .cli-style-v2 .cli-bar-message {
        width: 100%;
    }

    .cli-style-v2.cli-bar-container {
        justify-content: left;
        flex-wrap: wrap;
    }

    .cli-style-v2 .cli-bar-btn_container {
        margin-left: 0px;
        margin-top: 10px;
    }

    #cookie-law-info-bar[data-cli-style='cli-style-v2'],
    #cookie-law-info-bar[data-cli-style='cli-style-v2'][data-cli-type='widget'],
    #cookie-law-info-bar[data-cli-style='cli-style-v2'][data-cli-type='popup'] {
        padding: 25px 25px;
    }
}

.wt-cli-ckyes-brand-logo {
    display: flex;
    align-items: center;
    font-size: 9px;
    color: #111;
    font-weight: normal;
}

.wt-cli-ckyes-brand-logo img {
    width: 65px;
    margin-left: 2px;
}

.wt-cli-privacy-overview-actions {
    padding-bottom: 0;
}

@media only screen and (max-width: 479px) and (min-width: 320px) {
    .cli-style-v2 .cli-bar-btn_container {
        flex-wrap: wrap;
    }
}

.wt-cli-cookie-description {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 0;
    padding: 0;
    color: #000;
}

.disblock {
    display: block;
}

input[type='password'].som-password-input {
    max-width: 400px;
}

.som-password-sent-message {
    margin-bottom: 50px;
    padding: 15px;
    border: 2px solid #2679ce;
    border-radius: 4px;
    background-color: #2679ce;
    color: #fff;
    position: relative;
    display: table;
    width: 100%;
}

.lostpassword-submit {
    margin-bottom: 2rem;
}

.som-password-error-message {
    background-color: #2679ce;
    border-color: #2679ce;
    padding-left: 10px;
}

.som-password-sent-message span {
    display: table-cell;
    vertical-align: middle;
}

.sompass-submit-loading {
    display: inline-block;
    display: none;
}

.sompass-submit-loading.submitted {
    display: inline-block;
    padding-left: 10px;
    height: 50px;
    line-height: 50px;
}

.sompass-submit-loading img {
    display: inline;
}

#lostpasswordform #somfrp_user_info {
    display: block;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

ul[class],
ol[class] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

img {
    max-width: 100%;
    display: block;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal;
}

.my-lg {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

@media (max-width: 767.98px) {
    .my-lg {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
}

.my-xl {
    margin-top: 10.75rem;
    margin-bottom: 10.75rem;
}

@media (max-width: 767.98px) {
    .my-xl {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
}

.mt-h {
    margin-top: 0.5rem;
}

.mt-3q {
    margin-top: 0.75rem;
}

.mt-1r {
    margin-top: 1rem;
}

.mt-15 {
    margin-top: 1.575rem;
}

.mt-0 {
    margin-top: 0;
}

.mt-sm {
    margin-top: 2.5rem;
}

@media (max-width: 767.98px) {
    .mt-sm {
        margin-top: 3.5rem;
    }
}

.mt-md,
.block-clients-columns ul.list-columns {
    margin-top: 4.65rem;
}

@media (max-width: 767.98px) {
    .mt-md,
    .block-clients-columns ul.list-columns {
        margin-top: 3.5rem;
    }
}

.mt-lg {
    margin-top: 7rem;
}

@media (max-width: 767.98px) {
    .mt-lg {
        margin-top: 5rem;
    }
}

.mt-xl {
    margin-top: 10.75rem;
}

@media (max-width: 767.98px) {
    .mt-xl {
        margin-top: 8rem;
    }
}

.mt-xxl {
    margin-top: 14rem;
}

@media (max-width: 767.98px) {
    .mt-xxl {
        margin-top: 10rem;
    }
}

.mb-0 {
    margin-bottom: 0;
}

.mb-q {
    margin-bottom: 0.25rem;
}

.mb-h {
    margin-bottom: 0.5rem;
}

.mb-3q {
    margin-bottom: 0.75rem;
}

.mb-1hr {
    margin-bottom: 0.75rem;
}

.mb-1r {
    margin-bottom: 1rem;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-xs {
    margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
    .mb-xs {
        margin-bottom: 1rem;
    }
}

.mb-sm {
    margin-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
    .mb-sm {
        margin-bottom: 2.5rem;
    }
}

.mb-md {
    margin-bottom: 4.65rem;
}

@media (max-width: 767.98px) {
    .mb-md {
        margin-bottom: 3.5rem;
    }
}

.mb-lg {
    margin-bottom: 7rem;
}

@media (max-width: 767.98px) {
    .mb-lg {
        margin-bottom: 5rem;
    }
}

.mb-xl {
    margin-bottom: 10.75rem;
}

@media (max-width: 767.98px) {
    .mb-xl {
        margin-bottom: 8rem;
    }
}

.mb-xxl {
    margin-bottom: 14rem;
}

@media (max-width: 767.98px) {
    .mb-xxl {
        margin-bottom: 10rem;
    }
}

.mx-auto,
.footer-sticky .footer-sticky-logo img,
.footer-sticky .footer-sticky-logo video {
    margin-left: auto;
    margin-right: auto;
}

.py-1r {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.py-w {
    padding-top: 3.575rem;
    padding-bottom: 3.575rem;
}

@media (max-width: 767.98px) {
    .py-w {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.py-sm,
.block-logo-grid .grid .grid-item {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
    .py-sm,
    .block-logo-grid .grid .grid-item {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

.py-md {
    padding-top: 4.65rem;
    padding-bottom: 4.65rem;
}

@media (max-width: 767.98px) {
    .py-md {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
}

.py-lg {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

@media (max-width: 767.98px) {
    .py-lg {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

.py-xl {
    padding-top: 10.75rem;
    padding-bottom: 10.75rem;
}

@media (max-width: 767.98px) {
    .py-xl {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.py-xxl,
.block-statement {
    padding-top: 14rem;
    padding-bottom: 14rem;
}

@media (max-width: 767.98px) {
    .py-xxl,
    .block-statement {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
}

.pt-g {
    padding-top: 2rem;
}

@media (max-width: 767.98px) {
    .pt-g {
        padding-top: 1.25rem;
    }
}

.pt-1r {
    padding-top: 1rem;
}

.pt-sm,
.distributor-item + .distributor-item {
    padding-top: 2.5rem;
}

@media (max-width: 767.98px) {
    .pt-sm,
    .distributor-item + .distributor-item {
        padding-top: 2.5rem;
    }
}

.pt-md {
    padding-top: 4.65rem;
}

@media (max-width: 767.98px) {
    .pt-md {
        padding-top: 3.5rem;
    }
}

.pt-lg {
    padding-top: 7rem;
}

@media (max-width: 767.98px) {
    .pt-lg {
        padding-top: 5rem;
    }
}

.pt-xl {
    padding-top: 10.75rem;
}

@media (max-width: 767.98px) {
    .pt-xl {
        padding-top: 8rem;
    }
}

.pt-xxl {
    padding-top: 14rem;
}

@media (max-width: 767.98px) {
    .pt-xxl {
        padding-top: 10rem;
    }
}

.pb-1r {
    padding-bottom: 1rem;
}

.pb-xs {
    padding-bottom: 1rem;
}

@media (max-width: 767.98px) {
    .pb-xs {
        padding-bottom: 1rem;
    }
}

.pb-sm {
    padding-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
    .pb-sm {
        padding-bottom: 2.5rem;
    }
}

.pb-md {
    padding-bottom: 4.65rem;
}

@media (max-width: 767.98px) {
    .pb-md {
        padding-bottom: 3.5rem;
    }
}

.pb-lg,
.single-products .block-wide-image + .block-heading {
    padding-bottom: 7rem;
}

@media (max-width: 767.98px) {
    .pb-lg,
    .single-products .block-wide-image + .block-heading {
        padding-bottom: 5rem;
    }
}

.pb-xl {
    padding-bottom: 10.75rem;
}

@media (max-width: 767.98px) {
    .pb-xl {
        padding-bottom: 8rem;
    }
}

.pb-xxl {
    padding-bottom: 14rem;
}

@media (max-width: 767.98px) {
    .pb-xxl {
        padding-bottom: 10rem;
    }
}

.px-hg,
.flex-grid.blog-grid .flex-grid-item.item-post {
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (max-width: 767.98px) {
    .px-hg,
    .flex-grid.blog-grid .flex-grid-item.item-post {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
}

.px-g {
    padding-left: 2rem;
    padding-right: 2rem;
}

@media (max-width: 767.98px) {
    .px-g {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.px-sm {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

@media (max-width: 767.98px) {
    .px-sm {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
}

.pl-1r {
    padding-left: 1rem;
}

.pr-1r {
    padding-right: 1rem;
}

.px-md {
    padding-left: 4.65rem;
    padding-right: 4.65rem;
}

@media (max-width: 767.98px) {
    .px-md {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}

.px-lg {
    padding-left: 7rem;
    padding-right: 7rem;
}

@media (max-width: 767.98px) {
    .px-lg {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.px-w {
    padding-left: 2rem;
    padding-right: 2rem;
}

@media (max-width: 767.98px) {
    .px-w {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.pxy-md {
    padding: 4.65rem;
}

@media (max-width: 767.98px) {
    .pxy-md {
        padding: 4.65rem 2.5rem;
    }
}

.pxy-sm {
    padding: 2.5rem;
}

@media (max-width: 767.98px) {
    .pxy-sm {
        padding: 2.5rem 2.5rem;
    }
}

.pxy-lg {
    padding: 7rem;
}

@media (max-width: 767.98px) {
    .pxy-lg {
        padding: 7rem 2.5rem;
    }
}

.pxy-w {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3.575rem;
    padding-bottom: 3.575rem;
}

@media (max-width: 767.98px) {
    .pxy-w {
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.flickity-button {
    position: absolute;
    background: rgba(255, 255, 255, 0.75);
    border: none;
    color: #333;
}

.flickity-button:hover {
    background: white;
    cursor: pointer;
}

.flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
    display: none;
}

.flickity-button-icon {
    fill: currentColor;
}

.flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
    left: 10px;
}

.flickity-prev-next-button.next {
    right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

.flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 2rem;
    height: 2px;
    margin: 0 8px;
    background: #333;
    border-radius: 3px;
    opacity: 0.25;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
}

.fa,
.fas,
.far,
.fal,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-lg {
    font-size: 1.3333333333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
}

.fa-xs {
    font-size: 0.75em;
}

.fa-sm {
    font-size: 0.875em;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}

.fa-ul > li {
    position: relative;
}

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
}

.fa-border {
    border: solid 0.08em #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
    float: left;
}

.fa-pull-right {
    float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
    margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
    margin-left: 0.3em;
}

.fa-spin {
    animation: fa-spin 2s infinite linear;
}

.fa-pulse {
    animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-rotate-90 {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
    transform: rotate(90deg);
}

.fa-rotate-180 {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
    transform: rotate(180deg);
}

.fa-rotate-270 {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
    transform: scale(-1, 1);
}

.fa-flip-vertical {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
    transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
    transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
    filter: none;
}

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.fa-stack-1x {
    line-height: inherit;
}

.fa-stack-2x {
    font-size: 2em;
}

.fa-inverse {
    color: #fff;
}

.fa-500px:before {
    content: 'ï‰®';
}

.fa-abacus:before {
    content: 'ï™€';
}

.fa-accessible-icon:before {
    content: 'ï¨';
}

.fa-accusoft:before {
    content: 'ï©';
}

.fa-acorn:before {
    content: 'ïš®';
}

.fa-acquisitions-incorporated:before {
    content: 'ïš¯';
}

.fa-ad:before {
    content: 'ï™';
}

.fa-address-book:before {
    content: 'ïŠ¹';
}

.fa-address-card:before {
    content: 'ïŠ»';
}

.fa-adjust:before {
    content: 'ï‚';
}

.fa-adn:before {
    content: 'ï…°';
}

.fa-adobe:before {
    content: 'ï¸';
}

.fa-adversal:before {
    content: 'ïª';
}

.fa-affiliatetheme:before {
    content: 'ï«';
}

.fa-air-freshener:before {
    content: 'ï—';
}

.fa-alarm-clock:before {
    content: 'ïŽ';
}

.fa-algolia:before {
    content: 'ï¬';
}

.fa-alicorn:before {
    content: 'ïš°';
}

.fa-align-center:before {
    content: 'ï€·';
}

.fa-align-justify:before {
    content: 'ï€¹';
}

.fa-align-left:before {
    content: 'ï€¶';
}

.fa-align-right:before {
    content: 'ï€¸';
}

.fa-alipay:before {
    content: 'ï™‚';
}

.fa-allergies:before {
    content: 'ï‘¡';
}

.fa-amazon:before {
    content: 'ï‰°';
}

.fa-amazon-pay:before {
    content: 'ï¬';
}

.fa-ambulance:before {
    content: 'ïƒ¹';
}

.fa-american-sign-language-interpreting:before {
    content: 'ïŠ£';
}

.fa-amilia:before {
    content: 'ï­';
}

.fa-analytics:before {
    content: 'ï™ƒ';
}

.fa-anchor:before {
    content: 'ï„½';
}

.fa-android:before {
    content: 'ï…»';
}

.fa-angel:before {
    content: 'ï¹';
}

.fa-angellist:before {
    content: 'ïˆ‰';
}

.fa-angle-double-down:before {
    content: 'ï„ƒ';
}

.fa-angle-double-left:before {
    content: 'ï„€';
}

.fa-angle-double-right:before {
    content: 'ï„';
}

.fa-angle-double-up:before {
    content: 'ï„‚';
}

.fa-angle-down:before {
    content: 'ï„‡';
}

.fa-angle-left:before {
    content: 'ï„„';
}

.fa-angle-right:before {
    content: 'ï„…';
}

.fa-angle-up:before {
    content: 'ï„†';
}

.fa-angry:before {
    content: 'ï•–';
}

.fa-angrycreative:before {
    content: 'ï®';
}

.fa-angular:before {
    content: 'ï ';
}

.fa-ankh:before {
    content: 'ï™„';
}

.fa-app-store:before {
    content: 'ï¯';
}

.fa-app-store-ios:before {
    content: 'ï°';
}

.fa-apper:before {
    content: 'ï±';
}

.fa-apple:before {
    content: 'ï…¹';
}

.fa-apple-alt:before {
    content: 'ï—‘';
}

.fa-apple-crate:before {
    content: 'ïš±';
}

.fa-apple-pay:before {
    content: 'ï•';
}

.fa-archive:before {
    content: 'ï†‡';
}

.fa-archway:before {
    content: 'ï•—';
}

.fa-arrow-alt-circle-down:before {
    content: 'ï˜';
}

.fa-arrow-alt-circle-left:before {
    content: 'ï™';
}

.fa-arrow-alt-circle-right:before {
    content: 'ïš';
}

.fa-arrow-alt-circle-up:before {
    content: 'ï›';
}

.fa-arrow-alt-down:before {
    content: 'ï”';
}

.fa-arrow-alt-from-bottom:before {
    content: 'ï†';
}

.fa-arrow-alt-from-left:before {
    content: 'ï‡';
}

.fa-arrow-alt-from-right:before {
    content: 'ïˆ';
}

.fa-arrow-alt-from-top:before {
    content: 'ï‰';
}

.fa-arrow-alt-left:before {
    content: 'ï•';
}

.fa-arrow-alt-right:before {
    content: 'ï–';
}

.fa-arrow-alt-square-down:before {
    content: 'ï';
}

.fa-arrow-alt-square-left:before {
    content: 'ï‘';
}

.fa-arrow-alt-square-right:before {
    content: 'ï’';
}

.fa-arrow-alt-square-up:before {
    content: 'ï“';
}

.fa-arrow-alt-to-bottom:before {
    content: 'ïŠ';
}

.fa-arrow-alt-to-left:before {
    content: 'ï‹';
}

.fa-arrow-alt-to-right:before {
    content: 'ïŒ';
}

.fa-arrow-alt-to-top:before {
    content: 'ï';
}

.fa-arrow-alt-up:before {
    content: 'ï—';
}

.fa-arrow-circle-down:before {
    content: 'ï‚«';
}

.fa-arrow-circle-left:before {
    content: 'ï‚¨';
}

.fa-arrow-circle-right:before {
    content: 'ï‚©';
}

.fa-arrow-circle-up:before {
    content: 'ï‚ª';
}

.fa-arrow-down:before {
    content: 'ï£';
}

.fa-arrow-from-bottom:before {
    content: 'ï‚';
}

.fa-arrow-from-left:before {
    content: 'ïƒ';
}

.fa-arrow-from-right:before {
    content: 'ï„';
}

.fa-arrow-from-top:before {
    content: 'ï…';
}

.fa-arrow-left:before {
    content: 'ï ';
}

.fa-arrow-right:before {
    content: 'ï¡';
}

.fa-arrow-square-down:before {
    content: 'ïŒ¹';
}

.fa-arrow-square-left:before {
    content: 'ïŒº';
}

.fa-arrow-square-right:before {
    content: 'ïŒ»';
}

.fa-arrow-square-up:before {
    content: 'ïŒ¼';
}

.fa-arrow-to-bottom:before {
    content: 'ïŒ½';
}

.fa-arrow-to-left:before {
    content: 'ïŒ¾';
}

.fa-arrow-to-right:before {
    content: 'ï€';
}

.fa-arrow-to-top:before {
    content: 'ï';
}

.fa-arrow-up:before {
    content: 'ï¢';
}

.fa-arrows:before {
    content: 'ï‡';
}

.fa-arrows-alt:before {
    content: 'ï‚²';
}

.fa-arrows-alt-h:before {
    content: 'ïŒ·';
}

.fa-arrows-alt-v:before {
    content: 'ïŒ¸';
}

.fa-arrows-h:before {
    content: 'ï¾';
}

.fa-arrows-v:before {
    content: 'ï½';
}

.fa-artstation:before {
    content: 'ïº';
}

.fa-assistive-listening-systems:before {
    content: 'ïŠ¢';
}

.fa-asterisk:before {
    content: 'ï©';
}

.fa-asymmetrik:before {
    content: 'ï²';
}

.fa-at:before {
    content: 'ï‡º';
}

.fa-atlas:before {
    content: 'ï•˜';
}

.fa-atlassian:before {
    content: 'ï»';
}

.fa-atom:before {
    content: 'ï—’';
}

.fa-atom-alt:before {
    content: 'ï—“';
}

.fa-audible:before {
    content: 'ï³';
}

.fa-audio-description:before {
    content: 'ïŠž';
}

.fa-autoprefixer:before {
    content: 'ïœ';
}

.fa-avianex:before {
    content: 'ï´';
}

.fa-aviato:before {
    content: 'ï¡';
}

.fa-award:before {
    content: 'ï•™';
}

.fa-aws:before {
    content: 'ïµ';
}

.fa-axe:before {
    content: 'ïš²';
}

.fa-axe-battle:before {
    content: 'ïš³';
}

.fa-baby:before {
    content: 'ï¼';
}

.fa-baby-carriage:before {
    content: 'ï½';
}

.fa-backpack:before {
    content: 'ï—”';
}

.fa-backspace:before {
    content: 'ï•š';
}

.fa-backward:before {
    content: 'ïŠ';
}

.fa-bacon:before {
    content: 'ïŸ¥';
}

.fa-badge:before {
    content: 'ïŒµ';
}

.fa-badge-check:before {
    content: 'ïŒ¶';
}

.fa-badge-dollar:before {
    content: 'ï™…';
}

.fa-badge-percent:before {
    content: 'ï™†';
}

.fa-badger-honey:before {
    content: 'ïš´';
}

.fa-balance-scale:before {
    content: 'ï‰Ž';
}

.fa-balance-scale-left:before {
    content: 'ï”•';
}

.fa-balance-scale-right:before {
    content: 'ï”–';
}

.fa-ball-pile:before {
    content: 'ï¾';
}

.fa-ballot:before {
    content: 'ïœ²';
}

.fa-ballot-check:before {
    content: 'ïœ³';
}

.fa-ban:before {
    content: 'ïž';
}

.fa-band-aid:before {
    content: 'ï‘¢';
}

.fa-bandcamp:before {
    content: 'ï‹•';
}

.fa-barcode:before {
    content: 'ï€ª';
}

.fa-barcode-alt:before {
    content: 'ï‘£';
}

.fa-barcode-read:before {
    content: 'ï‘¤';
}

.fa-barcode-scan:before {
    content: 'ï‘¥';
}

.fa-bars:before {
    content: 'ïƒ‰';
}

.fa-baseball:before {
    content: 'ï²';
}

.fa-baseball-ball:before {
    content: 'ï³';
}

.fa-basketball-ball:before {
    content: 'ï´';
}

.fa-basketball-hoop:before {
    content: 'ïµ';
}

.fa-bat:before {
    content: 'ïšµ';
}

.fa-bath:before {
    content: 'ï‹';
}

.fa-battery-bolt:before {
    content: 'ï¶';
}

.fa-battery-empty:before {
    content: 'ï‰„';
}

.fa-battery-full:before {
    content: 'ï‰€';
}

.fa-battery-half:before {
    content: 'ï‰‚';
}

.fa-battery-quarter:before {
    content: 'ï‰ƒ';
}

.fa-battery-slash:before {
    content: 'ï·';
}

.fa-battery-three-quarters:before {
    content: 'ï‰';
}

.fa-bed:before {
    content: 'ïˆ¶';
}

.fa-beer:before {
    content: 'ïƒ¼';
}

.fa-behance:before {
    content: 'ï†´';
}

.fa-behance-square:before {
    content: 'ï†µ';
}

.fa-bell:before {
    content: 'ïƒ³';
}

.fa-bell-school:before {
    content: 'ï—•';
}

.fa-bell-school-slash:before {
    content: 'ï—–';
}

.fa-bell-slash:before {
    content: 'ï‡¶';
}

.fa-bells:before {
    content: 'ï¿';
}

.fa-bezier-curve:before {
    content: 'ï•›';
}

.fa-bible:before {
    content: 'ï™‡';
}

.fa-bicycle:before {
    content: 'ïˆ†';
}

.fa-bimobject:before {
    content: 'ï¸';
}

.fa-binoculars:before {
    content: 'ï‡¥';
}

.fa-biohazard:before {
    content: 'ïž€';
}

.fa-birthday-cake:before {
    content: 'ï‡½';
}

.fa-bitbucket:before {
    content: 'ï…±';
}

.fa-bitcoin:before {
    content: 'ï¹';
}

.fa-bity:before {
    content: 'ïº';
}

.fa-black-tie:before {
    content: 'ï‰¾';
}

.fa-blackberry:before {
    content: 'ï»';
}

.fa-blanket:before {
    content: 'ï’˜';
}

.fa-blender:before {
    content: 'ï”—';
}

.fa-blender-phone:before {
    content: 'ïš¶';
}

.fa-blind:before {
    content: 'ïŠ';
}

.fa-blog:before {
    content: 'ïž';
}

.fa-blogger:before {
    content: 'ï¼';
}

.fa-blogger-b:before {
    content: 'ï½';
}

.fa-bluetooth:before {
    content: 'ïŠ“';
}

.fa-bluetooth-b:before {
    content: 'ïŠ”';
}

.fa-bold:before {
    content: 'ï€²';
}

.fa-bolt:before {
    content: 'ïƒ§';
}

.fa-bomb:before {
    content: 'ï‡¢';
}

.fa-bone:before {
    content: 'ï——';
}

.fa-bone-break:before {
    content: 'ï—˜';
}

.fa-bong:before {
    content: 'ï•œ';
}

.fa-book:before {
    content: 'ï€­';
}

.fa-book-alt:before {
    content: 'ï—™';
}

.fa-book-dead:before {
    content: 'ïš·';
}

.fa-book-heart:before {
    content: 'ï’™';
}

.fa-book-medical:before {
    content: 'ïŸ¦';
}

.fa-book-open:before {
    content: 'ï”˜';
}

.fa-book-reader:before {
    content: 'ï—š';
}

.fa-book-spells:before {
    content: 'ïš¸';
}

.fa-book-user:before {
    content: 'ïŸ§';
}

.fa-bookmark:before {
    content: 'ï€®';
}

.fa-books:before {
    content: 'ï—›';
}

.fa-books-medical:before {
    content: 'ïŸ¨';
}

.fa-boot:before {
    content: 'ïž‚';
}

.fa-booth-curtain:before {
    content: 'ïœ´';
}

.fa-bow-arrow:before {
    content: 'ïš¹';
}

.fa-bowling-ball:before {
    content: 'ï¶';
}

.fa-bowling-pins:before {
    content: 'ï·';
}

.fa-box:before {
    content: 'ï‘¦';
}

.fa-box-alt:before {
    content: 'ï’š';
}

.fa-box-ballot:before {
    content: 'ïœµ';
}

.fa-box-check:before {
    content: 'ï‘§';
}

.fa-box-fragile:before {
    content: 'ï’›';
}

.fa-box-full:before {
    content: 'ï’œ';
}

.fa-box-heart:before {
    content: 'ï’';
}

.fa-box-open:before {
    content: 'ï’ž';
}

.fa-box-up:before {
    content: 'ï’Ÿ';
}

.fa-box-usd:before {
    content: 'ï’ ';
}

.fa-boxes:before {
    content: 'ï‘¨';
}

.fa-boxes-alt:before {
    content: 'ï’¡';
}

.fa-boxing-glove:before {
    content: 'ï¸';
}

.fa-brackets:before {
    content: 'ïŸ©';
}

.fa-brackets-curly:before {
    content: 'ïŸª';
}

.fa-braille:before {
    content: 'ïŠ¡';
}

.fa-brain:before {
    content: 'ï—œ';
}

.fa-bread-loaf:before {
    content: 'ïŸ«';
}

.fa-bread-slice:before {
    content: 'ïŸ¬';
}

.fa-briefcase:before {
    content: 'ï‚±';
}

.fa-briefcase-medical:before {
    content: 'ï‘©';
}

.fa-broadcast-tower:before {
    content: 'ï”™';
}

.fa-broom:before {
    content: 'ï”š';
}

.fa-browser:before {
    content: 'ï¾';
}

.fa-brush:before {
    content: 'ï•';
}

.fa-btc:before {
    content: 'ï…š';
}

.fa-bug:before {
    content: 'ï†ˆ';
}

.fa-building:before {
    content: 'ï†­';
}

.fa-bullhorn:before {
    content: 'ï‚¡';
}

.fa-bullseye:before {
    content: 'ï…€';
}

.fa-bullseye-arrow:before {
    content: 'ï™ˆ';
}

.fa-bullseye-pointer:before {
    content: 'ï™‰';
}

.fa-burn:before {
    content: 'ï‘ª';
}

.fa-buromobelexperte:before {
    content: 'ï¿';
}

.fa-burrito:before {
    content: 'ïŸ­';
}

.fa-bus:before {
    content: 'ïˆ‡';
}

.fa-bus-alt:before {
    content: 'ï•ž';
}

.fa-bus-school:before {
    content: 'ï—';
}

.fa-business-time:before {
    content: 'ï™Š';
}

.fa-buysellads:before {
    content: 'ïˆ';
}

.fa-cabinet-filing:before {
    content: 'ï™‹';
}

.fa-calculator:before {
    content: 'ï‡¬';
}

.fa-calculator-alt:before {
    content: 'ï™Œ';
}

.fa-calendar:before {
    content: 'ï„³';
}

.fa-calendar-alt:before {
    content: 'ï³';
}

.fa-calendar-check:before {
    content: 'ï‰´';
}

.fa-calendar-day:before {
    content: 'ïžƒ';
}

.fa-calendar-edit:before {
    content: 'ïŒ³';
}

.fa-calendar-exclamation:before {
    content: 'ïŒ´';
}

.fa-calendar-minus:before {
    content: 'ï‰²';
}

.fa-calendar-plus:before {
    content: 'ï‰±';
}

.fa-calendar-star:before {
    content: 'ïœ¶';
}

.fa-calendar-times:before {
    content: 'ï‰³';
}

.fa-calendar-week:before {
    content: 'ïž„';
}

.fa-camera:before {
    content: 'ï€°';
}

.fa-camera-alt:before {
    content: 'ïŒ²';
}

.fa-camera-retro:before {
    content: 'ï‚ƒ';
}

.fa-campfire:before {
    content: 'ïšº';
}

.fa-campground:before {
    content: 'ïš»';
}

.fa-canadian-maple-leaf:before {
    content: 'ïž…';
}

.fa-candle-holder:before {
    content: 'ïš¼';
}

.fa-candy-cane:before {
    content: 'ïž†';
}

.fa-candy-corn:before {
    content: 'ïš½';
}

.fa-cannabis:before {
    content: 'ï•Ÿ';
}

.fa-capsules:before {
    content: 'ï‘«';
}

.fa-car:before {
    content: 'ï†¹';
}

.fa-car-alt:before {
    content: 'ï—ž';
}

.fa-car-battery:before {
    content: 'ï—Ÿ';
}

.fa-car-bump:before {
    content: 'ï— ';
}

.fa-car-crash:before {
    content: 'ï—¡';
}

.fa-car-garage:before {
    content: 'ï—¢';
}

.fa-car-mechanic:before {
    content: 'ï—£';
}

.fa-car-side:before {
    content: 'ï—¤';
}

.fa-car-tilt:before {
    content: 'ï—¥';
}

.fa-car-wash:before {
    content: 'ï—¦';
}

.fa-caret-circle-down:before {
    content: 'ïŒ­';
}

.fa-caret-circle-left:before {
    content: 'ïŒ®';
}

.fa-caret-circle-right:before {
    content: 'ïŒ°';
}

.fa-caret-circle-up:before {
    content: 'ïŒ±';
}

.fa-caret-down:before {
    content: 'ïƒ—';
}

.fa-caret-left:before {
    content: 'ïƒ™';
}

.fa-caret-right:before {
    content: 'ïƒš';
}

.fa-caret-square-down:before {
    content: 'ï…';
}

.fa-caret-square-left:before {
    content: 'ï†‘';
}

.fa-caret-square-right:before {
    content: 'ï…’';
}

.fa-caret-square-up:before {
    content: 'ï…‘';
}

.fa-caret-up:before {
    content: 'ïƒ˜';
}

.fa-carrot:before {
    content: 'ïž‡';
}

.fa-cart-arrow-down:before {
    content: 'ïˆ˜';
}

.fa-cart-plus:before {
    content: 'ïˆ—';
}

.fa-cash-register:before {
    content: 'ïžˆ';
}

.fa-cat:before {
    content: 'ïš¾';
}

.fa-cauldron:before {
    content: 'ïš¿';
}

.fa-cc-amazon-pay:before {
    content: 'ï­';
}

.fa-cc-amex:before {
    content: 'ï‡³';
}

.fa-cc-apple-pay:before {
    content: 'ï–';
}

.fa-cc-diners-club:before {
    content: 'ï‰Œ';
}

.fa-cc-discover:before {
    content: 'ï‡²';
}

.fa-cc-jcb:before {
    content: 'ï‰‹';
}

.fa-cc-mastercard:before {
    content: 'ï‡±';
}

.fa-cc-paypal:before {
    content: 'ï‡´';
}

.fa-cc-stripe:before {
    content: 'ï‡µ';
}

.fa-cc-visa:before {
    content: 'ï‡°';
}

.fa-centercode:before {
    content: 'ïŽ€';
}

.fa-centos:before {
    content: 'ïž‰';
}

.fa-certificate:before {
    content: 'ï‚£';
}

.fa-chair:before {
    content: 'ï›€';
}

.fa-chair-office:before {
    content: 'ï›';
}

.fa-chalkboard:before {
    content: 'ï”›';
}

.fa-chalkboard-teacher:before {
    content: 'ï”œ';
}

.fa-charging-station:before {
    content: 'ï—§';
}

.fa-chart-area:before {
    content: 'ï‡¾';
}

.fa-chart-bar:before {
    content: 'ï‚€';
}

.fa-chart-line:before {
    content: 'ïˆ';
}

.fa-chart-line-down:before {
    content: 'ï™';
}

.fa-chart-network:before {
    content: 'ïžŠ';
}

.fa-chart-pie:before {
    content: 'ïˆ€';
}

.fa-chart-pie-alt:before {
    content: 'ï™Ž';
}

.fa-chart-scatter:before {
    content: 'ïŸ®';
}

.fa-check:before {
    content: 'ï€Œ';
}

.fa-check-circle:before {
    content: 'ï˜';
}

.fa-check-double:before {
    content: 'ï• ';
}

.fa-check-square:before {
    content: 'ï…Š';
}

.fa-cheese:before {
    content: 'ïŸ¯';
}

.fa-cheese-swiss:before {
    content: 'ïŸ°';
}

.fa-cheeseburger:before {
    content: 'ïŸ±';
}

.fa-chess:before {
    content: 'ï¹';
}

.fa-chess-bishop:before {
    content: 'ïº';
}

.fa-chess-bishop-alt:before {
    content: 'ï»';
}

.fa-chess-board:before {
    content: 'ï¼';
}

.fa-chess-clock:before {
    content: 'ï½';
}

.fa-chess-clock-alt:before {
    content: 'ï¾';
}

.fa-chess-king:before {
    content: 'ï¿';
}

.fa-chess-king-alt:before {
    content: 'ï‘€';
}

.fa-chess-knight:before {
    content: 'ï‘';
}

.fa-chess-knight-alt:before {
    content: 'ï‘‚';
}

.fa-chess-pawn:before {
    content: 'ï‘ƒ';
}

.fa-chess-pawn-alt:before {
    content: 'ï‘„';
}

.fa-chess-queen:before {
    content: 'ï‘…';
}

.fa-chess-queen-alt:before {
    content: 'ï‘†';
}

.fa-chess-rook:before {
    content: 'ï‘‡';
}

.fa-chess-rook-alt:before {
    content: 'ï‘ˆ';
}

.fa-chevron-circle-down:before {
    content: 'ï„º';
}

.fa-chevron-circle-left:before {
    content: 'ï„·';
}

.fa-chevron-circle-right:before {
    content: 'ï„¸';
}

.fa-chevron-circle-up:before {
    content: 'ï„¹';
}

.fa-chevron-double-down:before {
    content: 'ïŒ¢';
}

.fa-chevron-double-left:before {
    content: 'ïŒ£';
}

.fa-chevron-double-right:before {
    content: 'ïŒ¤';
}

.fa-chevron-double-up:before {
    content: 'ïŒ¥';
}

.fa-chevron-down:before {
    content: 'ï¸';
}

.fa-chevron-left:before {
    content: 'ï“';
}

.fa-chevron-right:before {
    content: 'ï”';
}

.fa-chevron-square-down:before {
    content: 'ïŒ©';
}

.fa-chevron-square-left:before {
    content: 'ïŒª';
}

.fa-chevron-square-right:before {
    content: 'ïŒ«';
}

.fa-chevron-square-up:before {
    content: 'ïŒ¬';
}

.fa-chevron-up:before {
    content: 'ï·';
}

.fa-child:before {
    content: 'ï†®';
}

.fa-chimney:before {
    content: 'ïž‹';
}

.fa-chrome:before {
    content: 'ï‰¨';
}

.fa-church:before {
    content: 'ï”';
}

.fa-circle:before {
    content: 'ï„‘';
}

.fa-circle-notch:before {
    content: 'ï‡Ž';
}

.fa-city:before {
    content: 'ï™';
}

.fa-claw-marks:before {
    content: 'ï›‚';
}

.fa-clinic-medical:before {
    content: 'ïŸ²';
}

.fa-clipboard:before {
    content: 'ïŒ¨';
}

.fa-clipboard-check:before {
    content: 'ï‘¬';
}

.fa-clipboard-list:before {
    content: 'ï‘­';
}

.fa-clipboard-list-check:before {
    content: 'ïœ·';
}

.fa-clipboard-prescription:before {
    content: 'ï—¨';
}

.fa-clipboard-user:before {
    content: 'ïŸ³';
}

.fa-clock:before {
    content: 'ï€—';
}

.fa-clone:before {
    content: 'ï‰';
}

.fa-closed-captioning:before {
    content: 'ïˆŠ';
}

.fa-cloud:before {
    content: 'ïƒ‚';
}

.fa-cloud-download:before {
    content: 'ïƒ­';
}

.fa-cloud-download-alt:before {
    content: 'ïŽ';
}

.fa-cloud-drizzle:before {
    content: 'ïœ¸';
}

.fa-cloud-hail:before {
    content: 'ïœ¹';
}

.fa-cloud-hail-mixed:before {
    content: 'ïœº';
}

.fa-cloud-meatball:before {
    content: 'ïœ»';
}

.fa-cloud-moon:before {
    content: 'ï›ƒ';
}

.fa-cloud-moon-rain:before {
    content: 'ïœ¼';
}

.fa-cloud-rain:before {
    content: 'ïœ½';
}

.fa-cloud-rainbow:before {
    content: 'ïœ¾';
}

.fa-cloud-showers:before {
    content: 'ïœ¿';
}

.fa-cloud-showers-heavy:before {
    content: 'ï€';
}

.fa-cloud-sleet:before {
    content: 'ï';
}

.fa-cloud-snow:before {
    content: 'ï‚';
}

.fa-cloud-sun:before {
    content: 'ï›„';
}

.fa-cloud-sun-rain:before {
    content: 'ïƒ';
}

.fa-cloud-upload:before {
    content: 'ïƒ®';
}

.fa-cloud-upload-alt:before {
    content: 'ïŽ‚';
}

.fa-clouds:before {
    content: 'ï„';
}

.fa-clouds-moon:before {
    content: 'ï…';
}

.fa-clouds-sun:before {
    content: 'ï†';
}

.fa-cloudscale:before {
    content: 'ïŽƒ';
}

.fa-cloudsmith:before {
    content: 'ïŽ„';
}

.fa-cloudversify:before {
    content: 'ïŽ…';
}

.fa-club:before {
    content: 'ïŒ§';
}

.fa-cocktail:before {
    content: 'ï•¡';
}

.fa-code:before {
    content: 'ï„¡';
}

.fa-code-branch:before {
    content: 'ï„¦';
}

.fa-code-commit:before {
    content: 'ïŽ†';
}

.fa-code-merge:before {
    content: 'ïŽ‡';
}

.fa-codepen:before {
    content: 'ï‡‹';
}

.fa-codiepie:before {
    content: 'ïŠ„';
}

.fa-coffee:before {
    content: 'ïƒ´';
}

.fa-coffee-togo:before {
    content: 'ï›…';
}

.fa-coffin:before {
    content: 'ï›†';
}

.fa-cog:before {
    content: 'ï€“';
}

.fa-cogs:before {
    content: 'ï‚…';
}

.fa-coins:before {
    content: 'ï”ž';
}

.fa-columns:before {
    content: 'ïƒ›';
}

.fa-comment:before {
    content: 'ïµ';
}

.fa-comment-alt:before {
    content: 'ï‰º';
}

.fa-comment-alt-check:before {
    content: 'ï’¢';
}

.fa-comment-alt-dollar:before {
    content: 'ï™';
}

.fa-comment-alt-dots:before {
    content: 'ï’£';
}

.fa-comment-alt-edit:before {
    content: 'ï’¤';
}

.fa-comment-alt-exclamation:before {
    content: 'ï’¥';
}

.fa-comment-alt-lines:before {
    content: 'ï’¦';
}

.fa-comment-alt-medical:before {
    content: 'ïŸ´';
}

.fa-comment-alt-minus:before {
    content: 'ï’§';
}

.fa-comment-alt-plus:before {
    content: 'ï’¨';
}

.fa-comment-alt-slash:before {
    content: 'ï’©';
}

.fa-comment-alt-smile:before {
    content: 'ï’ª';
}

.fa-comment-alt-times:before {
    content: 'ï’«';
}

.fa-comment-check:before {
    content: 'ï’¬';
}

.fa-comment-dollar:before {
    content: 'ï™‘';
}

.fa-comment-dots:before {
    content: 'ï’­';
}

.fa-comment-edit:before {
    content: 'ï’®';
}

.fa-comment-exclamation:before {
    content: 'ï’¯';
}

.fa-comment-lines:before {
    content: 'ï’°';
}

.fa-comment-medical:before {
    content: 'ïŸµ';
}

.fa-comment-minus:before {
    content: 'ï’±';
}

.fa-comment-plus:before {
    content: 'ï’²';
}

.fa-comment-slash:before {
    content: 'ï’³';
}

.fa-comment-smile:before {
    content: 'ï’´';
}

.fa-comment-times:before {
    content: 'ï’µ';
}

.fa-comments:before {
    content: 'ï‚†';
}

.fa-comments-alt:before {
    content: 'ï’¶';
}

.fa-comments-alt-dollar:before {
    content: 'ï™’';
}

.fa-comments-dollar:before {
    content: 'ï™“';
}

.fa-compact-disc:before {
    content: 'ï”Ÿ';
}

.fa-compass:before {
    content: 'ï…Ž';
}

.fa-compass-slash:before {
    content: 'ï—©';
}

.fa-compress:before {
    content: 'ï¦';
}

.fa-compress-alt:before {
    content: 'ï¢';
}

.fa-compress-arrows-alt:before {
    content: 'ïžŒ';
}

.fa-compress-wide:before {
    content: 'ïŒ¦';
}

.fa-concierge-bell:before {
    content: 'ï•¢';
}

.fa-confluence:before {
    content: 'ïž';
}

.fa-connectdevelop:before {
    content: 'ïˆŽ';
}

.fa-container-storage:before {
    content: 'ï’·';
}

.fa-contao:before {
    content: 'ï‰­';
}

.fa-conveyor-belt:before {
    content: 'ï‘®';
}

.fa-conveyor-belt-alt:before {
    content: 'ï‘¯';
}

.fa-cookie:before {
    content: 'ï•£';
}

.fa-cookie-bite:before {
    content: 'ï•¤';
}

.fa-copy:before {
    content: 'ïƒ…';
}

.fa-copyright:before {
    content: 'ï‡¹';
}

.fa-corn:before {
    content: 'ï›‡';
}

.fa-couch:before {
    content: 'ï’¸';
}

.fa-cow:before {
    content: 'ï›ˆ';
}

.fa-cpanel:before {
    content: 'ïŽˆ';
}

.fa-creative-commons:before {
    content: 'ï‰ž';
}

.fa-creative-commons-by:before {
    content: 'ï“§';
}

.fa-creative-commons-nc:before {
    content: 'ï“¨';
}

.fa-creative-commons-nc-eu:before {
    content: 'ï“©';
}

.fa-creative-commons-nc-jp:before {
    content: 'ï“ª';
}

.fa-creative-commons-nd:before {
    content: 'ï“«';
}

.fa-creative-commons-pd:before {
    content: 'ï“¬';
}

.fa-creative-commons-pd-alt:before {
    content: 'ï“­';
}

.fa-creative-commons-remix:before {
    content: 'ï“®';
}

.fa-creative-commons-sa:before {
    content: 'ï“¯';
}

.fa-creative-commons-sampling:before {
    content: 'ï“°';
}

.fa-creative-commons-sampling-plus:before {
    content: 'ï“±';
}

.fa-creative-commons-share:before {
    content: 'ï“²';
}

.fa-creative-commons-zero:before {
    content: 'ï“³';
}

.fa-credit-card:before {
    content: 'ï‚';
}

.fa-credit-card-blank:before {
    content: 'ïŽ‰';
}

.fa-credit-card-front:before {
    content: 'ïŽŠ';
}

.fa-cricket:before {
    content: 'ï‘‰';
}

.fa-critical-role:before {
    content: 'ï›‰';
}

.fa-croissant:before {
    content: 'ïŸ¶';
}

.fa-crop:before {
    content: 'ï„¥';
}

.fa-crop-alt:before {
    content: 'ï•¥';
}

.fa-cross:before {
    content: 'ï™”';
}

.fa-crosshairs:before {
    content: 'ï›';
}

.fa-crow:before {
    content: 'ï” ';
}

.fa-crown:before {
    content: 'ï”¡';
}

.fa-crutch:before {
    content: 'ïŸ·';
}

.fa-crutches:before {
    content: 'ïŸ¸';
}

.fa-css3:before {
    content: 'ï„¼';
}

.fa-css3-alt:before {
    content: 'ïŽ‹';
}

.fa-cube:before {
    content: 'ï†²';
}

.fa-cubes:before {
    content: 'ï†³';
}

.fa-curling:before {
    content: 'ï‘Š';
}

.fa-cut:before {
    content: 'ïƒ„';
}

.fa-cuttlefish:before {
    content: 'ïŽŒ';
}

.fa-d-and-d:before {
    content: 'ïŽ';
}

.fa-d-and-d-beyond:before {
    content: 'ï›Š';
}

.fa-dagger:before {
    content: 'ï›‹';
}

.fa-dashcube:before {
    content: 'ïˆ';
}

.fa-database:before {
    content: 'ï‡€';
}

.fa-deaf:before {
    content: 'ïŠ¤';
}

.fa-debug:before {
    content: 'ïŸ¹';
}

.fa-deer:before {
    content: 'ïžŽ';
}

.fa-deer-rudolph:before {
    content: 'ïž';
}

.fa-delicious:before {
    content: 'ï†¥';
}

.fa-democrat:before {
    content: 'ï‡';
}

.fa-deploydog:before {
    content: 'ïŽŽ';
}

.fa-deskpro:before {
    content: 'ïŽ';
}

.fa-desktop:before {
    content: 'ï„ˆ';
}

.fa-desktop-alt:before {
    content: 'ïŽ';
}

.fa-dev:before {
    content: 'ï›Œ';
}

.fa-deviantart:before {
    content: 'ï†½';
}

.fa-dewpoint:before {
    content: 'ïˆ';
}

.fa-dharmachakra:before {
    content: 'ï™•';
}

.fa-dhl:before {
    content: 'ïž';
}

.fa-diagnoses:before {
    content: 'ï‘°';
}

.fa-diamond:before {
    content: 'ïˆ™';
}

.fa-diaspora:before {
    content: 'ïž‘';
}

.fa-dice:before {
    content: 'ï”¢';
}

.fa-dice-d10:before {
    content: 'ï›';
}

.fa-dice-d12:before {
    content: 'ï›Ž';
}

.fa-dice-d20:before {
    content: 'ï›';
}

.fa-dice-d4:before {
    content: 'ï›';
}

.fa-dice-d6:before {
    content: 'ï›‘';
}

.fa-dice-d8:before {
    content: 'ï›’';
}

.fa-dice-five:before {
    content: 'ï”£';
}

.fa-dice-four:before {
    content: 'ï”¤';
}

.fa-dice-one:before {
    content: 'ï”¥';
}

.fa-dice-six:before {
    content: 'ï”¦';
}

.fa-dice-three:before {
    content: 'ï”§';
}

.fa-dice-two:before {
    content: 'ï”¨';
}

.fa-digg:before {
    content: 'ï†¦';
}

.fa-digital-ocean:before {
    content: 'ïŽ‘';
}

.fa-digital-tachograph:before {
    content: 'ï•¦';
}

.fa-diploma:before {
    content: 'ï—ª';
}

.fa-directions:before {
    content: 'ï—«';
}

.fa-discord:before {
    content: 'ïŽ’';
}

.fa-discourse:before {
    content: 'ïŽ“';
}

.fa-disease:before {
    content: 'ïŸº';
}

.fa-divide:before {
    content: 'ï”©';
}

.fa-dizzy:before {
    content: 'ï•§';
}

.fa-dna:before {
    content: 'ï‘±';
}

.fa-do-not-enter:before {
    content: 'ï—¬';
}

.fa-dochub:before {
    content: 'ïŽ”';
}

.fa-docker:before {
    content: 'ïŽ•';
}

.fa-dog:before {
    content: 'ï›“';
}

.fa-dog-leashed:before {
    content: 'ï›”';
}

.fa-dollar-sign:before {
    content: 'ï…•';
}

.fa-dolly:before {
    content: 'ï‘²';
}

.fa-dolly-empty:before {
    content: 'ï‘³';
}

.fa-dolly-flatbed:before {
    content: 'ï‘´';
}

.fa-dolly-flatbed-alt:before {
    content: 'ï‘µ';
}

.fa-dolly-flatbed-empty:before {
    content: 'ï‘¶';
}

.fa-donate:before {
    content: 'ï’¹';
}

.fa-door-closed:before {
    content: 'ï”ª';
}

.fa-door-open:before {
    content: 'ï”«';
}

.fa-dot-circle:before {
    content: 'ï†’';
}

.fa-dove:before {
    content: 'ï’º';
}

.fa-download:before {
    content: 'ï€™';
}

.fa-draft2digital:before {
    content: 'ïŽ–';
}

.fa-drafting-compass:before {
    content: 'ï•¨';
}

.fa-dragon:before {
    content: 'ï›•';
}

.fa-draw-circle:before {
    content: 'ï—­';
}

.fa-draw-polygon:before {
    content: 'ï—®';
}

.fa-draw-square:before {
    content: 'ï—¯';
}

.fa-dreidel:before {
    content: 'ïž’';
}

.fa-dribbble:before {
    content: 'ï…½';
}

.fa-dribbble-square:before {
    content: 'ïŽ—';
}

.fa-dropbox:before {
    content: 'ï…«';
}

.fa-drum:before {
    content: 'ï•©';
}

.fa-drum-steelpan:before {
    content: 'ï•ª';
}

.fa-drumstick:before {
    content: 'ï›–';
}

.fa-drumstick-bite:before {
    content: 'ï›—';
}

.fa-drupal:before {
    content: 'ï†©';
}

.fa-duck:before {
    content: 'ï›˜';
}

.fa-dumbbell:before {
    content: 'ï‘‹';
}

.fa-dumpster:before {
    content: 'ïž“';
}

.fa-dumpster-fire:before {
    content: 'ïž”';
}

.fa-dungeon:before {
    content: 'ï›™';
}

.fa-dyalog:before {
    content: 'ïŽ™';
}

.fa-ear:before {
    content: 'ï—°';
}

.fa-ear-muffs:before {
    content: 'ïž•';
}

.fa-earlybirds:before {
    content: 'ïŽš';
}

.fa-ebay:before {
    content: 'ï“´';
}

.fa-eclipse:before {
    content: 'ï‰';
}

.fa-eclipse-alt:before {
    content: 'ïŠ';
}

.fa-edge:before {
    content: 'ïŠ‚';
}

.fa-edit:before {
    content: 'ï„';
}

.fa-egg:before {
    content: 'ïŸ»';
}

.fa-egg-fried:before {
    content: 'ïŸ¼';
}

.fa-eject:before {
    content: 'ï’';
}

.fa-elementor:before {
    content: 'ï°';
}

.fa-elephant:before {
    content: 'ï›š';
}

.fa-ellipsis-h:before {
    content: 'ï…';
}

.fa-ellipsis-h-alt:before {
    content: 'ïŽ›';
}

.fa-ellipsis-v:before {
    content: 'ï…‚';
}

.fa-ellipsis-v-alt:before {
    content: 'ïŽœ';
}

.fa-ello:before {
    content: 'ï—±';
}

.fa-ember:before {
    content: 'ï£';
}

.fa-empire:before {
    content: 'ï‡‘';
}

.fa-empty-set:before {
    content: 'ï™–';
}

.fa-engine-warning:before {
    content: 'ï—²';
}

.fa-envelope:before {
    content: 'ïƒ ';
}

.fa-envelope-open:before {
    content: 'ïŠ¶';
}

.fa-envelope-open-dollar:before {
    content: 'ï™—';
}

.fa-envelope-open-text:before {
    content: 'ï™˜';
}

.fa-envelope-square:before {
    content: 'ï†™';
}

.fa-envira:before {
    content: 'ïŠ™';
}

.fa-equals:before {
    content: 'ï”¬';
}

.fa-eraser:before {
    content: 'ï„­';
}

.fa-erlang:before {
    content: 'ïŽ';
}

.fa-ethereum:before {
    content: 'ï®';
}

.fa-ethernet:before {
    content: 'ïž–';
}

.fa-etsy:before {
    content: 'ï‹—';
}

.fa-euro-sign:before {
    content: 'ï…“';
}

.fa-exchange:before {
    content: 'ïƒ¬';
}

.fa-exchange-alt:before {
    content: 'ï¢';
}

.fa-exclamation:before {
    content: 'ï„ª';
}

.fa-exclamation-circle:before {
    content: 'ïª';
}

.fa-exclamation-square:before {
    content: 'ïŒ¡';
}

.fa-exclamation-triangle:before {
    content: 'ï±';
}

.fa-expand:before {
    content: 'ï¥';
}

.fa-expand-alt:before {
    content: 'ï¤';
}

.fa-expand-arrows:before {
    content: 'ïŒ';
}

.fa-expand-arrows-alt:before {
    content: 'ïŒž';
}

.fa-expand-wide:before {
    content: 'ïŒ ';
}

.fa-expeditedssl:before {
    content: 'ïˆ¾';
}

.fa-external-link:before {
    content: 'ï‚Ž';
}

.fa-external-link-alt:before {
    content: 'ï';
}

.fa-external-link-square:before {
    content: 'ï…Œ';
}

.fa-external-link-square-alt:before {
    content: 'ï ';
}

.fa-eye:before {
    content: 'ï®';
}

.fa-eye-dropper:before {
    content: 'ï‡»';
}

.fa-eye-evil:before {
    content: 'ï››';
}

.fa-eye-slash:before {
    content: 'ï°';
}

.fa-facebook:before {
    content: 'ï‚š';
}

.fa-facebook-f:before {
    content: 'ïŽž';
}

.fa-facebook-messenger:before {
    content: 'ïŽŸ';
}

.fa-facebook-square:before {
    content: 'ï‚‚';
}

.fa-fantasy-flight-games:before {
    content: 'ï›œ';
}

.fa-fast-backward:before {
    content: 'ï‰';
}

.fa-fast-forward:before {
    content: 'ï';
}

.fa-fax:before {
    content: 'ï†¬';
}

.fa-feather:before {
    content: 'ï”­';
}

.fa-feather-alt:before {
    content: 'ï•«';
}

.fa-fedex:before {
    content: 'ïž—';
}

.fa-fedora:before {
    content: 'ïž˜';
}

.fa-female:before {
    content: 'ï†‚';
}

.fa-field-hockey:before {
    content: 'ï‘Œ';
}

.fa-fighter-jet:before {
    content: 'ïƒ»';
}

.fa-figma:before {
    content: 'ïž™';
}

.fa-file:before {
    content: 'ï…›';
}

.fa-file-alt:before {
    content: 'ï…œ';
}

.fa-file-archive:before {
    content: 'ï‡†';
}

.fa-file-audio:before {
    content: 'ï‡‡';
}

.fa-file-certificate:before {
    content: 'ï—³';
}

.fa-file-chart-line:before {
    content: 'ï™™';
}

.fa-file-chart-pie:before {
    content: 'ï™š';
}

.fa-file-check:before {
    content: 'ïŒ–';
}

.fa-file-code:before {
    content: 'ï‡‰';
}

.fa-file-contract:before {
    content: 'ï•¬';
}

.fa-file-csv:before {
    content: 'ï›';
}

.fa-file-download:before {
    content: 'ï•­';
}

.fa-file-edit:before {
    content: 'ïŒœ';
}

.fa-file-excel:before {
    content: 'ï‡ƒ';
}

.fa-file-exclamation:before {
    content: 'ïŒš';
}

.fa-file-export:before {
    content: 'ï•®';
}

.fa-file-image:before {
    content: 'ï‡…';
}

.fa-file-import:before {
    content: 'ï•¯';
}

.fa-file-invoice:before {
    content: 'ï•°';
}

.fa-file-invoice-dollar:before {
    content: 'ï•±';
}

.fa-file-medical:before {
    content: 'ï‘·';
}

.fa-file-medical-alt:before {
    content: 'ï‘¸';
}

.fa-file-minus:before {
    content: 'ïŒ˜';
}

.fa-file-pdf:before {
    content: 'ï‡';
}

.fa-file-plus:before {
    content: 'ïŒ™';
}

.fa-file-powerpoint:before {
    content: 'ï‡„';
}

.fa-file-prescription:before {
    content: 'ï•²';
}

.fa-file-signature:before {
    content: 'ï•³';
}

.fa-file-spreadsheet:before {
    content: 'ï™›';
}

.fa-file-times:before {
    content: 'ïŒ—';
}

.fa-file-upload:before {
    content: 'ï•´';
}

.fa-file-user:before {
    content: 'ï™œ';
}

.fa-file-video:before {
    content: 'ï‡ˆ';
}

.fa-file-word:before {
    content: 'ï‡‚';
}

.fa-files-medical:before {
    content: 'ïŸ½';
}

.fa-fill:before {
    content: 'ï•µ';
}

.fa-fill-drip:before {
    content: 'ï•¶';
}

.fa-film:before {
    content: 'ï€ˆ';
}

.fa-film-alt:before {
    content: 'ïŽ ';
}

.fa-filter:before {
    content: 'ï‚°';
}

.fa-fingerprint:before {
    content: 'ï•·';
}

.fa-fire:before {
    content: 'ï­';
}

.fa-fire-alt:before {
    content: 'ïŸ¤';
}

.fa-fire-extinguisher:before {
    content: 'ï„´';
}

.fa-fire-smoke:before {
    content: 'ï‹';
}

.fa-firefox:before {
    content: 'ï‰©';
}

.fa-fireplace:before {
    content: 'ïžš';
}

.fa-first-aid:before {
    content: 'ï‘¹';
}

.fa-first-order:before {
    content: 'ïŠ°';
}

.fa-first-order-alt:before {
    content: 'ï”Š';
}

.fa-firstdraft:before {
    content: 'ïŽ¡';
}

.fa-fish:before {
    content: 'ï•¸';
}

.fa-fish-cooked:before {
    content: 'ïŸ¾';
}

.fa-fist-raised:before {
    content: 'ï›ž';
}

.fa-flag:before {
    content: 'ï€¤';
}

.fa-flag-alt:before {
    content: 'ïŒ';
}

.fa-flag-checkered:before {
    content: 'ï„ž';
}

.fa-flag-usa:before {
    content: 'ï';
}

.fa-flame:before {
    content: 'ï›Ÿ';
}

.fa-flask:before {
    content: 'ïƒƒ';
}

.fa-flask-poison:before {
    content: 'ï› ';
}

.fa-flask-potion:before {
    content: 'ï›¡';
}

.fa-flickr:before {
    content: 'ï…®';
}

.fa-flipboard:before {
    content: 'ï‘';
}

.fa-flower:before {
    content: 'ïŸ¿';
}

.fa-flower-daffodil:before {
    content: 'ï €';
}

.fa-flower-tulip:before {
    content: 'ï ';
}

.fa-flushed:before {
    content: 'ï•¹';
}

.fa-fly:before {
    content: 'ï—';
}

.fa-fog:before {
    content: 'ïŽ';
}

.fa-folder:before {
    content: 'ï»';
}

.fa-folder-minus:before {
    content: 'ï™';
}

.fa-folder-open:before {
    content: 'ï¼';
}

.fa-folder-plus:before {
    content: 'ï™ž';
}

.fa-folder-times:before {
    content: 'ï™Ÿ';
}

.fa-folder-tree:before {
    content: 'ï ‚';
}

.fa-folders:before {
    content: 'ï™ ';
}

.fa-font:before {
    content: 'ï€±';
}

.fa-font-awesome:before {
    content: 'ïŠ´';
}

.fa-font-awesome-alt:before {
    content: 'ïœ';
}

.fa-font-awesome-flag:before {
    content: 'ï¥';
}

.fa-font-awesome-logo-full:before {
    content: 'ï“¦';
}

.fa-fonticons:before {
    content: 'ïŠ€';
}

.fa-fonticons-fi:before {
    content: 'ïŽ¢';
}

.fa-football-ball:before {
    content: 'ï‘Ž';
}

.fa-football-helmet:before {
    content: 'ï‘';
}

.fa-forklift:before {
    content: 'ï‘º';
}

.fa-fort-awesome:before {
    content: 'ïŠ†';
}

.fa-fort-awesome-alt:before {
    content: 'ïŽ£';
}

.fa-forumbee:before {
    content: 'ïˆ‘';
}

.fa-forward:before {
    content: 'ïŽ';
}

.fa-foursquare:before {
    content: 'ï†€';
}

.fa-fragile:before {
    content: 'ï’»';
}

.fa-free-code-camp:before {
    content: 'ï‹…';
}

.fa-freebsd:before {
    content: 'ïŽ¤';
}

.fa-french-fries:before {
    content: 'ï ƒ';
}

.fa-frog:before {
    content: 'ï”®';
}

.fa-frosty-head:before {
    content: 'ïž›';
}

.fa-frown:before {
    content: 'ï„™';
}

.fa-frown-open:before {
    content: 'ï•º';
}

.fa-fulcrum:before {
    content: 'ï”‹';
}

.fa-function:before {
    content: 'ï™¡';
}

.fa-funnel-dollar:before {
    content: 'ï™¢';
}

.fa-futbol:before {
    content: 'ï‡£';
}

.fa-galactic-republic:before {
    content: 'ï”Œ';
}

.fa-galactic-senate:before {
    content: 'ï”';
}

.fa-gamepad:before {
    content: 'ï„›';
}

.fa-gas-pump:before {
    content: 'ï”¯';
}

.fa-gas-pump-slash:before {
    content: 'ï—´';
}

.fa-gavel:before {
    content: 'ïƒ£';
}

.fa-gem:before {
    content: 'ïŽ¥';
}

.fa-genderless:before {
    content: 'ïˆ­';
}

.fa-get-pocket:before {
    content: 'ï‰¥';
}

.fa-gg:before {
    content: 'ï‰ ';
}

.fa-gg-circle:before {
    content: 'ï‰¡';
}

.fa-ghost:before {
    content: 'ï›¢';
}

.fa-gift:before {
    content: 'ï«';
}

.fa-gift-card:before {
    content: 'ï™£';
}

.fa-gifts:before {
    content: 'ïžœ';
}

.fa-gingerbread-man:before {
    content: 'ïž';
}

.fa-git:before {
    content: 'ï‡“';
}

.fa-git-square:before {
    content: 'ï‡’';
}

.fa-github:before {
    content: 'ï‚›';
}

.fa-github-alt:before {
    content: 'ï„“';
}

.fa-github-square:before {
    content: 'ï‚’';
}

.fa-gitkraken:before {
    content: 'ïŽ¦';
}

.fa-gitlab:before {
    content: 'ïŠ–';
}

.fa-gitter:before {
    content: 'ï¦';
}

.fa-glass:before {
    content: 'ï „';
}

.fa-glass-champagne:before {
    content: 'ïžž';
}

.fa-glass-cheers:before {
    content: 'ïžŸ';
}

.fa-glass-martini:before {
    content: 'ï€€';
}

.fa-glass-martini-alt:before {
    content: 'ï•»';
}

.fa-glass-whiskey:before {
    content: 'ïž ';
}

.fa-glass-whiskey-rocks:before {
    content: 'ïž¡';
}

.fa-glasses:before {
    content: 'ï”°';
}

.fa-glasses-alt:before {
    content: 'ï—µ';
}

.fa-glide:before {
    content: 'ïŠ¥';
}

.fa-glide-g:before {
    content: 'ïŠ¦';
}

.fa-globe:before {
    content: 'ï‚¬';
}

.fa-globe-africa:before {
    content: 'ï•¼';
}

.fa-globe-americas:before {
    content: 'ï•½';
}

.fa-globe-asia:before {
    content: 'ï•¾';
}

.fa-globe-europe:before {
    content: 'ïž¢';
}

.fa-globe-snow:before {
    content: 'ïž£';
}

.fa-globe-stand:before {
    content: 'ï—¶';
}

.fa-gofore:before {
    content: 'ïŽ§';
}

.fa-golf-ball:before {
    content: 'ï‘';
}

.fa-golf-club:before {
    content: 'ï‘‘';
}

.fa-goodreads:before {
    content: 'ïŽ¨';
}

.fa-goodreads-g:before {
    content: 'ïŽ©';
}

.fa-google:before {
    content: 'ï† ';
}

.fa-google-drive:before {
    content: 'ïŽª';
}

.fa-google-play:before {
    content: 'ïŽ«';
}

.fa-google-plus:before {
    content: 'ïŠ³';
}

.fa-google-plus-g:before {
    content: 'ïƒ•';
}

.fa-google-plus-square:before {
    content: 'ïƒ”';
}

.fa-google-wallet:before {
    content: 'ï‡®';
}

.fa-gopuram:before {
    content: 'ï™¤';
}

.fa-graduation-cap:before {
    content: 'ï†';
}

.fa-gratipay:before {
    content: 'ï†„';
}

.fa-grav:before {
    content: 'ï‹–';
}

.fa-greater-than:before {
    content: 'ï”±';
}

.fa-greater-than-equal:before {
    content: 'ï”²';
}

.fa-grimace:before {
    content: 'ï•¿';
}

.fa-grin:before {
    content: 'ï–€';
}

.fa-grin-alt:before {
    content: 'ï–';
}

.fa-grin-beam:before {
    content: 'ï–‚';
}

.fa-grin-beam-sweat:before {
    content: 'ï–ƒ';
}

.fa-grin-hearts:before {
    content: 'ï–„';
}

.fa-grin-squint:before {
    content: 'ï–…';
}

.fa-grin-squint-tears:before {
    content: 'ï–†';
}

.fa-grin-stars:before {
    content: 'ï–‡';
}

.fa-grin-tears:before {
    content: 'ï–ˆ';
}

.fa-grin-tongue:before {
    content: 'ï–‰';
}

.fa-grin-tongue-squint:before {
    content: 'ï–Š';
}

.fa-grin-tongue-wink:before {
    content: 'ï–‹';
}

.fa-grin-wink:before {
    content: 'ï–Œ';
}

.fa-grip-horizontal:before {
    content: 'ï–';
}

.fa-grip-lines:before {
    content: 'ïž¤';
}

.fa-grip-lines-vertical:before {
    content: 'ïž¥';
}

.fa-grip-vertical:before {
    content: 'ï–Ž';
}

.fa-gripfire:before {
    content: 'ïŽ¬';
}

.fa-grunt:before {
    content: 'ïŽ­';
}

.fa-guitar:before {
    content: 'ïž¦';
}

.fa-gulp:before {
    content: 'ïŽ®';
}

.fa-h-square:before {
    content: 'ïƒ½';
}

.fa-h1:before {
    content: 'ïŒ“';
}

.fa-h2:before {
    content: 'ïŒ”';
}

.fa-h3:before {
    content: 'ïŒ•';
}

.fa-hacker-news:before {
    content: 'ï‡”';
}

.fa-hacker-news-square:before {
    content: 'ïŽ¯';
}

.fa-hackerrank:before {
    content: 'ï—·';
}

.fa-hamburger:before {
    content: 'ï …';
}

.fa-hammer:before {
    content: 'ï›£';
}

.fa-hammer-war:before {
    content: 'ï›¤';
}

.fa-hamsa:before {
    content: 'ï™¥';
}

.fa-hand-heart:before {
    content: 'ï’¼';
}

.fa-hand-holding:before {
    content: 'ï’½';
}

.fa-hand-holding-box:before {
    content: 'ï‘»';
}

.fa-hand-holding-heart:before {
    content: 'ï’¾';
}

.fa-hand-holding-magic:before {
    content: 'ï›¥';
}

.fa-hand-holding-seedling:before {
    content: 'ï’¿';
}

.fa-hand-holding-usd:before {
    content: 'ï“€';
}

.fa-hand-holding-water:before {
    content: 'ï“';
}

.fa-hand-lizard:before {
    content: 'ï‰˜';
}

.fa-hand-middle-finger:before {
    content: 'ï †';
}

.fa-hand-paper:before {
    content: 'ï‰–';
}

.fa-hand-peace:before {
    content: 'ï‰›';
}

.fa-hand-point-down:before {
    content: 'ï‚§';
}

.fa-hand-point-left:before {
    content: 'ï‚¥';
}

.fa-hand-point-right:before {
    content: 'ï‚¤';
}

.fa-hand-point-up:before {
    content: 'ï‚¦';
}

.fa-hand-pointer:before {
    content: 'ï‰š';
}

.fa-hand-receiving:before {
    content: 'ï‘¼';
}

.fa-hand-rock:before {
    content: 'ï‰•';
}

.fa-hand-scissors:before {
    content: 'ï‰—';
}

.fa-hand-spock:before {
    content: 'ï‰™';
}

.fa-hands:before {
    content: 'ï“‚';
}

.fa-hands-heart:before {
    content: 'ï“ƒ';
}

.fa-hands-helping:before {
    content: 'ï“„';
}

.fa-hands-usd:before {
    content: 'ï“…';
}

.fa-handshake:before {
    content: 'ïŠµ';
}

.fa-handshake-alt:before {
    content: 'ï“†';
}

.fa-hanukiah:before {
    content: 'ï›¦';
}

.fa-hard-hat:before {
    content: 'ï ‡';
}

.fa-hashtag:before {
    content: 'ïŠ’';
}

.fa-hat-santa:before {
    content: 'ïž§';
}

.fa-hat-winter:before {
    content: 'ïž¨';
}

.fa-hat-witch:before {
    content: 'ï›§';
}

.fa-hat-wizard:before {
    content: 'ï›¨';
}

.fa-haykal:before {
    content: 'ï™¦';
}

.fa-hdd:before {
    content: 'ï‚ ';
}

.fa-head-side:before {
    content: 'ï›©';
}

.fa-head-side-brain:before {
    content: 'ï ˆ';
}

.fa-head-side-medical:before {
    content: 'ï ‰';
}

.fa-head-vr:before {
    content: 'ï›ª';
}

.fa-heading:before {
    content: 'ï‡œ';
}

.fa-headphones:before {
    content: 'ï€¥';
}

.fa-headphones-alt:before {
    content: 'ï–';
}

.fa-headset:before {
    content: 'ï–';
}

.fa-heart:before {
    content: 'ï€„';
}

.fa-heart-broken:before {
    content: 'ïž©';
}

.fa-heart-circle:before {
    content: 'ï“‡';
}

.fa-heart-rate:before {
    content: 'ï—¸';
}

.fa-heart-square:before {
    content: 'ï“ˆ';
}

.fa-heartbeat:before {
    content: 'ïˆž';
}

.fa-helicopter:before {
    content: 'ï”³';
}

.fa-helmet-battle:before {
    content: 'ï›«';
}

.fa-hexagon:before {
    content: 'ïŒ’';
}

.fa-highlighter:before {
    content: 'ï–‘';
}

.fa-hiking:before {
    content: 'ï›¬';
}

.fa-hippo:before {
    content: 'ï›­';
}

.fa-hips:before {
    content: 'ï‘’';
}

.fa-hire-a-helper:before {
    content: 'ïŽ°';
}

.fa-history:before {
    content: 'ï‡š';
}

.fa-hockey-mask:before {
    content: 'ï›®';
}

.fa-hockey-puck:before {
    content: 'ï‘“';
}

.fa-hockey-sticks:before {
    content: 'ï‘”';
}

.fa-holly-berry:before {
    content: 'ïžª';
}

.fa-home:before {
    content: 'ï€•';
}

.fa-home-alt:before {
    content: 'ï Š';
}

.fa-home-heart:before {
    content: 'ï“‰';
}

.fa-home-lg:before {
    content: 'ï ‹';
}

.fa-home-lg-alt:before {
    content: 'ï Œ';
}

.fa-hood-cloak:before {
    content: 'ï›¯';
}

.fa-hooli:before {
    content: 'ï§';
}

.fa-hornbill:before {
    content: 'ï–’';
}

.fa-horse:before {
    content: 'ï›°';
}

.fa-horse-head:before {
    content: 'ïž«';
}

.fa-hospital:before {
    content: 'ïƒ¸';
}

.fa-hospital-alt:before {
    content: 'ï‘½';
}

.fa-hospital-symbol:before {
    content: 'ï‘¾';
}

.fa-hospital-user:before {
    content: 'ï ';
}

.fa-hospitals:before {
    content: 'ï Ž';
}

.fa-hot-tub:before {
    content: 'ï–“';
}

.fa-hotdog:before {
    content: 'ï ';
}

.fa-hotel:before {
    content: 'ï–”';
}

.fa-hotjar:before {
    content: 'ïŽ±';
}

.fa-hourglass:before {
    content: 'ï‰”';
}

.fa-hourglass-end:before {
    content: 'ï‰“';
}

.fa-hourglass-half:before {
    content: 'ï‰’';
}

.fa-hourglass-start:before {
    content: 'ï‰‘';
}

.fa-house-damage:before {
    content: 'ï›±';
}

.fa-house-flood:before {
    content: 'ï';
}

.fa-houzz:before {
    content: 'ï‰¼';
}

.fa-hryvnia:before {
    content: 'ï›²';
}

.fa-html5:before {
    content: 'ï„»';
}

.fa-hubspot:before {
    content: 'ïŽ²';
}

.fa-humidity:before {
    content: 'ï';
}

.fa-hurricane:before {
    content: 'ï‘';
}

.fa-i-cursor:before {
    content: 'ï‰†';
}

.fa-ice-cream:before {
    content: 'ï ';
}

.fa-ice-skate:before {
    content: 'ïž¬';
}

.fa-icicles:before {
    content: 'ïž­';
}

.fa-id-badge:before {
    content: 'ï‹';
}

.fa-id-card:before {
    content: 'ï‹‚';
}

.fa-id-card-alt:before {
    content: 'ï‘¿';
}

.fa-igloo:before {
    content: 'ïž®';
}

.fa-image:before {
    content: 'ï€¾';
}

.fa-images:before {
    content: 'ïŒ‚';
}

.fa-imdb:before {
    content: 'ï‹˜';
}

.fa-inbox:before {
    content: 'ï€œ';
}

.fa-inbox-in:before {
    content: 'ïŒ';
}

.fa-inbox-out:before {
    content: 'ïŒ‘';
}

.fa-indent:before {
    content: 'ï€¼';
}

.fa-industry:before {
    content: 'ï‰µ';
}

.fa-industry-alt:before {
    content: 'ïŽ³';
}

.fa-infinity:before {
    content: 'ï”´';
}

.fa-info:before {
    content: 'ï„©';
}

.fa-info-circle:before {
    content: 'ïš';
}

.fa-info-square:before {
    content: 'ïŒ';
}

.fa-inhaler:before {
    content: 'ï—¹';
}

.fa-instagram:before {
    content: 'ï…­';
}

.fa-integral:before {
    content: 'ï™§';
}

.fa-intercom:before {
    content: 'ïž¯';
}

.fa-internet-explorer:before {
    content: 'ï‰«';
}

.fa-intersection:before {
    content: 'ï™¨';
}

.fa-inventory:before {
    content: 'ï’€';
}

.fa-invision:before {
    content: 'ïž°';
}

.fa-ioxhost:before {
    content: 'ïˆˆ';
}

.fa-island-tropical:before {
    content: 'ï ‘';
}

.fa-italic:before {
    content: 'ï€³';
}

.fa-itunes:before {
    content: 'ïŽ´';
}

.fa-itunes-note:before {
    content: 'ïŽµ';
}

.fa-jack-o-lantern:before {
    content: 'ïŒŽ';
}

.fa-java:before {
    content: 'ï“¤';
}

.fa-jedi:before {
    content: 'ï™©';
}

.fa-jedi-order:before {
    content: 'ï”Ž';
}

.fa-jenkins:before {
    content: 'ïŽ¶';
}

.fa-jira:before {
    content: 'ïž±';
}

.fa-joget:before {
    content: 'ïŽ·';
}

.fa-joint:before {
    content: 'ï–•';
}

.fa-joomla:before {
    content: 'ï†ª';
}

.fa-journal-whills:before {
    content: 'ï™ª';
}

.fa-js:before {
    content: 'ïŽ¸';
}

.fa-js-square:before {
    content: 'ïŽ¹';
}

.fa-jsfiddle:before {
    content: 'ï‡Œ';
}

.fa-kaaba:before {
    content: 'ï™«';
}

.fa-kaggle:before {
    content: 'ï—º';
}

.fa-key:before {
    content: 'ï‚„';
}

.fa-key-skeleton:before {
    content: 'ï›³';
}

.fa-keybase:before {
    content: 'ï“µ';
}

.fa-keyboard:before {
    content: 'ï„œ';
}

.fa-keycdn:before {
    content: 'ïŽº';
}

.fa-keynote:before {
    content: 'ï™¬';
}

.fa-khanda:before {
    content: 'ï™­';
}

.fa-kickstarter:before {
    content: 'ïŽ»';
}

.fa-kickstarter-k:before {
    content: 'ïŽ¼';
}

.fa-kidneys:before {
    content: 'ï—»';
}

.fa-kiss:before {
    content: 'ï––';
}

.fa-kiss-beam:before {
    content: 'ï–—';
}

.fa-kiss-wink-heart:before {
    content: 'ï–˜';
}

.fa-kite:before {
    content: 'ï›´';
}

.fa-kiwi-bird:before {
    content: 'ï”µ';
}

.fa-knife-kitchen:before {
    content: 'ï›µ';
}

.fa-korvue:before {
    content: 'ï¯';
}

.fa-lambda:before {
    content: 'ï™®';
}

.fa-lamp:before {
    content: 'ï“Š';
}

.fa-landmark:before {
    content: 'ï™¯';
}

.fa-landmark-alt:before {
    content: 'ï’';
}

.fa-language:before {
    content: 'ï†«';
}

.fa-laptop:before {
    content: 'ï„‰';
}

.fa-laptop-code:before {
    content: 'ï—¼';
}

.fa-laptop-medical:before {
    content: 'ï ’';
}

.fa-laravel:before {
    content: 'ïŽ½';
}

.fa-lastfm:before {
    content: 'ïˆ‚';
}

.fa-lastfm-square:before {
    content: 'ïˆƒ';
}

.fa-laugh:before {
    content: 'ï–™';
}

.fa-laugh-beam:before {
    content: 'ï–š';
}

.fa-laugh-squint:before {
    content: 'ï–›';
}

.fa-laugh-wink:before {
    content: 'ï–œ';
}

.fa-layer-group:before {
    content: 'ï—½';
}

.fa-layer-minus:before {
    content: 'ï—¾';
}

.fa-layer-plus:before {
    content: 'ï—¿';
}

.fa-leaf:before {
    content: 'ï¬';
}

.fa-leaf-heart:before {
    content: 'ï“‹';
}

.fa-leaf-maple:before {
    content: 'ï›¶';
}

.fa-leaf-oak:before {
    content: 'ï›·';
}

.fa-leanpub:before {
    content: 'ïˆ’';
}

.fa-lemon:before {
    content: 'ï‚”';
}

.fa-less:before {
    content: 'ï';
}

.fa-less-than:before {
    content: 'ï”¶';
}

.fa-less-than-equal:before {
    content: 'ï”·';
}

.fa-level-down:before {
    content: 'ï…‰';
}

.fa-level-down-alt:before {
    content: 'ïŽ¾';
}

.fa-level-up:before {
    content: 'ï…ˆ';
}

.fa-level-up-alt:before {
    content: 'ïŽ¿';
}

.fa-life-ring:before {
    content: 'ï‡';
}

.fa-lightbulb:before {
    content: 'ïƒ«';
}

.fa-lightbulb-dollar:before {
    content: 'ï™°';
}

.fa-lightbulb-exclamation:before {
    content: 'ï™±';
}

.fa-lightbulb-on:before {
    content: 'ï™²';
}

.fa-lightbulb-slash:before {
    content: 'ï™³';
}

.fa-lights-holiday:before {
    content: 'ïž²';
}

.fa-line:before {
    content: 'ï€';
}

.fa-link:before {
    content: 'ïƒ';
}

.fa-linkedin:before {
    content: 'ï‚Œ';
}

.fa-linkedin-in:before {
    content: 'ïƒ¡';
}

.fa-linode:before {
    content: 'ïŠ¸';
}

.fa-linux:before {
    content: 'ï…¼';
}

.fa-lips:before {
    content: 'ï˜€';
}

.fa-lira-sign:before {
    content: 'ï†•';
}

.fa-list:before {
    content: 'ï€º';
}

.fa-list-alt:before {
    content: 'ï€¢';
}

.fa-list-ol:before {
    content: 'ïƒ‹';
}

.fa-list-ul:before {
    content: 'ïƒŠ';
}

.fa-location:before {
    content: 'ï˜';
}

.fa-location-arrow:before {
    content: 'ï„¤';
}

.fa-location-circle:before {
    content: 'ï˜‚';
}

.fa-location-slash:before {
    content: 'ï˜ƒ';
}

.fa-lock:before {
    content: 'ï€£';
}

.fa-lock-alt:before {
    content: 'ïŒ';
}

.fa-lock-open:before {
    content: 'ï';
}

.fa-lock-open-alt:before {
    content: 'ï‚';
}

.fa-long-arrow-alt-down:before {
    content: 'ïŒ‰';
}

.fa-long-arrow-alt-left:before {
    content: 'ïŒŠ';
}

.fa-long-arrow-alt-right:before {
    content: 'ïŒ‹';
}

.fa-long-arrow-alt-up:before {
    content: 'ïŒŒ';
}

.fa-long-arrow-down:before {
    content: 'ï…µ';
}

.fa-long-arrow-left:before {
    content: 'ï…·';
}

.fa-long-arrow-right:before {
    content: 'ï…¸';
}

.fa-long-arrow-up:before {
    content: 'ï…¶';
}

.fa-loveseat:before {
    content: 'ï“Œ';
}

.fa-low-vision:before {
    content: 'ïŠ¨';
}

.fa-luchador:before {
    content: 'ï‘•';
}

.fa-luggage-cart:before {
    content: 'ï–';
}

.fa-lungs:before {
    content: 'ï˜„';
}

.fa-lyft:before {
    content: 'ïƒ';
}

.fa-mace:before {
    content: 'ï›¸';
}

.fa-magento:before {
    content: 'ï„';
}

.fa-magic:before {
    content: 'ïƒ';
}

.fa-magnet:before {
    content: 'ï¶';
}

.fa-mail-bulk:before {
    content: 'ï™´';
}

.fa-mailbox:before {
    content: 'ï “';
}

.fa-mailchimp:before {
    content: 'ï–ž';
}

.fa-male:before {
    content: 'ï†ƒ';
}

.fa-mandalorian:before {
    content: 'ï”';
}

.fa-mandolin:before {
    content: 'ï›¹';
}

.fa-map:before {
    content: 'ï‰¹';
}

.fa-map-marked:before {
    content: 'ï–Ÿ';
}

.fa-map-marked-alt:before {
    content: 'ï– ';
}

.fa-map-marker:before {
    content: 'ï';
}

.fa-map-marker-alt:before {
    content: 'ï…';
}

.fa-map-marker-alt-slash:before {
    content: 'ï˜…';
}

.fa-map-marker-check:before {
    content: 'ï˜†';
}

.fa-map-marker-edit:before {
    content: 'ï˜‡';
}

.fa-map-marker-exclamation:before {
    content: 'ï˜ˆ';
}

.fa-map-marker-minus:before {
    content: 'ï˜‰';
}

.fa-map-marker-plus:before {
    content: 'ï˜Š';
}

.fa-map-marker-question:before {
    content: 'ï˜‹';
}

.fa-map-marker-slash:before {
    content: 'ï˜Œ';
}

.fa-map-marker-smile:before {
    content: 'ï˜';
}

.fa-map-marker-times:before {
    content: 'ï˜Ž';
}

.fa-map-pin:before {
    content: 'ï‰¶';
}

.fa-map-signs:before {
    content: 'ï‰·';
}

.fa-markdown:before {
    content: 'ï˜';
}

.fa-marker:before {
    content: 'ï–¡';
}

.fa-mars:before {
    content: 'ïˆ¢';
}

.fa-mars-double:before {
    content: 'ïˆ§';
}

.fa-mars-stroke:before {
    content: 'ïˆ©';
}

.fa-mars-stroke-h:before {
    content: 'ïˆ«';
}

.fa-mars-stroke-v:before {
    content: 'ïˆª';
}

.fa-mask:before {
    content: 'ï›º';
}

.fa-mastodon:before {
    content: 'ï“¶';
}

.fa-maxcdn:before {
    content: 'ï„¶';
}

.fa-meat:before {
    content: 'ï ”';
}

.fa-medal:before {
    content: 'ï–¢';
}

.fa-medapps:before {
    content: 'ï†';
}

.fa-medium:before {
    content: 'ïˆº';
}

.fa-medium-m:before {
    content: 'ï‡';
}

.fa-medkit:before {
    content: 'ïƒº';
}

.fa-medrt:before {
    content: 'ïˆ';
}

.fa-meetup:before {
    content: 'ï‹ ';
}

.fa-megaphone:before {
    content: 'ï™µ';
}

.fa-megaport:before {
    content: 'ï–£';
}

.fa-meh:before {
    content: 'ï„š';
}

.fa-meh-blank:before {
    content: 'ï–¤';
}

.fa-meh-rolling-eyes:before {
    content: 'ï–¥';
}

.fa-memory:before {
    content: 'ï”¸';
}

.fa-mendeley:before {
    content: 'ïž³';
}

.fa-menorah:before {
    content: 'ï™¶';
}

.fa-mercury:before {
    content: 'ïˆ£';
}

.fa-meteor:before {
    content: 'ï“';
}

.fa-microchip:before {
    content: 'ï‹›';
}

.fa-microphone:before {
    content: 'ï„°';
}

.fa-microphone-alt:before {
    content: 'ï‰';
}

.fa-microphone-alt-slash:before {
    content: 'ï”¹';
}

.fa-microphone-slash:before {
    content: 'ï„±';
}

.fa-microscope:before {
    content: 'ï˜';
}

.fa-microsoft:before {
    content: 'ïŠ';
}

.fa-mind-share:before {
    content: 'ï™·';
}

.fa-minus:before {
    content: 'ï¨';
}

.fa-minus-circle:before {
    content: 'ï–';
}

.fa-minus-hexagon:before {
    content: 'ïŒ‡';
}

.fa-minus-octagon:before {
    content: 'ïŒˆ';
}

.fa-minus-square:before {
    content: 'ï…†';
}

.fa-mistletoe:before {
    content: 'ïž´';
}

.fa-mitten:before {
    content: 'ïžµ';
}

.fa-mix:before {
    content: 'ï‹';
}

.fa-mixcloud:before {
    content: 'ïŠ‰';
}

.fa-mizuni:before {
    content: 'ïŒ';
}

.fa-mobile:before {
    content: 'ï„‹';
}

.fa-mobile-alt:before {
    content: 'ï';
}

.fa-mobile-android:before {
    content: 'ïŽ';
}

.fa-mobile-android-alt:before {
    content: 'ï';
}

.fa-modx:before {
    content: 'ïŠ…';
}

.fa-monero:before {
    content: 'ï';
}

.fa-money-bill:before {
    content: 'ïƒ–';
}

.fa-money-bill-alt:before {
    content: 'ï‘';
}

.fa-money-bill-wave:before {
    content: 'ï”º';
}

.fa-money-bill-wave-alt:before {
    content: 'ï”»';
}

.fa-money-check:before {
    content: 'ï”¼';
}

.fa-money-check-alt:before {
    content: 'ï”½';
}

.fa-monitor-heart-rate:before {
    content: 'ï˜‘';
}

.fa-monkey:before {
    content: 'ï›»';
}

.fa-monument:before {
    content: 'ï–¦';
}

.fa-moon:before {
    content: 'ï††';
}

.fa-moon-cloud:before {
    content: 'ï”';
}

.fa-moon-stars:before {
    content: 'ï•';
}

.fa-mortar-pestle:before {
    content: 'ï–§';
}

.fa-mosque:before {
    content: 'ï™¸';
}

.fa-motorcycle:before {
    content: 'ïˆœ';
}

.fa-mountain:before {
    content: 'ï›¼';
}

.fa-mountains:before {
    content: 'ï›½';
}

.fa-mouse-pointer:before {
    content: 'ï‰…';
}

.fa-mug-hot:before {
    content: 'ïž¶';
}

.fa-mug-marshmallows:before {
    content: 'ïž·';
}

.fa-music:before {
    content: 'ï€';
}

.fa-napster:before {
    content: 'ï’';
}

.fa-narwhal:before {
    content: 'ï›¾';
}

.fa-neos:before {
    content: 'ï˜’';
}

.fa-network-wired:before {
    content: 'ï›¿';
}

.fa-neuter:before {
    content: 'ïˆ¬';
}

.fa-newspaper:before {
    content: 'ï‡ª';
}

.fa-nimblr:before {
    content: 'ï–¨';
}

.fa-nintendo-switch:before {
    content: 'ï˜';
}

.fa-node:before {
    content: 'ï™';
}

.fa-node-js:before {
    content: 'ï“';
}

.fa-not-equal:before {
    content: 'ï”¾';
}

.fa-notes-medical:before {
    content: 'ï’';
}

.fa-npm:before {
    content: 'ï”';
}

.fa-ns8:before {
    content: 'ï•';
}

.fa-nutritionix:before {
    content: 'ï–';
}

.fa-object-group:before {
    content: 'ï‰‡';
}

.fa-object-ungroup:before {
    content: 'ï‰ˆ';
}

.fa-octagon:before {
    content: 'ïŒ†';
}

.fa-odnoklassniki:before {
    content: 'ï‰£';
}

.fa-odnoklassniki-square:before {
    content: 'ï‰¤';
}

.fa-oil-can:before {
    content: 'ï˜“';
}

.fa-oil-temp:before {
    content: 'ï˜”';
}

.fa-old-republic:before {
    content: 'ï”';
}

.fa-om:before {
    content: 'ï™¹';
}

.fa-omega:before {
    content: 'ï™º';
}

.fa-opencart:before {
    content: 'ïˆ½';
}

.fa-openid:before {
    content: 'ï†›';
}

.fa-opera:before {
    content: 'ï‰ª';
}

.fa-optin-monster:before {
    content: 'ïˆ¼';
}

.fa-ornament:before {
    content: 'ïž¸';
}

.fa-osi:before {
    content: 'ïš';
}

.fa-otter:before {
    content: 'ïœ€';
}

.fa-outdent:before {
    content: 'ï€»';
}

.fa-page4:before {
    content: 'ï—';
}

.fa-pagelines:before {
    content: 'ï†Œ';
}

.fa-pager:before {
    content: 'ï •';
}

.fa-paint-brush:before {
    content: 'ï‡¼';
}

.fa-paint-brush-alt:before {
    content: 'ï–©';
}

.fa-paint-roller:before {
    content: 'ï–ª';
}

.fa-palette:before {
    content: 'ï”¿';
}

.fa-palfed:before {
    content: 'ï˜';
}

.fa-pallet:before {
    content: 'ï’‚';
}

.fa-pallet-alt:before {
    content: 'ï’ƒ';
}

.fa-paper-plane:before {
    content: 'ï‡˜';
}

.fa-paperclip:before {
    content: 'ïƒ†';
}

.fa-parachute-box:before {
    content: 'ï“';
}

.fa-paragraph:before {
    content: 'ï‡';
}

.fa-parking:before {
    content: 'ï•€';
}

.fa-parking-circle:before {
    content: 'ï˜•';
}

.fa-parking-circle-slash:before {
    content: 'ï˜–';
}

.fa-parking-slash:before {
    content: 'ï˜—';
}

.fa-passport:before {
    content: 'ï–«';
}

.fa-pastafarianism:before {
    content: 'ï™»';
}

.fa-paste:before {
    content: 'ïƒª';
}

.fa-patreon:before {
    content: 'ï™';
}

.fa-pause:before {
    content: 'ïŒ';
}

.fa-pause-circle:before {
    content: 'ïŠ‹';
}

.fa-paw:before {
    content: 'ï†°';
}

.fa-paw-alt:before {
    content: 'ïœ';
}

.fa-paw-claws:before {
    content: 'ïœ‚';
}

.fa-paypal:before {
    content: 'ï‡­';
}

.fa-peace:before {
    content: 'ï™¼';
}

.fa-pegasus:before {
    content: 'ïœƒ';
}

.fa-pen:before {
    content: 'ïŒ„';
}

.fa-pen-alt:before {
    content: 'ïŒ…';
}

.fa-pen-fancy:before {
    content: 'ï–¬';
}

.fa-pen-nib:before {
    content: 'ï–­';
}

.fa-pen-square:before {
    content: 'ï…‹';
}

.fa-pencil:before {
    content: 'ï€';
}

.fa-pencil-alt:before {
    content: 'ïŒƒ';
}

.fa-pencil-paintbrush:before {
    content: 'ï˜˜';
}

.fa-pencil-ruler:before {
    content: 'ï–®';
}

.fa-pennant:before {
    content: 'ï‘–';
}

.fa-penny-arcade:before {
    content: 'ïœ„';
}

.fa-people-carry:before {
    content: 'ï“Ž';
}

.fa-pepper-hot:before {
    content: 'ï –';
}

.fa-percent:before {
    content: 'ïŠ•';
}

.fa-percentage:before {
    content: 'ï•';
}

.fa-periscope:before {
    content: 'ïš';
}

.fa-person-booth:before {
    content: 'ï–';
}

.fa-person-carry:before {
    content: 'ï“';
}

.fa-person-dolly:before {
    content: 'ï“';
}

.fa-person-dolly-empty:before {
    content: 'ï“‘';
}

.fa-person-sign:before {
    content: 'ï—';
}

.fa-phabricator:before {
    content: 'ï›';
}

.fa-phoenix-framework:before {
    content: 'ïœ';
}

.fa-phoenix-squadron:before {
    content: 'ï”‘';
}

.fa-phone:before {
    content: 'ï‚•';
}

.fa-phone-office:before {
    content: 'ï™½';
}

.fa-phone-plus:before {
    content: 'ï“’';
}

.fa-phone-slash:before {
    content: 'ï';
}

.fa-phone-square:before {
    content: 'ï‚˜';
}

.fa-phone-volume:before {
    content: 'ïŠ ';
}

.fa-php:before {
    content: 'ï‘—';
}

.fa-pi:before {
    content: 'ï™¾';
}

.fa-pie:before {
    content: 'ïœ…';
}

.fa-pied-piper:before {
    content: 'ïŠ®';
}

.fa-pied-piper-alt:before {
    content: 'ï†¨';
}

.fa-pied-piper-hat:before {
    content: 'ï“¥';
}

.fa-pied-piper-pp:before {
    content: 'ï†§';
}

.fa-pig:before {
    content: 'ïœ†';
}

.fa-piggy-bank:before {
    content: 'ï““';
}

.fa-pills:before {
    content: 'ï’„';
}

.fa-pinterest:before {
    content: 'ïƒ’';
}

.fa-pinterest-p:before {
    content: 'ïˆ±';
}

.fa-pinterest-square:before {
    content: 'ïƒ“';
}

.fa-pizza:before {
    content: 'ï —';
}

.fa-pizza-slice:before {
    content: 'ï ˜';
}

.fa-place-of-worship:before {
    content: 'ï™¿';
}

.fa-plane:before {
    content: 'ï²';
}

.fa-plane-alt:before {
    content: 'ïž';
}

.fa-plane-arrival:before {
    content: 'ï–¯';
}

.fa-plane-departure:before {
    content: 'ï–°';
}

.fa-play:before {
    content: 'ï‹';
}

.fa-play-circle:before {
    content: 'ï…„';
}

.fa-playstation:before {
    content: 'ïŸ';
}

.fa-plug:before {
    content: 'ï‡¦';
}

.fa-plus:before {
    content: 'ï§';
}

.fa-plus-circle:before {
    content: 'ï•';
}

.fa-plus-hexagon:before {
    content: 'ïŒ€';
}

.fa-plus-octagon:before {
    content: 'ïŒ';
}

.fa-plus-square:before {
    content: 'ïƒ¾';
}

.fa-podcast:before {
    content: 'ï‹Ž';
}

.fa-podium:before {
    content: 'ïš€';
}

.fa-podium-star:before {
    content: 'ï˜';
}

.fa-poll:before {
    content: 'ïš';
}

.fa-poll-h:before {
    content: 'ïš‚';
}

.fa-poll-people:before {
    content: 'ï™';
}

.fa-poo:before {
    content: 'ï‹¾';
}

.fa-poo-storm:before {
    content: 'ïš';
}

.fa-poop:before {
    content: 'ï˜™';
}

.fa-popcorn:before {
    content: 'ï ™';
}

.fa-portrait:before {
    content: 'ï ';
}

.fa-pound-sign:before {
    content: 'ï…”';
}

.fa-power-off:before {
    content: 'ï€‘';
}

.fa-pray:before {
    content: 'ïšƒ';
}

.fa-praying-hands:before {
    content: 'ïš„';
}

.fa-prescription:before {
    content: 'ï–±';
}

.fa-prescription-bottle:before {
    content: 'ï’…';
}

.fa-prescription-bottle-alt:before {
    content: 'ï’†';
}

.fa-presentation:before {
    content: 'ïš…';
}

.fa-print:before {
    content: 'ï€¯';
}

.fa-print-search:before {
    content: 'ï š';
}

.fa-print-slash:before {
    content: 'ïš†';
}

.fa-procedures:before {
    content: 'ï’‡';
}

.fa-product-hunt:before {
    content: 'ïŠˆ';
}

.fa-project-diagram:before {
    content: 'ï•‚';
}

.fa-pumpkin:before {
    content: 'ïœ‡';
}

.fa-pushed:before {
    content: 'ï¡';
}

.fa-puzzle-piece:before {
    content: 'ï„®';
}

.fa-python:before {
    content: 'ï¢';
}

.fa-qq:before {
    content: 'ï‡–';
}

.fa-qrcode:before {
    content: 'ï€©';
}

.fa-question:before {
    content: 'ï„¨';
}

.fa-question-circle:before {
    content: 'ï™';
}

.fa-question-square:before {
    content: 'ï‹½';
}

.fa-quidditch:before {
    content: 'ï‘˜';
}

.fa-quinscape:before {
    content: 'ï‘™';
}

.fa-quora:before {
    content: 'ï‹„';
}

.fa-quote-left:before {
    content: 'ï„';
}

.fa-quote-right:before {
    content: 'ï„Ž';
}

.fa-quran:before {
    content: 'ïš‡';
}

.fa-r-project:before {
    content: 'ï“·';
}

.fa-rabbit:before {
    content: 'ïœˆ';
}

.fa-rabbit-fast:before {
    content: 'ïœ‰';
}

.fa-racquet:before {
    content: 'ï‘š';
}

.fa-radiation:before {
    content: 'ïž¹';
}

.fa-radiation-alt:before {
    content: 'ïžº';
}

.fa-rainbow:before {
    content: 'ï›';
}

.fa-raindrops:before {
    content: 'ïœ';
}

.fa-ram:before {
    content: 'ïœŠ';
}

.fa-ramp-loading:before {
    content: 'ï“”';
}

.fa-random:before {
    content: 'ï´';
}

.fa-raspberry-pi:before {
    content: 'ïž»';
}

.fa-ravelry:before {
    content: 'ï‹™';
}

.fa-react:before {
    content: 'ï›';
}

.fa-reacteurope:before {
    content: 'ï';
}

.fa-readme:before {
    content: 'ï“•';
}

.fa-rebel:before {
    content: 'ï‡';
}

.fa-receipt:before {
    content: 'ï•ƒ';
}

.fa-rectangle-landscape:before {
    content: 'ï‹º';
}

.fa-rectangle-portrait:before {
    content: 'ï‹»';
}

.fa-rectangle-wide:before {
    content: 'ï‹¼';
}

.fa-recycle:before {
    content: 'ï†¸';
}

.fa-red-river:before {
    content: 'ï£';
}

.fa-reddit:before {
    content: 'ï†¡';
}

.fa-reddit-alien:before {
    content: 'ïŠ';
}

.fa-reddit-square:before {
    content: 'ï†¢';
}

.fa-redhat:before {
    content: 'ïž¼';
}

.fa-redo:before {
    content: 'ï€ž';
}

.fa-redo-alt:before {
    content: 'ï‹¹';
}

.fa-registered:before {
    content: 'ï‰';
}

.fa-renren:before {
    content: 'ï†‹';
}

.fa-repeat:before {
    content: 'ï£';
}

.fa-repeat-1:before {
    content: 'ï¥';
}

.fa-repeat-1-alt:before {
    content: 'ï¦';
}

.fa-repeat-alt:before {
    content: 'ï¤';
}

.fa-reply:before {
    content: 'ï¥';
}

.fa-reply-all:before {
    content: 'ï„¢';
}

.fa-replyd:before {
    content: 'ï¦';
}

.fa-republican:before {
    content: 'ïž';
}

.fa-researchgate:before {
    content: 'ï“¸';
}

.fa-resolving:before {
    content: 'ï§';
}

.fa-restroom:before {
    content: 'ïž½';
}

.fa-retweet:before {
    content: 'ï¹';
}

.fa-retweet-alt:before {
    content: 'ï¡';
}

.fa-rev:before {
    content: 'ï–²';
}

.fa-ribbon:before {
    content: 'ï“–';
}

.fa-ring:before {
    content: 'ïœ‹';
}

.fa-rings-wedding:before {
    content: 'ï ›';
}

.fa-road:before {
    content: 'ï€˜';
}

.fa-robot:before {
    content: 'ï•„';
}

.fa-rocket:before {
    content: 'ï„µ';
}

.fa-rocketchat:before {
    content: 'ï¨';
}

.fa-rockrms:before {
    content: 'ï©';
}

.fa-route:before {
    content: 'ï“—';
}

.fa-route-highway:before {
    content: 'ï˜š';
}

.fa-route-interstate:before {
    content: 'ï˜›';
}

.fa-rss:before {
    content: 'ï‚ž';
}

.fa-rss-square:before {
    content: 'ï…ƒ';
}

.fa-ruble-sign:before {
    content: 'ï…˜';
}

.fa-ruler:before {
    content: 'ï•…';
}

.fa-ruler-combined:before {
    content: 'ï•†';
}

.fa-ruler-horizontal:before {
    content: 'ï•‡';
}

.fa-ruler-triangle:before {
    content: 'ï˜œ';
}

.fa-ruler-vertical:before {
    content: 'ï•ˆ';
}

.fa-running:before {
    content: 'ïœŒ';
}

.fa-rupee-sign:before {
    content: 'ï…–';
}

.fa-rv:before {
    content: 'ïž¾';
}

.fa-sack:before {
    content: 'ï œ';
}

.fa-sack-dollar:before {
    content: 'ï ';
}

.fa-sad-cry:before {
    content: 'ï–³';
}

.fa-sad-tear:before {
    content: 'ï–´';
}

.fa-safari:before {
    content: 'ï‰§';
}

.fa-salad:before {
    content: 'ï ž';
}

.fa-sandwich:before {
    content: 'ï Ÿ';
}

.fa-sass:before {
    content: 'ïž';
}

.fa-satellite:before {
    content: 'ïž¿';
}

.fa-satellite-dish:before {
    content: 'ïŸ€';
}

.fa-sausage:before {
    content: 'ï  ';
}

.fa-save:before {
    content: 'ïƒ‡';
}

.fa-scalpel:before {
    content: 'ï˜';
}

.fa-scalpel-path:before {
    content: 'ï˜ž';
}

.fa-scanner:before {
    content: 'ï’ˆ';
}

.fa-scanner-keyboard:before {
    content: 'ï’‰';
}

.fa-scanner-touchscreen:before {
    content: 'ï’Š';
}

.fa-scarecrow:before {
    content: 'ïœ';
}

.fa-scarf:before {
    content: 'ïŸ';
}

.fa-schlix:before {
    content: 'ïª';
}

.fa-school:before {
    content: 'ï•‰';
}

.fa-screwdriver:before {
    content: 'ï•Š';
}

.fa-scribd:before {
    content: 'ïŠŠ';
}

.fa-scroll:before {
    content: 'ïœŽ';
}

.fa-scroll-old:before {
    content: 'ïœ';
}

.fa-scrubber:before {
    content: 'ï‹¸';
}

.fa-scythe:before {
    content: 'ïœ';
}

.fa-sd-card:before {
    content: 'ïŸ‚';
}

.fa-search:before {
    content: 'ï€‚';
}

.fa-search-dollar:before {
    content: 'ïšˆ';
}

.fa-search-location:before {
    content: 'ïš‰';
}

.fa-search-minus:before {
    content: 'ï€';
}

.fa-search-plus:before {
    content: 'ï€Ž';
}

.fa-searchengin:before {
    content: 'ï«';
}

.fa-seedling:before {
    content: 'ï“˜';
}

.fa-sellcast:before {
    content: 'ï‹š';
}

.fa-sellsy:before {
    content: 'ïˆ“';
}

.fa-server:before {
    content: 'ïˆ³';
}

.fa-servicestack:before {
    content: 'ï¬';
}

.fa-shapes:before {
    content: 'ï˜Ÿ';
}

.fa-share:before {
    content: 'ï¤';
}

.fa-share-all:before {
    content: 'ï§';
}

.fa-share-alt:before {
    content: 'ï‡ ';
}

.fa-share-alt-square:before {
    content: 'ï‡¡';
}

.fa-share-square:before {
    content: 'ï…';
}

.fa-sheep:before {
    content: 'ïœ‘';
}

.fa-shekel-sign:before {
    content: 'ïˆ‹';
}

.fa-shield:before {
    content: 'ï„²';
}

.fa-shield-alt:before {
    content: 'ï­';
}

.fa-shield-check:before {
    content: 'ï‹·';
}

.fa-shield-cross:before {
    content: 'ïœ’';
}

.fa-ship:before {
    content: 'ïˆš';
}

.fa-shipping-fast:before {
    content: 'ï’‹';
}

.fa-shipping-timed:before {
    content: 'ï’Œ';
}

.fa-shirtsinbulk:before {
    content: 'ïˆ”';
}

.fa-shish-kebab:before {
    content: 'ï ¡';
}

.fa-shoe-prints:before {
    content: 'ï•‹';
}

.fa-shopping-bag:before {
    content: 'ïŠ';
}

.fa-shopping-basket:before {
    content: 'ïŠ‘';
}

.fa-shopping-cart:before {
    content: 'ïº';
}

.fa-shopware:before {
    content: 'ï–µ';
}

.fa-shovel:before {
    content: 'ïœ“';
}

.fa-shovel-snow:before {
    content: 'ïŸƒ';
}

.fa-shower:before {
    content: 'ï‹Œ';
}

.fa-shredder:before {
    content: 'ïšŠ';
}

.fa-shuttle-van:before {
    content: 'ï–¶';
}

.fa-shuttlecock:before {
    content: 'ï‘›';
}

.fa-sickle:before {
    content: 'ï ¢';
}

.fa-sigma:before {
    content: 'ïš‹';
}

.fa-sign:before {
    content: 'ï“™';
}

.fa-sign-in:before {
    content: 'ï‚';
}

.fa-sign-in-alt:before {
    content: 'ï‹¶';
}

.fa-sign-language:before {
    content: 'ïŠ§';
}

.fa-sign-out:before {
    content: 'ï‚‹';
}

.fa-sign-out-alt:before {
    content: 'ï‹µ';
}

.fa-signal:before {
    content: 'ï€’';
}

.fa-signal-1:before {
    content: 'ïšŒ';
}

.fa-signal-2:before {
    content: 'ïš';
}

.fa-signal-3:before {
    content: 'ïšŽ';
}

.fa-signal-4:before {
    content: 'ïš';
}

.fa-signal-alt:before {
    content: 'ïš';
}

.fa-signal-alt-1:before {
    content: 'ïš‘';
}

.fa-signal-alt-2:before {
    content: 'ïš’';
}

.fa-signal-alt-3:before {
    content: 'ïš“';
}

.fa-signal-alt-slash:before {
    content: 'ïš”';
}

.fa-signal-slash:before {
    content: 'ïš•';
}

.fa-signature:before {
    content: 'ï–·';
}

.fa-sim-card:before {
    content: 'ïŸ„';
}

.fa-simplybuilt:before {
    content: 'ïˆ•';
}

.fa-sistrix:before {
    content: 'ï®';
}

.fa-sitemap:before {
    content: 'ïƒ¨';
}

.fa-sith:before {
    content: 'ï”’';
}

.fa-skating:before {
    content: 'ïŸ…';
}

.fa-skeleton:before {
    content: 'ï˜ ';
}

.fa-sketch:before {
    content: 'ïŸ†';
}

.fa-ski-jump:before {
    content: 'ïŸ‡';
}

.fa-ski-lift:before {
    content: 'ïŸˆ';
}

.fa-skiing:before {
    content: 'ïŸ‰';
}

.fa-skiing-nordic:before {
    content: 'ïŸŠ';
}

.fa-skull:before {
    content: 'ï•Œ';
}

.fa-skull-crossbones:before {
    content: 'ïœ”';
}

.fa-skyatlas:before {
    content: 'ïˆ–';
}

.fa-skype:before {
    content: 'ï…¾';
}

.fa-slack:before {
    content: 'ï†˜';
}

.fa-slack-hash:before {
    content: 'ï¯';
}

.fa-slash:before {
    content: 'ïœ•';
}

.fa-sledding:before {
    content: 'ïŸ‹';
}

.fa-sleigh:before {
    content: 'ïŸŒ';
}

.fa-sliders-h:before {
    content: 'ï‡ž';
}

.fa-sliders-h-square:before {
    content: 'ï°';
}

.fa-sliders-v:before {
    content: 'ï±';
}

.fa-sliders-v-square:before {
    content: 'ï²';
}

.fa-slideshare:before {
    content: 'ï‡§';
}

.fa-smile:before {
    content: 'ï„˜';
}

.fa-smile-beam:before {
    content: 'ï–¸';
}

.fa-smile-plus:before {
    content: 'ï–¹';
}

.fa-smile-wink:before {
    content: 'ï“š';
}

.fa-smog:before {
    content: 'ïŸ';
}

.fa-smoke:before {
    content: 'ï ';
}

.fa-smoking:before {
    content: 'ï’';
}

.fa-smoking-ban:before {
    content: 'ï•';
}

.fa-sms:before {
    content: 'ïŸ';
}

.fa-snake:before {
    content: 'ïœ–';
}

.fa-snapchat:before {
    content: 'ïŠ«';
}

.fa-snapchat-ghost:before {
    content: 'ïŠ¬';
}

.fa-snapchat-square:before {
    content: 'ïŠ­';
}

.fa-snow-blowing:before {
    content: 'ï¡';
}

.fa-snowboarding:before {
    content: 'ïŸŽ';
}

.fa-snowflake:before {
    content: 'ï‹œ';
}

.fa-snowflakes:before {
    content: 'ïŸ';
}

.fa-snowman:before {
    content: 'ïŸ';
}

.fa-snowmobile:before {
    content: 'ïŸ‘';
}

.fa-snowplow:before {
    content: 'ïŸ’';
}

.fa-socks:before {
    content: 'ïš–';
}

.fa-solar-panel:before {
    content: 'ï–º';
}

.fa-sort:before {
    content: 'ïƒœ';
}

.fa-sort-alpha-down:before {
    content: 'ï…';
}

.fa-sort-alpha-up:before {
    content: 'ï…ž';
}

.fa-sort-amount-down:before {
    content: 'ï… ';
}

.fa-sort-amount-up:before {
    content: 'ï…¡';
}

.fa-sort-down:before {
    content: 'ïƒ';
}

.fa-sort-numeric-down:before {
    content: 'ï…¢';
}

.fa-sort-numeric-up:before {
    content: 'ï…£';
}

.fa-sort-up:before {
    content: 'ïƒž';
}

.fa-soundcloud:before {
    content: 'ï†¾';
}

.fa-soup:before {
    content: 'ï £';
}

.fa-sourcetree:before {
    content: 'ïŸ“';
}

.fa-spa:before {
    content: 'ï–»';
}

.fa-space-shuttle:before {
    content: 'ï†—';
}

.fa-spade:before {
    content: 'ï‹´';
}

.fa-speakap:before {
    content: 'ï³';
}

.fa-spider:before {
    content: 'ïœ—';
}

.fa-spider-black-widow:before {
    content: 'ïœ˜';
}

.fa-spider-web:before {
    content: 'ïœ™';
}

.fa-spinner:before {
    content: 'ï„';
}

.fa-spinner-third:before {
    content: 'ï´';
}

.fa-splotch:before {
    content: 'ï–¼';
}

.fa-spotify:before {
    content: 'ï†¼';
}

.fa-spray-can:before {
    content: 'ï–½';
}

.fa-square:before {
    content: 'ïƒˆ';
}

.fa-square-full:before {
    content: 'ï‘œ';
}

.fa-square-root:before {
    content: 'ïš—';
}

.fa-square-root-alt:before {
    content: 'ïš˜';
}

.fa-squarespace:before {
    content: 'ï–¾';
}

.fa-squirrel:before {
    content: 'ïœš';
}

.fa-stack-exchange:before {
    content: 'ï†';
}

.fa-stack-overflow:before {
    content: 'ï…¬';
}

.fa-staff:before {
    content: 'ïœ›';
}

.fa-stamp:before {
    content: 'ï–¿';
}

.fa-star:before {
    content: 'ï€…';
}

.fa-star-and-crescent:before {
    content: 'ïš™';
}

.fa-star-christmas:before {
    content: 'ïŸ”';
}

.fa-star-exclamation:before {
    content: 'ï‹³';
}

.fa-star-half:before {
    content: 'ï‚‰';
}

.fa-star-half-alt:before {
    content: 'ï—€';
}

.fa-star-of-david:before {
    content: 'ïšš';
}

.fa-star-of-life:before {
    content: 'ï˜¡';
}

.fa-stars:before {
    content: 'ï¢';
}

.fa-staylinked:before {
    content: 'ïµ';
}

.fa-steak:before {
    content: 'ï ¤';
}

.fa-steam:before {
    content: 'ï†¶';
}

.fa-steam-square:before {
    content: 'ï†·';
}

.fa-steam-symbol:before {
    content: 'ï¶';
}

.fa-steering-wheel:before {
    content: 'ï˜¢';
}

.fa-step-backward:before {
    content: 'ïˆ';
}

.fa-step-forward:before {
    content: 'ï‘';
}

.fa-stethoscope:before {
    content: 'ïƒ±';
}

.fa-sticker-mule:before {
    content: 'ï·';
}

.fa-sticky-note:before {
    content: 'ï‰‰';
}

.fa-stocking:before {
    content: 'ïŸ•';
}

.fa-stomach:before {
    content: 'ï˜£';
}

.fa-stop:before {
    content: 'ï';
}

.fa-stop-circle:before {
    content: 'ïŠ';
}

.fa-stopwatch:before {
    content: 'ï‹²';
}

.fa-store:before {
    content: 'ï•Ž';
}

.fa-store-alt:before {
    content: 'ï•';
}

.fa-strava:before {
    content: 'ï¨';
}

.fa-stream:before {
    content: 'ï•';
}

.fa-street-view:before {
    content: 'ïˆ';
}

.fa-stretcher:before {
    content: 'ï ¥';
}

.fa-strikethrough:before {
    content: 'ïƒŒ';
}

.fa-stripe:before {
    content: 'ï©';
}

.fa-stripe-s:before {
    content: 'ïª';
}

.fa-stroopwafel:before {
    content: 'ï•‘';
}

.fa-studiovinari:before {
    content: 'ï¸';
}

.fa-stumbleupon:before {
    content: 'ï†¤';
}

.fa-stumbleupon-circle:before {
    content: 'ï†£';
}

.fa-subscript:before {
    content: 'ï„¬';
}

.fa-subway:before {
    content: 'ïˆ¹';
}

.fa-suitcase:before {
    content: 'ïƒ²';
}

.fa-suitcase-rolling:before {
    content: 'ï—';
}

.fa-sun:before {
    content: 'ï†…';
}

.fa-sun-cloud:before {
    content: 'ï£';
}

.fa-sun-dust:before {
    content: 'ï¤';
}

.fa-sun-haze:before {
    content: 'ï¥';
}

.fa-sunrise:before {
    content: 'ï¦';
}

.fa-sunset:before {
    content: 'ï§';
}

.fa-superpowers:before {
    content: 'ï‹';
}

.fa-superscript:before {
    content: 'ï„«';
}

.fa-supple:before {
    content: 'ï¹';
}

.fa-surprise:before {
    content: 'ï—‚';
}

.fa-suse:before {
    content: 'ïŸ–';
}

.fa-swatchbook:before {
    content: 'ï—ƒ';
}

.fa-swimmer:before {
    content: 'ï—„';
}

.fa-swimming-pool:before {
    content: 'ï—…';
}

.fa-sword:before {
    content: 'ïœœ';
}

.fa-swords:before {
    content: 'ïœ';
}

.fa-synagogue:before {
    content: 'ïš›';
}

.fa-sync:before {
    content: 'ï€¡';
}

.fa-sync-alt:before {
    content: 'ï‹±';
}

.fa-syringe:before {
    content: 'ï’Ž';
}

.fa-table:before {
    content: 'ïƒŽ';
}

.fa-table-tennis:before {
    content: 'ï‘';
}

.fa-tablet:before {
    content: 'ï„Š';
}

.fa-tablet-alt:before {
    content: 'ïº';
}

.fa-tablet-android:before {
    content: 'ï»';
}

.fa-tablet-android-alt:before {
    content: 'ï¼';
}

.fa-tablet-rugged:before {
    content: 'ï’';
}

.fa-tablets:before {
    content: 'ï’';
}

.fa-tachometer:before {
    content: 'ïƒ¤';
}

.fa-tachometer-alt:before {
    content: 'ï½';
}

.fa-tachometer-alt-average:before {
    content: 'ï˜¤';
}

.fa-tachometer-alt-fast:before {
    content: 'ï˜¥';
}

.fa-tachometer-alt-fastest:before {
    content: 'ï˜¦';
}

.fa-tachometer-alt-slow:before {
    content: 'ï˜§';
}

.fa-tachometer-alt-slowest:before {
    content: 'ï˜¨';
}

.fa-tachometer-average:before {
    content: 'ï˜©';
}

.fa-tachometer-fast:before {
    content: 'ï˜ª';
}

.fa-tachometer-fastest:before {
    content: 'ï˜«';
}

.fa-tachometer-slow:before {
    content: 'ï˜¬';
}

.fa-tachometer-slowest:before {
    content: 'ï˜­';
}

.fa-taco:before {
    content: 'ï ¦';
}

.fa-tag:before {
    content: 'ï€«';
}

.fa-tags:before {
    content: 'ï€¬';
}

.fa-tally:before {
    content: 'ïšœ';
}

.fa-tanakh:before {
    content: 'ï §';
}

.fa-tape:before {
    content: 'ï“›';
}

.fa-tasks:before {
    content: 'ï‚®';
}

.fa-tasks-alt:before {
    content: 'ï ¨';
}

.fa-taxi:before {
    content: 'ï†º';
}

.fa-teamspeak:before {
    content: 'ï“¹';
}

.fa-teeth:before {
    content: 'ï˜®';
}

.fa-teeth-open:before {
    content: 'ï˜¯';
}

.fa-telegram:before {
    content: 'ï‹†';
}

.fa-telegram-plane:before {
    content: 'ï¾';
}

.fa-temperature-frigid:before {
    content: 'ï¨';
}

.fa-temperature-high:before {
    content: 'ï©';
}

.fa-temperature-hot:before {
    content: 'ïª';
}

.fa-temperature-low:before {
    content: 'ï«';
}

.fa-tencent-weibo:before {
    content: 'ï‡•';
}

.fa-tenge:before {
    content: 'ïŸ—';
}

.fa-tennis-ball:before {
    content: 'ï‘ž';
}

.fa-terminal:before {
    content: 'ï„ ';
}

.fa-text-height:before {
    content: 'ï€´';
}

.fa-text-width:before {
    content: 'ï€µ';
}

.fa-th:before {
    content: 'ï€Š';
}

.fa-th-large:before {
    content: 'ï€‰';
}

.fa-th-list:before {
    content: 'ï€‹';
}

.fa-the-red-yeti:before {
    content: 'ïš';
}

.fa-theater-masks:before {
    content: 'ï˜°';
}

.fa-themeco:before {
    content: 'ï—†';
}

.fa-themeisle:before {
    content: 'ïŠ²';
}

.fa-thermometer:before {
    content: 'ï’‘';
}

.fa-thermometer-empty:before {
    content: 'ï‹‹';
}

.fa-thermometer-full:before {
    content: 'ï‹‡';
}

.fa-thermometer-half:before {
    content: 'ï‹‰';
}

.fa-thermometer-quarter:before {
    content: 'ï‹Š';
}

.fa-thermometer-three-quarters:before {
    content: 'ï‹ˆ';
}

.fa-theta:before {
    content: 'ïšž';
}

.fa-think-peaks:before {
    content: 'ïœ±';
}

.fa-thumbs-down:before {
    content: 'ï…¥';
}

.fa-thumbs-up:before {
    content: 'ï…¤';
}

.fa-thumbtack:before {
    content: 'ï‚';
}

.fa-thunderstorm:before {
    content: 'ï¬';
}

.fa-thunderstorm-moon:before {
    content: 'ï­';
}

.fa-thunderstorm-sun:before {
    content: 'ï®';
}

.fa-ticket:before {
    content: 'ï……';
}

.fa-ticket-alt:before {
    content: 'ï¿';
}

.fa-tilde:before {
    content: 'ïšŸ';
}

.fa-times:before {
    content: 'ï€';
}

.fa-times-circle:before {
    content: 'ï—';
}

.fa-times-hexagon:before {
    content: 'ï‹®';
}

.fa-times-octagon:before {
    content: 'ï‹°';
}

.fa-times-square:before {
    content: 'ï‹“';
}

.fa-tint:before {
    content: 'ïƒ';
}

.fa-tint-slash:before {
    content: 'ï—‡';
}

.fa-tire:before {
    content: 'ï˜±';
}

.fa-tire-flat:before {
    content: 'ï˜²';
}

.fa-tire-pressure-warning:before {
    content: 'ï˜³';
}

.fa-tire-rugged:before {
    content: 'ï˜´';
}

.fa-tired:before {
    content: 'ï—ˆ';
}

.fa-toggle-off:before {
    content: 'ïˆ„';
}

.fa-toggle-on:before {
    content: 'ïˆ…';
}

.fa-toilet:before {
    content: 'ïŸ˜';
}

.fa-toilet-paper:before {
    content: 'ïœž';
}

.fa-toilet-paper-alt:before {
    content: 'ïœŸ';
}

.fa-tombstone:before {
    content: 'ïœ ';
}

.fa-tombstone-alt:before {
    content: 'ïœ¡';
}

.fa-toolbox:before {
    content: 'ï•’';
}

.fa-tools:before {
    content: 'ïŸ™';
}

.fa-tooth:before {
    content: 'ï—‰';
}

.fa-toothbrush:before {
    content: 'ï˜µ';
}

.fa-torah:before {
    content: 'ïš ';
}

.fa-torii-gate:before {
    content: 'ïš¡';
}

.fa-tornado:before {
    content: 'ï¯';
}

.fa-tractor:before {
    content: 'ïœ¢';
}

.fa-trade-federation:before {
    content: 'ï”“';
}

.fa-trademark:before {
    content: 'ï‰œ';
}

.fa-traffic-cone:before {
    content: 'ï˜¶';
}

.fa-traffic-light:before {
    content: 'ï˜·';
}

.fa-traffic-light-go:before {
    content: 'ï˜¸';
}

.fa-traffic-light-slow:before {
    content: 'ï˜¹';
}

.fa-traffic-light-stop:before {
    content: 'ï˜º';
}

.fa-train:before {
    content: 'ïˆ¸';
}

.fa-tram:before {
    content: 'ïŸš';
}

.fa-transgender:before {
    content: 'ïˆ¤';
}

.fa-transgender-alt:before {
    content: 'ïˆ¥';
}

.fa-trash:before {
    content: 'ï‡¸';
}

.fa-trash-alt:before {
    content: 'ï‹­';
}

.fa-trash-restore:before {
    content: 'ï ©';
}

.fa-trash-restore-alt:before {
    content: 'ï ª';
}

.fa-treasure-chest:before {
    content: 'ïœ£';
}

.fa-tree:before {
    content: 'ï†»';
}

.fa-tree-alt:before {
    content: 'ï€';
}

.fa-tree-christmas:before {
    content: 'ïŸ›';
}

.fa-tree-decorated:before {
    content: 'ïŸœ';
}

.fa-tree-large:before {
    content: 'ïŸ';
}

.fa-tree-palm:before {
    content: 'ï «';
}

.fa-trees:before {
    content: 'ïœ¤';
}

.fa-trello:before {
    content: 'ï†';
}

.fa-triangle:before {
    content: 'ï‹¬';
}

.fa-tripadvisor:before {
    content: 'ï‰¢';
}

.fa-trophy:before {
    content: 'ï‚‘';
}

.fa-trophy-alt:before {
    content: 'ï‹«';
}

.fa-truck:before {
    content: 'ïƒ‘';
}

.fa-truck-container:before {
    content: 'ï“œ';
}

.fa-truck-couch:before {
    content: 'ï“';
}

.fa-truck-loading:before {
    content: 'ï“ž';
}

.fa-truck-monster:before {
    content: 'ï˜»';
}

.fa-truck-moving:before {
    content: 'ï“Ÿ';
}

.fa-truck-pickup:before {
    content: 'ï˜¼';
}

.fa-truck-plow:before {
    content: 'ïŸž';
}

.fa-truck-ramp:before {
    content: 'ï“ ';
}

.fa-tshirt:before {
    content: 'ï•“';
}

.fa-tty:before {
    content: 'ï‡¤';
}

.fa-tumblr:before {
    content: 'ï…³';
}

.fa-tumblr-square:before {
    content: 'ï…´';
}

.fa-turkey:before {
    content: 'ïœ¥';
}

.fa-turtle:before {
    content: 'ïœ¦';
}

.fa-tv:before {
    content: 'ï‰¬';
}

.fa-tv-retro:before {
    content: 'ï';
}

.fa-twitch:before {
    content: 'ï‡¨';
}

.fa-twitter:before {
    content: 'ï‚™';
}

.fa-twitter-square:before {
    content: 'ï‚';
}

.fa-typo3:before {
    content: 'ï«';
}

.fa-uber:before {
    content: 'ï‚';
}

.fa-ubuntu:before {
    content: 'ïŸŸ';
}

.fa-uikit:before {
    content: 'ïƒ';
}

.fa-umbrella:before {
    content: 'ïƒ©';
}

.fa-umbrella-beach:before {
    content: 'ï—Š';
}

.fa-underline:before {
    content: 'ïƒ';
}

.fa-undo:before {
    content: 'ïƒ¢';
}

.fa-undo-alt:before {
    content: 'ï‹ª';
}

.fa-unicorn:before {
    content: 'ïœ§';
}

.fa-union:before {
    content: 'ïš¢';
}

.fa-uniregistry:before {
    content: 'ï„';
}

.fa-universal-access:before {
    content: 'ïŠš';
}

.fa-university:before {
    content: 'ï†œ';
}

.fa-unlink:before {
    content: 'ï„§';
}

.fa-unlock:before {
    content: 'ï‚œ';
}

.fa-unlock-alt:before {
    content: 'ï„¾';
}

.fa-untappd:before {
    content: 'ï…';
}

.fa-upload:before {
    content: 'ï‚“';
}

.fa-ups:before {
    content: 'ïŸ ';
}

.fa-usb:before {
    content: 'ïŠ‡';
}

.fa-usd-circle:before {
    content: 'ï‹¨';
}

.fa-usd-square:before {
    content: 'ï‹©';
}

.fa-user:before {
    content: 'ï€‡';
}

.fa-user-alt:before {
    content: 'ï†';
}

.fa-user-alt-slash:before {
    content: 'ï“º';
}

.fa-user-astronaut:before {
    content: 'ï“»';
}

.fa-user-chart:before {
    content: 'ïš£';
}

.fa-user-check:before {
    content: 'ï“¼';
}

.fa-user-circle:before {
    content: 'ïŠ½';
}

.fa-user-clock:before {
    content: 'ï“½';
}

.fa-user-cog:before {
    content: 'ï“¾';
}

.fa-user-crown:before {
    content: 'ïš¤';
}

.fa-user-edit:before {
    content: 'ï“¿';
}

.fa-user-friends:before {
    content: 'ï”€';
}

.fa-user-graduate:before {
    content: 'ï”';
}

.fa-user-hard-hat:before {
    content: 'ï ¬';
}

.fa-user-headset:before {
    content: 'ï ­';
}

.fa-user-injured:before {
    content: 'ïœ¨';
}

.fa-user-lock:before {
    content: 'ï”‚';
}

.fa-user-md:before {
    content: 'ïƒ°';
}

.fa-user-md-chat:before {
    content: 'ï ®';
}

.fa-user-minus:before {
    content: 'ï”ƒ';
}

.fa-user-ninja:before {
    content: 'ï”„';
}

.fa-user-nurse:before {
    content: 'ï ¯';
}

.fa-user-plus:before {
    content: 'ïˆ´';
}

.fa-user-secret:before {
    content: 'ïˆ›';
}

.fa-user-shield:before {
    content: 'ï”…';
}

.fa-user-slash:before {
    content: 'ï”†';
}

.fa-user-tag:before {
    content: 'ï”‡';
}

.fa-user-tie:before {
    content: 'ï”ˆ';
}

.fa-user-times:before {
    content: 'ïˆµ';
}

.fa-users:before {
    content: 'ïƒ€';
}

.fa-users-class:before {
    content: 'ï˜½';
}

.fa-users-cog:before {
    content: 'ï”‰';
}

.fa-users-crown:before {
    content: 'ïš¥';
}

.fa-users-medical:before {
    content: 'ï °';
}

.fa-usps:before {
    content: 'ïŸ¡';
}

.fa-ussunnah:before {
    content: 'ï‡';
}

.fa-utensil-fork:before {
    content: 'ï‹£';
}

.fa-utensil-knife:before {
    content: 'ï‹¤';
}

.fa-utensil-spoon:before {
    content: 'ï‹¥';
}

.fa-utensils:before {
    content: 'ï‹§';
}

.fa-utensils-alt:before {
    content: 'ï‹¦';
}

.fa-vaadin:before {
    content: 'ïˆ';
}

.fa-value-absolute:before {
    content: 'ïš¦';
}

.fa-vector-square:before {
    content: 'ï—‹';
}

.fa-venus:before {
    content: 'ïˆ¡';
}

.fa-venus-double:before {
    content: 'ïˆ¦';
}

.fa-venus-mars:before {
    content: 'ïˆ¨';
}

.fa-viacoin:before {
    content: 'ïˆ·';
}

.fa-viadeo:before {
    content: 'ïŠ©';
}

.fa-viadeo-square:before {
    content: 'ïŠª';
}

.fa-vial:before {
    content: 'ï’’';
}

.fa-vials:before {
    content: 'ï’“';
}

.fa-viber:before {
    content: 'ï‰';
}

.fa-video:before {
    content: 'ï€½';
}

.fa-video-plus:before {
    content: 'ï“¡';
}

.fa-video-slash:before {
    content: 'ï“¢';
}

.fa-vihara:before {
    content: 'ïš§';
}

.fa-vimeo:before {
    content: 'ïŠ';
}

.fa-vimeo-square:before {
    content: 'ï†”';
}

.fa-vimeo-v:before {
    content: 'ï‰½';
}

.fa-vine:before {
    content: 'ï‡Š';
}

.fa-vk:before {
    content: 'ï†‰';
}

.fa-vnv:before {
    content: 'ï‹';
}

.fa-volcano:before {
    content: 'ï°';
}

.fa-volleyball-ball:before {
    content: 'ï‘Ÿ';
}

.fa-volume:before {
    content: 'ïš¨';
}

.fa-volume-down:before {
    content: 'ï€§';
}

.fa-volume-mute:before {
    content: 'ïš©';
}

.fa-volume-off:before {
    content: 'ï€¦';
}

.fa-volume-slash:before {
    content: 'ï‹¢';
}

.fa-volume-up:before {
    content: 'ï€¨';
}

.fa-vote-nay:before {
    content: 'ï±';
}

.fa-vote-yea:before {
    content: 'ï²';
}

.fa-vr-cardboard:before {
    content: 'ïœ©';
}

.fa-vuejs:before {
    content: 'ïŸ';
}

.fa-walker:before {
    content: 'ï ±';
}

.fa-walking:before {
    content: 'ï•”';
}

.fa-wallet:before {
    content: 'ï••';
}

.fa-wand:before {
    content: 'ïœª';
}

.fa-wand-magic:before {
    content: 'ïœ«';
}

.fa-warehouse:before {
    content: 'ï’”';
}

.fa-warehouse-alt:before {
    content: 'ï’•';
}

.fa-watch:before {
    content: 'ï‹¡';
}

.fa-watch-fitness:before {
    content: 'ï˜¾';
}

.fa-water:before {
    content: 'ï³';
}

.fa-water-lower:before {
    content: 'ï´';
}

.fa-water-rise:before {
    content: 'ïµ';
}

.fa-webcam:before {
    content: 'ï ²';
}

.fa-webcam-slash:before {
    content: 'ï ³';
}

.fa-weebly:before {
    content: 'ï—Œ';
}

.fa-weibo:before {
    content: 'ï†Š';
}

.fa-weight:before {
    content: 'ï’–';
}

.fa-weight-hanging:before {
    content: 'ï—';
}

.fa-weixin:before {
    content: 'ï‡—';
}

.fa-whale:before {
    content: 'ïœ¬';
}

.fa-whatsapp:before {
    content: 'ïˆ²';
}

.fa-whatsapp-square:before {
    content: 'ïŒ';
}

.fa-wheat:before {
    content: 'ïœ­';
}

.fa-wheelchair:before {
    content: 'ï†“';
}

.fa-whistle:before {
    content: 'ï‘ ';
}

.fa-whmcs:before {
    content: 'ï';
}

.fa-wifi:before {
    content: 'ï‡«';
}

.fa-wifi-1:before {
    content: 'ïšª';
}

.fa-wifi-2:before {
    content: 'ïš«';
}

.fa-wifi-slash:before {
    content: 'ïš¬';
}

.fa-wikipedia-w:before {
    content: 'ï‰¦';
}

.fa-wind:before {
    content: 'ïœ®';
}

.fa-wind-warning:before {
    content: 'ï¶';
}

.fa-window:before {
    content: 'ïŽ';
}

.fa-window-alt:before {
    content: 'ï';
}

.fa-window-close:before {
    content: 'ï';
}

.fa-window-maximize:before {
    content: 'ï‹';
}

.fa-window-minimize:before {
    content: 'ï‹‘';
}

.fa-window-restore:before {
    content: 'ï‹’';
}

.fa-windows:before {
    content: 'ï…º';
}

.fa-windsock:before {
    content: 'ï·';
}

.fa-wine-bottle:before {
    content: 'ïœ¯';
}

.fa-wine-glass:before {
    content: 'ï“£';
}

.fa-wine-glass-alt:before {
    content: 'ï—Ž';
}

.fa-wix:before {
    content: 'ï—';
}

.fa-wizards-of-the-coast:before {
    content: 'ïœ°';
}

.fa-wolf-pack-battalion:before {
    content: 'ï””';
}

.fa-won-sign:before {
    content: 'ï…™';
}

.fa-wordpress:before {
    content: 'ï†š';
}

.fa-wordpress-simple:before {
    content: 'ï‘';
}

.fa-wpbeginner:before {
    content: 'ïŠ—';
}

.fa-wpexplorer:before {
    content: 'ï‹ž';
}

.fa-wpforms:before {
    content: 'ïŠ˜';
}

.fa-wpressr:before {
    content: 'ï¤';
}

.fa-wreath:before {
    content: 'ïŸ¢';
}

.fa-wrench:before {
    content: 'ï‚­';
}

.fa-x-ray:before {
    content: 'ï’—';
}

.fa-xbox:before {
    content: 'ï’';
}

.fa-xing:before {
    content: 'ï…¨';
}

.fa-xing-square:before {
    content: 'ï…©';
}

.fa-y-combinator:before {
    content: 'ïˆ»';
}

.fa-yahoo:before {
    content: 'ï†ž';
}

.fa-yandex:before {
    content: 'ï“';
}

.fa-yandex-international:before {
    content: 'ï”';
}

.fa-yarn:before {
    content: 'ïŸ£';
}

.fa-yelp:before {
    content: 'ï‡©';
}

.fa-yen-sign:before {
    content: 'ï…—';
}

.fa-yin-yang:before {
    content: 'ïš­';
}

.fa-yoast:before {
    content: 'ïŠ±';
}

.fa-youtube:before {
    content: 'ï…§';
}

.fa-youtube-square:before {
    content: 'ï±';
}

.fa-zhihu:before {
    content: 'ï˜¿';
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.fab {
    font-family: 'Font Awesome 5 Brands';
}

.fal {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
}

body:not(.wp-admin) [data-sal] {
    transition-duration: 0.2s;
    transition-duration: var(--sal-duration, 0.2s);
    transition-delay: 0s;
    transition-delay: var(--sal-delay, 0s);
    transition-timing-function: ease;
    transition-timing-function: var(--sal-easing, ease);
}

body:not(.wp-admin) [data-sal][data-sal-duration='200'] {
    transition-duration: 0.2s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='250'] {
    transition-duration: 0.25s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='300'] {
    transition-duration: 0.3s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='350'] {
    transition-duration: 0.35s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='400'] {
    transition-duration: 0.4s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='450'] {
    transition-duration: 0.45s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='500'] {
    transition-duration: 0.5s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='550'] {
    transition-duration: 0.55s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='600'] {
    transition-duration: 0.6s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='650'] {
    transition-duration: 0.65s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='700'] {
    transition-duration: 0.7s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='750'] {
    transition-duration: 0.75s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='800'] {
    transition-duration: 0.8s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='850'] {
    transition-duration: 0.85s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='900'] {
    transition-duration: 0.9s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='950'] {
    transition-duration: 0.95s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1000'] {
    transition-duration: 1s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1050'] {
    transition-duration: 1.05s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1100'] {
    transition-duration: 1.1s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1150'] {
    transition-duration: 1.15s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1200'] {
    transition-duration: 1.2s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1250'] {
    transition-duration: 1.25s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1300'] {
    transition-duration: 1.3s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1350'] {
    transition-duration: 1.35s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1400'] {
    transition-duration: 1.4s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1450'] {
    transition-duration: 1.45s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1500'] {
    transition-duration: 1.5s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1550'] {
    transition-duration: 1.55s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1600'] {
    transition-duration: 1.6s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1650'] {
    transition-duration: 1.65s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1700'] {
    transition-duration: 1.7s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1750'] {
    transition-duration: 1.75s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1800'] {
    transition-duration: 1.8s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1850'] {
    transition-duration: 1.85s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1900'] {
    transition-duration: 1.9s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='1950'] {
    transition-duration: 1.95s;
}

body:not(.wp-admin) [data-sal][data-sal-duration='2000'] {
    transition-duration: 2s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='50'] {
    transition-delay: 0.05s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='100'] {
    transition-delay: 0.1s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='150'] {
    transition-delay: 0.15s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='200'] {
    transition-delay: 0.2s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='250'] {
    transition-delay: 0.25s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='300'] {
    transition-delay: 0.3s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='350'] {
    transition-delay: 0.35s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='400'] {
    transition-delay: 0.4s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='450'] {
    transition-delay: 0.45s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='500'] {
    transition-delay: 0.5s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='550'] {
    transition-delay: 0.55s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='600'] {
    transition-delay: 0.6s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='650'] {
    transition-delay: 0.65s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='700'] {
    transition-delay: 0.7s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='750'] {
    transition-delay: 0.75s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='800'] {
    transition-delay: 0.8s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='850'] {
    transition-delay: 0.85s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='900'] {
    transition-delay: 0.9s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='950'] {
    transition-delay: 0.95s;
}

body:not(.wp-admin) [data-sal][data-sal-delay='1000'] {
    transition-delay: 1s;
}

body:not(.wp-admin) [data-sal][data-sal-easing='linear'] {
    transition-timing-function: linear;
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease'] {
    transition-timing-function: ease;
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in'] {
    transition-timing-function: ease-in;
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out'] {
    transition-timing-function: ease-out;
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out'] {
    transition-timing-function: ease-in-out;
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-cubic'] {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-cubic'] {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-cubic'] {
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-circ'] {
    transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-circ'] {
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-circ'] {
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-expo'] {
    transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-expo'] {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-expo'] {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-quad'] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-quad'] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-quad'] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-quart'] {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-quart'] {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-quart'] {
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-quint'] {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-quint'] {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-quint'] {
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-sine'] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-sine'] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-sine'] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-back'] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-out-back'] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body:not(.wp-admin) [data-sal][data-sal-easing='ease-in-out-back'] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body:not(.wp-admin) [data-sal|='fade'] {
    opacity: 0;
    transition-property: opacity;
}

body:not(.wp-admin) .sal-animate[data-sal|='fade'],
body:not(.wp-admin) body.sal-disabled [data-sal|='fade'] {
    opacity: 1;
}

body:not(.wp-admin) [data-sal|='slide'] {
    opacity: 0;
    transition-property: opacity, transform;
}

body:not(.wp-admin) [data-sal='slide-up'] {
    transform: translateY(20%);
}

body:not(.wp-admin) [data-sal='slide-down'] {
    transform: translateY(-20%);
}

body:not(.wp-admin) [data-sal='slide-left'] {
    transform: translateX(20%);
}

body:not(.wp-admin) [data-sal='slide-right'] {
    transform: translateX(-20%);
}

body:not(.wp-admin) .sal-animate[data-sal|='slide'],
body:not(.wp-admin) body.sal-disabled [data-sal|='slide'] {
    opacity: 1;
    transform: none;
}

body:not(.wp-admin) [data-sal|='zoom'] {
    opacity: 0;
    transition-property: opacity, transform;
}

body:not(.wp-admin) [data-sal='zoom-in'] {
    transform: scale(0.5);
}

body:not(.wp-admin) [data-sal='zoom-out'] {
    transform: scale(1.1);
}

body:not(.wp-admin) .sal-animate[data-sal|='zoom'],
body:not(.wp-admin) body.sal-disabled [data-sal|='zoom'] {
    opacity: 1;
    transform: none;
}

body:not(.wp-admin) [data-sal|='flip'] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: transform;
}

body:not(.wp-admin) [data-sal='flip-left'] {
    transform: perspective(2000px) rotateY(-91deg);
}

body:not(.wp-admin) [data-sal='flip-right'] {
    transform: perspective(2000px) rotateY(91deg);
}

body:not(.wp-admin) [data-sal='flip-up'] {
    transform: perspective(2000px) rotateX(-91deg);
}

body:not(.wp-admin) [data-sal='flip-down'] {
    transform: perspective(2000px) rotateX(91deg);
}

body:not(.wp-admin) .sal-animate[data-sal|='flip'],
body:not(.wp-admin) body.sal-disabled [data-sal|='flip'] {
    transform: none;
}

body:not(.wp-admin) [data-sal='slide-up'] {
    --sal-duration: 0.75s;
    --sal-easing: ease-out-cubic;
    transform: translateY(2rem);
}

body:not(.wp-admin) :root {
    --sal-duration: 0.75s;
    --sal-easing: ease-out-cubic;
}

html.swipebox-html.swipebox-touch {
    overflow: hidden !important;
}

#swipebox-overlay img {
    border: none !important;
}

#swipebox-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999 !important;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#swipebox-container {
    position: relative;
    width: 100%;
    height: 100%;
}

#swipebox-slider {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    white-space: nowrap;
    position: absolute;
    display: none;
    cursor: pointer;
}

#swipebox-slider .slide {
    height: 100%;
    width: 100%;
    line-height: 1px;
    text-align: center;
    display: inline-block;
}

#swipebox-slider .slide:before {
    content: '';
    display: inline-block;
    height: 50%;
    width: 1px;
    margin-right: -1px;
}

#swipebox-slider .slide img,
#swipebox-slider .slide .swipebox-video-container,
#swipebox-slider .slide .swipebox-inline-container {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    vertical-align: middle;
}

#swipebox-slider .slide .swipebox-video-container {
    background: none;
    max-width: 1140px;
    max-height: 100%;
    width: 100%;
    padding: 5%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#swipebox-slider .slide .swipebox-video-container .swipebox-video {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
}

#swipebox-slider .slide .swipebox-video-container .swipebox-video iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}

#swipebox-bottom-bar,
#swipebox-top-bar {
    background-color: #fff;
    position: absolute;
    left: 0;
    z-index: 999;
    height: auto;
    width: 100%;
}

#swipebox-bottom-bar {
    bottom: 0;
    z-index: 100000;
}

#swipebox-top-bar {
    padding: 0 !important;
    top: 0;
}

#swipebox-title {
    display: block;
    width: 100%;
    text-align: center;
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
    background-repeat: no-repeat;
    border: none !important;
    text-decoration: none !important;
    cursor: pointer;
    top: 0;
    z-index: 100000;
}

#swipebox-arrows {
    display: block;
    margin: 0 auto;
    width: 100%;
}

#swipebox-prev.disabled,
#swipebox-next.disabled {
    opacity: 0.5;
}

.swipebox-no-touch #swipebox-overlay.rightSpring #swipebox-slider {
    -webkit-animation: rightSpring 0.3s;
    animation: rightSpring 0.3s;
}

.swipebox-no-touch #swipebox-overlay.leftSpring #swipebox-slider {
    -webkit-animation: leftSpring 0.3s;
    animation: leftSpring 0.3s;
}

.swipebox-touch #swipebox-container:before,
.swipebox-touch #swipebox-container:after {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    content: ' ';
    position: absolute;
    z-index: 999;
    top: 0;
    height: 100%;
    width: 20px;
    opacity: 0;
}

.swipebox-touch #swipebox-container:before {
    left: 0;
    -webkit-box-shadow: inset 10px 0px 10px -8px #656565;
    box-shadow: inset 10px 0px 10px -8px #656565;
}

.swipebox-touch #swipebox-container:after {
    right: 0;
    -webkit-box-shadow: inset -10px 0px 10px -8px #656565;
    box-shadow: inset -10px 0px 10px -8px #656565;
}

.swipebox-touch #swipebox-overlay.leftSpringTouch #swipebox-container:before {
    opacity: 1;
}

.swipebox-touch #swipebox-overlay.rightSpringTouch #swipebox-container:after {
    opacity: 1;
}

@-webkit-keyframes rightSpring {
    0% {
        left: 0;
    }

    50% {
        left: -30px;
    }

    100% {
        left: 0;
    }
}

@keyframes rightSpring {
    0% {
        left: 0;
    }

    50% {
        left: -30px;
    }

    100% {
        left: 0;
    }
}

@-webkit-keyframes leftSpring {
    0% {
        left: 0;
    }

    50% {
        left: 30px;
    }

    100% {
        left: 0;
    }
}

@keyframes leftSpring {
    0% {
        left: 0;
    }

    50% {
        left: 30px;
    }

    100% {
        left: 0;
    }
}

#swipebox-overlay {
    background-color: #fff;
}

#swipebox-bottom-bar,
#swipebox-top-bar {
    background-color: #fff;
    opacity: 1 !important;
}

#swipebox-arrows {
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#swipebox-top-bar {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

#swipebox-close {
    height: unset;
    width: unset;
    display: block;
    top: 0;
    right: 1rem;
    position: absolute;
    z-index: 9999;
    font-size: 1rem;
    color: #0c0c0c;
}

#swipebox-title-top {
    display: block;
    width: 100%;
    text-align: center;
}

#swipebox-overlay {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

#swipebox-overlay.opened {
    opacity: 1;
}

#swipebox-overlay.closing {
    opacity: 0 !important;
}

body.is-shop #swipebox-bottom-bar,
body.is-shop #swipebox-top-bar,
body.is-shop #swipebox-overlay {
    background-color: #fff !important;
    color: #ece9e6 !important;
}

body.is-shop #swipebox-next,
body.is-shop #swipebox-prev,
body.is-shop #swipebox-close {
    color: #ece9e6;
}

.ani-float {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate3d(0, 0, 0) rotate(0deg);
    }

    25% {
        transform: translate3d(0, 0.15rem, 0) rotate(-1deg);
    }

    50% {
        transform: translate3d(0, 0, 0) rotate(0deg);
    }

    75% {
        transform: translate3d(0, -0.15rem, 0) rotate(1deg);
    }

    100% {
        transform: translate3d(0, 0, 0) rotate(0deg);
    }
}

.ani-bounce {
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.ani-bounce-up {
    animation-name: bounce-up;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
        transform: scale(0.9, 1.1) translateY(-5px);
    }

    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }

    57% {
        transform: scale(1, 1) translateY(-2px);
    }

    64% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes bounce-up {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
        transform: scale(0.9, 1.1) translateY(5px);
    }

    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }

    57% {
        transform: scale(1, 1) translateY(2px);
    }

    64% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}

html {
    font-family: 'SweetSans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    width: 100%;
    height: auto;
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    overscroll-behavior: none;
}

@media (min-width: 1600px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 1922px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 1399.98px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 1194.98px) {
    html {
        font-size: 12px;
    }
}

body {
    color: #0c0c0c;
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.35;
    height: auto;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    font-variant-numeric: lining-nums;
    overscroll-behavior: none;
}

body.wp-admin {
    font-size: 14px;
}

::selection {
    background: #0c0c0c;
    color: #fff;
}

::-moz-selection {
    background: #0c0c0c;
    color: #fff;
}

p + p {
    margin-top: 1.35em;
}

b,
strong {
    font-weight: bold;
}

#loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000;
    pointer-events: none !important;
    background-color: #fff;
    transition: background-color 0.5s;
}

@media (max-width: 767.98px) {
    #loader {
        padding: 0 1.25rem;
    }
}

#loader.is-loaded {
    background-color: rgba(255, 255, 255, 0);
}

@media print {
    html,
    body {
        display: none;
    }
}

video {
    max-width: 100%;
    outline: none !important;
}

input:not([type='checkbox']),
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='submit']:not([type='checkbox']),
textarea[type='submit'] {
    cursor: pointer;
}

img {
    max-width: 100%;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
    padding-left: 0.25em;
}

body.preload-transitions * {
    transition: none !important;
}

.scroll-to {
    user-select: none;
    cursor: pointer;
}

.d-inline {
    display: inline;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-flex-inline {
    display: flex-inline;
}

.d-none {
    display: none;
}

section,
article {
    position: relative;
}

#main {
    position: relative;
    display: block;
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}

.container {
    position: relative;
    width: 1142px;
    max-width: 100%;
    max-width: 100%;
    max-width: calc(100vw - (2rem * 2));
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1194.98px) {
    .container {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 767.98px) {
    .container {
        width: 100%;
        max-width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 767.98px) and (max-width: 1194.98px) {
    .container {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: calc(100vw - (2 * 2rem));
        width: 1600px;
    }
}

.container-small,
.page-template-template-gallery .block.block-heading:first-of-type {
    position: relative;
    max-width: 100%;
    max-width: 100%;
    width: 860px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1194.98px) {
    .container-small,
    .page-template-template-gallery .block.block-heading:first-of-type {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.container-xsmall {
    position: relative;
    max-width: 100%;
    max-width: 100%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1194.98px) {
    .container-xsmall {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.container-large {
    position: relative;
    max-width: calc(100vw - (2 * 2rem));
    width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1399.98px) {
    .container-large {
        max-width: calc(100vw - (2 * 1.5rem));
    }
}

.container-xsmall {
    position: relative;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

@media (max-width: 1194.98px) {
    .container-xsmall {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.container-fluid {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.h-100 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.mh-100 {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}

.abs-center,
.block-statement.has-image .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.flex-center,
.header-menu-container > ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-cols,
.flex-cols {
    display: flex;
}

.f-cols.reverse,
.flex-cols.reverse {
    flex-direction: row-reverse;
}

.f-cols .col-left,
.flex-cols .col-left {
    padding-right: 2rem;
}

.f-cols .col-right,
.flex-cols .col-right {
    padding-left: 2rem;
}

.f-cols .col-25,
.f-cols .col-33,
.f-cols .col-50,
.f-cols .col-66,
.f-cols .col-75,
.f-cols .col-100,
.flex-cols .col-25,
.flex-cols .col-33,
.flex-cols .col-50,
.flex-cols .col-66,
.flex-cols .col-75,
.flex-cols .col-100 {
    position: relative;
}

.flex-a-center {
    align-items: center;
}

.flex-a-end {
    align-items: flex-end;
}

.flex-a-start {
    align-items: flex-start;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-j-space-between,
.flex-j-sb {
    justify-content: space-between;
}

.flex-j-center,
.flex-j-c {
    justify-content: center;
}

.col-10 {
    width: 10%;
}

.col-12 {
    width: 12.5%;
}

.col-15 {
    width: 15%;
}

.col-16 {
    width: 16.66%;
}

.col-20 {
    width: 20%;
}

.col-25 {
    width: 25%;
}

.col-33 {
    width: 33.33%;
}

.col-40 {
    width: 40%;
}

.col-50 {
    width: 50%;
}

.col-60 {
    width: 60%;
}

.col-66 {
    width: 66.66%;
}

.col-75 {
    width: 75%;
}

.col-80 {
    width: 80%;
}

.col-83 {
    width: 83.34%;
}

.col-100 {
    width: 100%;
}

.col-container {
    display: flex;
    flex-wrap: wrap;
}

ul.list-commas {
    list-style: none;
    display: block;
    position: relative;
}

ul.list-commas li {
    list-style: none;
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
}

ul.list-commas li:after {
    content: ',';
    position: absolute;
    display: inline-block;
    left: 100%;
    bottom: 0;
}

ul.list-commas li a {
    position: relative;
}

ul.list-commas li:last-child:after {
    display: none;
}

.image {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.image img,
.image video {
    width: 100%;
    height: auto;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.background img,
.background video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
}

.background.has-fade {
    background-color: #040f1b;
}

.background.has-fade img,
.background.has-fade video {
    opacity: 0.75;
}

.block-image-text .background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.block-image-text .background img,
.block-image-text .background video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

[style*='--aspect-ratio'] > :first-child {
    width: 100%;
}

[style*='--aspect-ratio'] > img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@supports (--custom: property) {
    [style*='--aspect-ratio'] {
        position: relative;
    }

    [style*='--aspect-ratio']::before {
        content: '';
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }

    [style*='--aspect-ratio'] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

.sticky-top {
    position: sticky;
    top: 0;
}

.grid {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    grid-auto-rows: minmax(min-content, max-content);
}

@media (max-width: 767.98px) {
    .grid {
        grid-gap: 1.25rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid.grid-1 {
    grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 1600px) {
    .grid.grid-1 {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 767.98px) {
    .grid.grid-1 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid.grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1600px) {
    .grid.grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767.98px) {
    .grid.grid-2 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 1600px) {
    .grid.grid-3 {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 767.98px) {
    .grid.grid-3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid.grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 767.98px) {
    .grid.grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid.grid-5 {
    grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 767.98px) {
    .grid.grid-5 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid.grid-6 {
    grid-template-columns: repeat(6, 1fr);
}

@media (max-width: 767.98px) {
    .grid.grid-6 {
        grid-template-columns: repeat(3, 1fr);
    }
}

.grid.grid-7 {
    grid-template-columns: repeat(7, 1fr);
}

@media (max-width: 767.98px) {
    .grid.grid-7 {
        grid-template-columns: repeat(3, 1fr);
    }
}

.grid.grid-8 {
    grid-template-columns: repeat(8, 1fr);
}

@media (max-width: 767.98px) {
    .grid.grid-8 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1195px) {
    .md-only {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .sm-only {
        display: none !important;
    }
}

@media (max-width: 1194.98px) {
    .desktop-only {
        display: none !important;
    }
}

@media (max-width: 767.98px) {
    .desktop-tablet-only {
        display: none !important;
    }
}

@media (max-width: 767.98px) {
    .sm-hide {
        display: none !important;
    }
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.text-bold {
    font-weight: 600;
}

.text-underline {
    text-decoration: underline;
    text-underline-position: under;
}

.text-italic {
    font-style: italic;
}

.text-spacing {
    letter-spacing: unset;
}

.text-spacing-lg {
    letter-spacing: unset;
}

.text-uppercase {
    text-transform: uppercase;
}

.tt-none {
    text-transform: none !important;
}

.color-white {
    color: #fff;
}

.color-black {
    color: #0c0c0c;
}

.color-blue-light,
.text-content p a {
    color: #9fb9c8;
}

.color-blue-dark,
.share-container .share-links .share-item a {
    color: #040f1b;
}

.color-grey-light {
    color: #ece9e6;
}

.color-grey-dark,
.pagination > ul li > a,
.header-menu-container > ul {
    color: #888;
}

.bg-white {
    background-color: #fff;
}

.bg-black {
    background-color: #0c0c0c;
}

.bg-blue-light {
    background-color: #9fb9c8;
}

.bg-blue-lighter {
    background-color: #f0f4f6;
}

.bg-blue-dark {
    background-color: #040f1b;
}

.bg-grey-light {
    background-color: #ece9e6;
}

.bg-grey-dark {
    background-color: #888;
}

.ws-nowrap {
    white-space: nowrap;
}

.text-size-normal {
    font-size: 1rem;
}

.font-primary,
.text-heading-sm,
.text-heading-link,
#cookie-law-info-bar > span #cookie_action_close_header,
.pagination > ul li > a,
.header-menu-container > ul,
#lostpasswordform fieldset label,
#resetpasswordform fieldset label,
.text-heading-micro,
.text-heading-xl,
.text-heading-lg,
.text-heading-md,
.text-heading,
.share-container .share-links .share-item a,
body:not(.wp-admin) .button,
.text-xl,
.text-lg,
.text-mdlg,
.text-menu,
ul.featured-projects-list,
ul.solutions-list,
.menu-overlay .menu-overlay-container > ul.menu,
.text-md,
.text-sm,
.text-body-sm,
.text-body,
#swipebox-title,
.menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
.menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu,
.text-paragraph {
    font-family: 'SweetSans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.font-secondary,
.text-sec-sm,
.text-sec,
ul.solutions-list > li > ul.sub-menu,
.text-sec-lg,
.text-statement,
.text-sec-md,
.text-testimonial {
    font-family: 'Didot', 'Times', 'Times New Roman', serif;
}

.text-body,
#swipebox-title,
.menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
.menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu,
.text-paragraph {
    font-size: 1rem;
    line-height: 1.35;
}

.text-body-sm {
    font-size: 0.875rem;
    line-height: 1.35;
}

.text-sm {
    font-size: 0.85rem;
}

@media (max-width: 1194.98px) {
    .text-sm {
        font-size: 0.8rem;
    }
}

.text-md {
    font-size: 1.285rem;
}

@media (max-width: 767.98px) {
    .text-md {
        font-size: 1.25rem;
    }
}

.text-mdlg,
.text-menu,
ul.featured-projects-list,
ul.solutions-list,
.menu-overlay .menu-overlay-container > ul.menu {
    font-size: 1.85rem;
}

@media (max-width: 1194.98px) {
    .text-mdlg,
    .text-menu,
    ul.featured-projects-list,
    ul.solutions-list,
    .menu-overlay .menu-overlay-container > ul.menu {
        font-size: 1.35rem;
    }
}

.text-mdlg.mw-text,
.mw-text.text-menu,
ul.mw-text.featured-projects-list,
ul.mw-text.solutions-list,
.menu-overlay .menu-overlay-container > ul.mw-text.menu {
    max-width: 20em;
}

.text-lg {
    font-size: 2.75rem;
}

@media (max-width: 767.98px) {
    .text-lg {
        font-size: 2.15rem;
        line-height: 1;
    }
}

.text-xl {
    font-size: 3.5rem;
    line-height: 1.2;
}

@media (max-width: 1399.98px) {
    .text-xl {
        font-size: 3rem;
    }
}

@media (max-width: 767.98px) {
    .text-xl {
        font-size: 2.15rem;
    }
}

@media (min-width: 2400px) {
    .text-xl {
        font-size: 4.75rem;
    }
}

.text-sec,
ul.solutions-list > li > ul.sub-menu,
.text-sec-lg,
.text-statement,
.text-sec-md,
.text-testimonial {
    font-style: italic;
    line-height: 0.8;
}

.text-sec-sm {
    font-size: 2.15rem;
    font-style: italic;
    line-height: 0.8;
}

@media (max-width: 767.98px) {
    .text-sec-sm {
        font-size: 2rem;
    }
}

.text-sec-md,
.text-testimonial {
    font-size: 2.85rem;
}

@media (max-width: 767.98px) {
    .text-sec-md,
    .text-testimonial {
        font-size: 2rem;
    }
}

.text-sec-lg,
.text-statement {
    font-size: 4.285rem;
}

@media (max-width: 767.98px) {
    .text-sec-lg,
    .text-statement {
        font-size: 2.5rem;
    }
}

.text-w,
.mw-text,
.mw-form-sm {
    max-width: 33em;
}

.mw-text-sm {
    max-width: 25em;
}

.text-wr {
    max-width: 48rem;
}

.text-w-md,
.mw-text-md {
    max-width: 45em;
}

.mw-headline {
    width: 20em;
    max-width: 100%;
}

h1 + p,
h2 + p,
h3 + p,
h1 + div[class^='text-'],
h2 + div[class^='text-'],
h3 + div[class^='text-'] {
    margin-top: 1.35rem;
}

.link-underline {
    text-decoration: underline;
}

.no-underline,
.footer-menu-container ul.menu > li a,
.text-content p a,
.text-paragraph a {
    text-decoration: none;
}

.lh-1 {
    line-height: 1;
}

blockquote p + footer {
    margin-top: 4.65rem;
}

body:not(.wp-admin) .button {
    display: inline-block;
    position: relative;
    background-color: #040f1b;
    color: #fff;
    line-height: 4.5;
    text-align: center;
    padding: 0 4.5em;
    border-radius: 10rem;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: color 0.25s ease, background-color 0.25s ease;
    cursor: pointer;
    border: 0;
    outline: none;
    white-space: nowrap;
}

@media (max-width: 767.98px) {
    body:not(.wp-admin) .button {
        font-size: 0.7rem;
    }
}

body:not(.wp-admin) .button:active:hover {
    transform: scale(0.975) !important;
}

body:not(.wp-admin) .button:hover {
    color: #040f1b;
    background-color: #9fb9c8;
}

body:not(.wp-admin) .button.clear {
    color: #040f1b;
    background-color: transparent !important;
    padding: 0 1em;
}

body:not(.wp-admin) .button.clear:hover {
    color: #9fb9c8;
    background-color: transparent;
}

.text-heading,
.share-container .share-links .share-item a {
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.285rem;
    letter-spacing: 0.1em;
}

@media (max-width: 767.98px) {
    .text-heading,
    .share-container .share-links .share-item a {
        font-size: 1.25rem;
    }
}

.text-heading-md {
    line-height: 1;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.15rem;
    letter-spacing: 0.1em;
}

@media (max-width: 767.98px) {
    .text-heading-md {
        font-size: 1.15rem;
    }
}

.text-heading-lg {
    line-height: 1;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.85rem;
    letter-spacing: 0.1em;
}

@media (max-width: 767.98px) {
    .text-heading-lg {
        font-size: 1.35rem;
    }
}

.text-heading-xl {
    line-height: 1;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 3.5rem;
    letter-spacing: 0.1em;
}

@media (max-width: 767.98px) {
    .text-heading-xl {
        font-size: 2.15rem;
    }
}

.text-heading-link,
#cookie-law-info-bar > span #cookie_action_close_header,
.pagination > ul li > a,
.header-menu-container > ul,
#lostpasswordform fieldset label,
#resetpasswordform fieldset label,
.text-heading-micro {
    line-height: 1;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-decoration: none;
}

@media (max-width: 767.98px) {
    .text-heading-link,
    #cookie-law-info-bar > span #cookie_action_close_header,
    .pagination > ul li > a,
    .header-menu-container > ul,
    #lostpasswordform fieldset label,
    #resetpasswordform fieldset label,
    .text-heading-micro {
        font-size: 0.7rem;
    }
}

.text-heading-sm {
    line-height: 1;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.85rem;
    letter-spacing: 0.1em;
    text-decoration: none;
}

@media (max-width: 767.98px) {
    .text-heading-sm {
        font-size: 0.75rem;
    }
}

@media (max-width: 767.98px) {
    .text-heading-sm {
        font-size: 0.85rem;
    }
}

.text-statement {
    text-transform: uppercase;
    line-height: 1;
}

.text-testimonial {
    line-height: 1.25;
}

.text-menu,
ul.featured-projects-list,
ul.solutions-list,
.menu-overlay .menu-overlay-container > ul.menu {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-decoration: none;
}

@media (max-width: 1599.98px) {
    .text-menu,
    ul.featured-projects-list,
    ul.solutions-list,
    .menu-overlay .menu-overlay-container > ul.menu {
        font-size: 1.5rem;
    }
}

@media (max-width: 1194.98px) {
    .text-menu,
    ul.featured-projects-list,
    ul.solutions-list,
    .menu-overlay .menu-overlay-container > ul.menu {
        font-size: 2rem;
    }
}

@media (max-width: 767.98px) {
    .text-menu,
    ul.featured-projects-list,
    ul.solutions-list,
    .menu-overlay .menu-overlay-container > ul.menu {
        font-size: 1.5rem;
    }
}

.text-paragraph {
    line-height: 1.5;
}

.text-paragraph a {
    color: #040f1b;
}

a.color-blue-light,
.text-content p a,
a.flex-grid-item .text-heading-link.color-blue-light,
a.flex-grid-item #cookie-law-info-bar > span #cookie_action_close_header.color-blue-light,
#cookie-law-info-bar > span a.flex-grid-item #cookie_action_close_header.color-blue-light,
.pagination > ul a.flex-grid-item li > a.color-blue-light,
a.flex-grid-item .header-menu-container > ul.color-blue-light,
a.flex-grid-item #lostpasswordform fieldset label.color-blue-light,
#lostpasswordform fieldset a.flex-grid-item label.color-blue-light,
a.flex-grid-item #resetpasswordform fieldset label.color-blue-light,
#resetpasswordform fieldset a.flex-grid-item label.color-blue-light,
a.color-grey-dark,
.pagination > ul li > a {
    transition: color 0.25s ease;
}

a.color-blue-light:hover,
.text-content p a:hover,
a.flex-grid-item .text-heading-link.color-blue-light:hover,
a.flex-grid-item #cookie-law-info-bar > span #cookie_action_close_header.color-blue-light:hover,
#cookie-law-info-bar > span a.flex-grid-item #cookie_action_close_header.color-blue-light:hover,
a.flex-grid-item .header-menu-container > ul.color-blue-light:hover,
a.flex-grid-item #lostpasswordform fieldset label.color-blue-light:hover,
#lostpasswordform fieldset a.flex-grid-item label.color-blue-light:hover,
a.flex-grid-item #resetpasswordform fieldset label.color-blue-light:hover,
#resetpasswordform fieldset a.flex-grid-item label.color-blue-light:hover,
a.color-grey-dark:hover,
.pagination > ul li > a:hover {
    color: #040f1b;
}

a.color-blue-dark,
.share-container .share-links .share-item a {
    transition: color 0.25s ease;
}

a.color-blue-dark:hover,
.share-container .share-links .share-item a:hover {
    color: #9fb9c8;
}

a.flex-grid-item:hover .text-heading-link.color-blue-light,
a.flex-grid-item:hover #cookie-law-info-bar > span #cookie_action_close_header.color-blue-light,
#cookie-law-info-bar > span a.flex-grid-item:hover #cookie_action_close_header.color-blue-light,
a.flex-grid-item:hover .pagination > ul li > a.color-blue-light,
.pagination > ul a.flex-grid-item:hover li > a.color-blue-light,
a.flex-grid-item:hover .header-menu-container > ul.color-blue-light,
a.flex-grid-item:hover #lostpasswordform fieldset label.color-blue-light,
#lostpasswordform fieldset a.flex-grid-item:hover label.color-blue-light,
a.flex-grid-item:hover #resetpasswordform fieldset label.color-blue-light,
#resetpasswordform fieldset a.flex-grid-item:hover label.color-blue-light,
a.flex-grid-item:hover .text-content p a.text-heading-link,
a.flex-grid-item:hover .text-content p #cookie-law-info-bar > span a#cookie_action_close_header,
#cookie-law-info-bar > span a.flex-grid-item:hover .text-content p a#cookie_action_close_header,
a.flex-grid-item:hover .text-content p .pagination > ul li > a,
.pagination > ul a.flex-grid-item:hover .text-content p li > a,
.text-content p a.flex-grid-item:hover a.text-heading-link,
.text-content p a.flex-grid-item:hover #cookie-law-info-bar > span a#cookie_action_close_header,
#cookie-law-info-bar > span .text-content p a.flex-grid-item:hover a#cookie_action_close_header,
.text-content p a.flex-grid-item:hover .pagination > ul li > a,
.pagination > ul .text-content p a.flex-grid-item:hover li > a {
    color: #040f1b;
}

.block.bg-blue-dark a.color-blue-light:hover,
.block.bg-blue-dark .text-content p a:hover,
.text-content p .block.bg-blue-dark a:hover {
    color: #888;
}

.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5 {
    font-size: 1.15rem;
    color: #040f1b;
}

.text-content img.aligncenter {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.container-accordion {
    position: relative;
    border-top: 2px solid #040f1b;
}

.container-accordion:last-of-type {
    margin-bottom: 0;
}

.accordion-item {
    border-bottom: 1px solid #ece9e6;
}

.accordion-item .accordion-title {
    line-height: 4.5;
    position: relative;
    cursor: pointer;
    transition: color 0.5s;
}

@media (max-width: 767.98px) {
    .accordion-item .accordion-title {
        border-width: 1px !important;
        font-size: 0.8rem !important;
    }
}

.accordion-item .accordion-title > * {
    pointer-events: none;
}

.accordion-item .accordion-title:before {
    content: '';
    height: 21px;
    width: 1px;
    background-color: currentColor;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    transition: all 0.5s ease;
}

@media (max-width: 767.98px) {
    .accordion-item .accordion-title:before {
        height: 17px;
        top: calc(50% - 8px);
        right: 8px;
    }
}

.accordion-item .accordion-title:after {
    content: '';
    width: 21px;
    height: 1px;
    background-color: currentColor;
    position: absolute;
    right: 0;
    top: 50%;
    transition: all 0.5s ease;
}

@media (max-width: 767.98px) {
    .accordion-item .accordion-title:after {
        width: 17px;
    }
}

.accordion-item .accordion-content {
    padding-top: 2rem;
    padding-bottom: 3rem;
    position: relative;
    opacity: 0;
    transition: opacity 1s ease;
    display: none;
}

.accordion-item .accordion-content p {
    max-width: 33.5em;
}

@media (max-width: 767.98px) {
    .accordion-item .accordion-content {
        border-width: 1px !important;
    }
}

.accordion-item.is-active .accordion-title {
    color: #040f1b;
}

.accordion-item.is-active .accordion-title:after {
    transform: rotate(-180deg) !important;
}

.accordion-item.is-active .accordion-title:before {
    transform: rotate(-90deg) !important;
    opacity: 0 !important;
}

.accordion-item.is-active .accordion-content {
    opacity: 1;
}

.accordion-item:hover .accordion-title {
    color: #040f1b;
}

.list-inline {
    display: flex;
}

.list-inline.align-right {
    justify-content: flex-end;
}

.list-inline li {
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
}

.list-inline li:last-child {
    padding-right: 0;
}

.list-inline li:first-child {
    padding-left: 0;
}

@media (max-width: 767.98px) {
    .list-inline li {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.list-inline.has-separator.align-right li + li:before {
    content: '';
    width: 1px;
    height: 200%;
    top: -50%;
    background-color: #ece9e6;
    position: absolute;
    left: 0;
}

.list-inline.share-links li {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.list-inline.share-links li:last-child {
    padding-right: 0;
}

.list-inline.share-links li:first-child {
    padding-left: 0;
}

input {
    border-radius: 0;
}

.acfe-form.acf-form.form-hide-labels .acf-label,
#loginform.form-hide-labels .acf-label,
#lostpasswordform.form-hide-labels .acf-label,
#resetpasswordform.form-hide-labels .acf-label {
    display: none;
}

.acfe-form.acf-form .acf-fields,
#loginform .acf-fields,
#lostpasswordform .acf-fields,
#resetpasswordform .acf-fields {
    padding-bottom: 2.5rem;
}

.acfe-form.acf-form .acf-field,
#loginform .acf-field,
#lostpasswordform .acf-field,
#resetpasswordform .acf-field {
    border: 0 !important;
}

.acfe-form.acf-form .acf-field,
#loginform .acf-field,
#lostpasswordform .acf-field,
#resetpasswordform .acf-field {
    font: inherit;
}

.acfe-form.acf-form .acf-field.field-left,
#loginform .acf-field.field-left,
#lostpasswordform .acf-field.field-left,
#resetpasswordform .acf-field.field-left {
    padding-right: 0;
}

.acfe-form.acf-form .acf-field.field-right,
#loginform .acf-field.field-right,
#lostpasswordform .acf-field.field-right,
#resetpasswordform .acf-field.field-right {
    padding-left: 0;
}

.acfe-form.acf-form .acf-field input,
.acfe-form.acf-form .acf-field textarea,
#loginform .acf-field input,
#loginform .acf-field textarea,
#lostpasswordform .acf-field input,
#lostpasswordform .acf-field textarea,
#resetpasswordform .acf-field input,
#resetpasswordform .acf-field textarea {
    font: inherit !important;
}

.acfe-form.acf-form .acf-field.acf-field-true-false,
#loginform .acf-field.acf-field-true-false,
#lostpasswordform .acf-field.acf-field-true-false,
#resetpasswordform .acf-field.acf-field-true-false {
    text-align: left;
    color: #888;
}

.acfe-form.acf-form .acf-field.acf-field-true-false + .acf-field-true-false,
#loginform .acf-field.acf-field-true-false + .acf-field-true-false,
#lostpasswordform .acf-field.acf-field-true-false + .acf-field-true-false,
#resetpasswordform .acf-field.acf-field-true-false + .acf-field-true-false {
    padding-top: 0 !important;
}

.acfe-form.acf-form input[type='text'],
.acfe-form.acf-form input[type='email'],
.acfe-form.acf-form input[type='password'],
.acfe-form.acf-form textarea,
#loginform input[type='text'],
#loginform input[type='email'],
#loginform input[type='password'],
#loginform textarea,
#lostpasswordform input[type='text'],
#lostpasswordform input[type='email'],
#lostpasswordform input[type='password'],
#lostpasswordform textarea,
#resetpasswordform input[type='text'],
#resetpasswordform input[type='email'],
#resetpasswordform input[type='password'],
#resetpasswordform textarea {
    background-color: transparent;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #ece9e6;
    resize: none;
}

.acfe-form.acf-form .acf-form-submit,
#loginform .acf-form-submit,
#lostpasswordform .acf-form-submit,
#resetpasswordform .acf-form-submit {
    cursor: pointer;
}

.acfe-form.acf-form .acf-form-submit input.button,
#loginform .acf-form-submit input.button,
#lostpasswordform .acf-form-submit input.button,
#resetpasswordform .acf-form-submit input.button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

.acfe-form.acf-form input[type='checkbox'],
#loginform input[type='checkbox'],
#lostpasswordform input[type='checkbox'],
#resetpasswordform input[type='checkbox'] {
    display: none;
}

.acfe-form.acf-form input[type='checkbox'] + span.message,
#loginform input[type='checkbox'] + span.message,
#lostpasswordform input[type='checkbox'] + span.message,
#resetpasswordform input[type='checkbox'] + span.message {
    user-select: none;
    cursor: pointer;
    color: #888;
}

.acfe-form.acf-form input[type='checkbox'] + span.message a,
#loginform input[type='checkbox'] + span.message a,
#lostpasswordform input[type='checkbox'] + span.message a,
#resetpasswordform input[type='checkbox'] + span.message a {
    color: currentColor;
    text-decoration: underline;
}

.acfe-form.acf-form input[type='checkbox'] + span.message:before,
#loginform input[type='checkbox'] + span.message:before,
#lostpasswordform input[type='checkbox'] + span.message:before,
#resetpasswordform input[type='checkbox'] + span.message:before {
    font-family: 'Font Awesome 5 Pro';
    display: inline-block;
    width: 1.5em;
    color: currentColor;
    font-weight: 400;
}

.acfe-form.acf-form input[type='checkbox'] + span.message:before,
#loginform input[type='checkbox'] + span.message:before,
#lostpasswordform input[type='checkbox'] + span.message:before,
#resetpasswordform input[type='checkbox'] + span.message:before {
    content: 'ïƒˆ';
}

.acfe-form.acf-form input[type='checkbox']:checked,
#loginform input[type='checkbox']:checked,
#lostpasswordform input[type='checkbox']:checked,
#resetpasswordform input[type='checkbox']:checked {
    color: #040f1b;
}

.acfe-form.acf-form input[type='checkbox']:checked + span.message,
#loginform input[type='checkbox']:checked + span.message,
#lostpasswordform input[type='checkbox']:checked + span.message,
#resetpasswordform input[type='checkbox']:checked + span.message {
    color: #040f1b;
}

.acfe-form.acf-form input[type='checkbox']:checked + span.message:before,
#loginform input[type='checkbox']:checked + span.message:before,
#lostpasswordform input[type='checkbox']:checked + span.message:before,
#resetpasswordform input[type='checkbox']:checked + span.message:before {
    content: 'ï…Š';
}

.form-response {
    opacity: 0;
    transition: opacity 0.25s;
    pointer-events: none;
}

.form-response.is-active {
    opacity: 1;
}

body:not(.wp-admin) .select-container,
body:not(.wp-admin) .acf-field-select {
    position: relative;
    display: flex;
    width: 100%;
}

body:not(.wp-admin) .select-container .acf-input,
body:not(.wp-admin) .acf-field-select .acf-input {
    width: 100%;
}

body:not(.wp-admin) .select-container select,
body:not(.wp-admin) .select-container .acf-input select,
body:not(.wp-admin) .acf-field-select select,
body:not(.wp-admin) .acf-field-select .acf-input select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0;
    background: #fff;
    background-image: none;
    border-bottom: 1px solid #ece9e6;
    flex: 1;
    padding: 4px 8px;
    color: #888;
    cursor: pointer;
    border-radius: 0;
    transition: border-color 0.25s ease;
    font: inherit !important;
}

body:not(.wp-admin) .select-container select::-ms-expand,
body:not(.wp-admin) .select-container .acf-input select::-ms-expand,
body:not(.wp-admin) .acf-field-select select::-ms-expand,
body:not(.wp-admin) .acf-field-select .acf-input select::-ms-expand {
    display: none;
}

body:not(.wp-admin) .select-container.acf-field-select:after,
body:not(.wp-admin) .acf-field-select.acf-field-select:after {
    top: 20px;
    right: 10px;
}

body:not(.wp-admin) .select-container::after,
body:not(.wp-admin) .acf-field-select::after {
    content: 'ï¸';
    font-family: 'Font Awesome 5 Pro';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0.25em 0 1em;
    background: transparent;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
    color: #888;
    font-size: 0.85rem;
}

body:not(.wp-admin) .select-container:hover,
body:not(.wp-admin) .acf-field-select:hover {
    border-color: #040f1b;
}

body:not(.wp-admin) .select-container:hover:after,
body:not(.wp-admin) .acf-field-select:hover:after {
    color: #040f1b;
}

#lostpasswordform fieldset,
#resetpasswordform fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none;
}

body:not(.wp-admin) .select-container select.nitro-lazy,
body:not(.wp-admin) .select-container .acf-input select.nitro-lazy,
body:not(.wp-admin) .acf-field-select select.nitro-lazy,
body:not(.wp-admin) .acf-field-select .acf-input select.nitro-lazy {
    background-image: none !important;
}

#lostpasswordform fieldset legend,
#lostpasswordform fieldset .somfrp-lost-pass-form-text,
#resetpasswordform fieldset legend,
#resetpasswordform fieldset .somfrp-lost-pass-form-text {
    display: none;
}

#lostpasswordform fieldset label,
#resetpasswordform fieldset label {
    color: #040f1b;
    text-align: center;
    margin-bottom: 1rem;
}

#lostpasswordform fieldset input[type='text'],
#lostpasswordform fieldset input[type='password'],
#resetpasswordform fieldset input[type='text'],
#resetpasswordform fieldset input[type='password'] {
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

body.page-template-template-reset-password .header-menu-container .menu-item.menu-dashboard.active a:not(:hover) {
    color: #888;
}

body.page-template-template-reset-password .header-menu-container .menu-item.menu-dashboard.active a:not(:hover):after {
    opacity: 0;
}

body.page-template-template-reset-email-success .header-menu-container .menu-item.active a:not(:hover),
body.page-template-template-reset-success .header-menu-container .menu-item.active a:not(:hover) {
    color: #888;
}

body.page-template-template-reset-email-success .header-menu-container .menu-item.active a:not(:hover):after,
body.page-template-template-reset-success .header-menu-container .menu-item.active a:not(:hover):after {
    opacity: 0;
}

.header {
    position: absolute;
    top: 0;
    z-index: 200;
    padding-bottom: 2rem;
    transition: background-color 0.15s;
}

.header > .container {
    border-bottom: 1px solid #ece9e6;
}

@media (max-width: 1194.98px) {
    .header > .container {
        width: 100%;
        max-width: 100%;
    }

    .header > .container .header-right {
        padding-right: 1.5rem;
    }
}

@media (max-width: 1194.98px) and (max-width: 767.98px) {
    .header > .container .header-right {
        padding-right: 0;
    }
}

.header .header-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.header.header-light {
    color: #fff;
}

.header.header-light .header-logo img {
    filter: none !important;
}

.header.header-light > .container {
    border-color: #fff;
}

.header.header-light .menu-icon {
    color: #fff;
}

.header.header-light .header-menu-container {
    color: #fff;
}

.header.header-light .header-menu-container > ul {
    color: inherit;
}

.header.header-light .header-menu-container > ul > li {
    color: #fff;
}

.header.header-light .header-menu-container > ul > li a {
    position: relative;
}

.header.header-light .header-menu-container > ul > li a:after {
    content: '';
    bottom: -0.35em;
    left: 0;
    width: 100%;
    background-color: currentColor;
    height: 1px;
    position: absolute;
    opacity: 0;
    transition: 0.25s opacity;
}

.header.header-light .header-menu-container > ul > li:hover a:after,
.header.header-light .header-menu-container > ul > li.active a:after {
    opacity: 1;
}

.header.header-bg {
    background-color: #fff;
}

body.popup-is-opened .header {
    z-index: 100;
}

.header-logo img {
    filter: invert(100%);
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
}

.footer-logo {
    display: inline-block;
}

.footer-logo img {
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
}

.header-menu-container {
    transition: opacity 0.25s;
}

.header-menu-container > ul > li {
    padding-left: 1rem;
    padding-right: 1rem;
    transition: color 0.25s;
}

.header-menu-container > ul > li a {
    position: relative;
}

.header-menu-container > ul > li a:after {
    content: '';
    bottom: -0.35em;
    left: 0;
    width: 100%;
    background-color: currentColor;
    height: 1px;
    position: absolute;
    opacity: 0;
    transition: 0.25s opacity;
}

.header-menu-container > ul > li:hover a:after,
.header-menu-container > ul > li.active a:after {
    opacity: 1;
}

.header-menu-container > ul > li a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 767.98px) {
    .header-menu-container > ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .header-menu-container > ul > li {
        padding-bottom: 1rem;
        text-align: center;
    }

    #sticky-sub-nav.header-menu-container > ul > li {
        padding-bottom: 0;
        line-height: 2.65;
    }
}

@media (max-width: 767.98px) {
    .header-menu-container body.page-template-template-home #sticky-sub-nav {
        display: none;
    }
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 190;
    background-color: #040f1b;
    display: none;
}

@media (pointer: coarse) {
    .menu-overlay {
        overflow-y: scroll;
        align-items: center;
        padding-top: 10rem;
        padding-bottom: 5rem;
    }
}

@media (max-width: 767.98px) {
    .menu-overlay {
        align-items: flex-start;
        padding-top: 12rem;
    }
}

.menu-overlay .background {
    opacity: 0.75;
}

@media (pointer: coarse) {
    .menu-overlay .background {
        position: fixed;
    }
}

.menu-overlay .menu-overlay-container {
    position: relative;
    text-align: center;
}

.menu-overlay .menu-overlay-container > ul.menu {
    display: flex;
}

@media (pointer: coarse) {
    .menu-overlay .menu-overlay-container > ul.menu {
        flex-direction: column;
    }
}

.menu-overlay .menu-overlay-container > ul.menu a {
    text-decoration: none;
    color: inherit;
}

.menu-overlay .menu-overlay-container > ul.menu > li {
    padding: 0 3rem;
    position: relative;
    opacity: 0.7;
    transition: opacity 0.25s;
}

@media (pointer: coarse) {
    .menu-overlay .menu-overlay-container > ul.menu > li {
        padding: 1.5rem 0;
        opacity: 1;
    }
}

.menu-overlay .menu-overlay-container > ul.menu > li > a {
    position: relative;
}

.menu-overlay .menu-overlay-container > ul.menu > li:first-child a:before {
    display: none;
}

.menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
.menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu {
    line-height: 2;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    padding-top: 1rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s;
}

@media (min-width: 1195px) {
    .menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
    .menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu {
        white-space: nowrap;
        min-width: fit-content;
    }
}

@media (min-width: 1195px) and (pointer: fine) {
    .menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
    .menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu {
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (pointer: coarse) {
    .menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu,
    .menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu {
        position: relative;
        left: unset;
        top: unset;
        display: flex;
        flex-direction: column;
        opacity: 1;
        pointer-events: all;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: initial;
    }

    .menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu > li,
    .menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu > li {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
}

.menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu > li > a,
.menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu > li > a {
    position: relative;
    box-shadow: inset 0 -1px rgba(255, 255, 255, 0);
    transition: box-shadow 0.25s;
    line-height: 1.5;
    display: inline;
}

.menu-overlay .menu-overlay-container > ul.menu > li > ul.sub-menu > li > a:hover,
.menu-overlay .menu-overlay-container > ul.menu > li > .submenu-wrap > ul.sub-menu > li > a:hover {
    box-shadow: inset 0 -1px rgba(255, 255, 255, 0.85);
}

.menu-overlay .menu-overlay-container > ul.menu > li:hover {
    opacity: 1;
}

.menu-overlay .menu-overlay-container > ul.menu > li:hover > a:before {
    opacity: 0.5;
}

.menu-overlay .menu-overlay-container > ul.menu > li:hover > a:after {
    opacity: 0.5;
}

.menu-overlay .menu-overlay-container > ul.menu > li:hover > ul.sub-menu {
    opacity: 1;
    pointer-events: all;
}

.submenu-wrap {
    display: none;
}

body.menu-overlay-active .header-menu-container {
    opacity: 0;
    pointer-events: none;
}

body.menu-overlay-active .header-logo img {
    filter: none !important;
}

body.menu-overlay-active .header.header-bg {
    background: transparent !important;
}

body.menu-overlay-active .header .header-menu-container {
    display: none;
}

body.menu-overlay-active .menu-icon {
    color: #fff;
}

html.no-overflow {
    overflow-y: hidden !important;
}

#menu-overlay-toggle {
    display: none;
}

#menu-overlay-toggle + label {
    user-select: none;
    position: relative;
    display: inline-block;
    width: 31px;
    height: 13px;
    box-sizing: content-box;
    border: 0 solid transparent;
    z-index: 1050;
    line-height: 13px;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
}

#menu-overlay-toggle + label span {
    display: inline-block;
    position: absolute;
    left: 100%;
    margin-top: 1px;
    transition: color 0.25s;
    margin-left: 0;
}

#menu-overlay-toggle + label span::before,
#menu-overlay-toggle + label span::after {
    content: '';
    display: inline-block;
    min-width: 10px;
    width: 6.2px;
}

#menu-overlay-toggle + label i,
#menu-overlay-toggle + label::before,
#menu-overlay-toggle + label::after {
    position: absolute;
    left: 0;
    display: block;
    will-change: transform, background-color;
    width: 31px;
    height: 1px;
    border-radius: 0;
    transition: transform 0.25s, background-color 0.25s;
    backface-visibility: hidden;
    outline: 1px solid transparent;
}

#menu-overlay-toggle + label i {
    top: 6px;
    font-size: 0;
    color: currentColor;
    line-height: 0;
}

#menu-overlay-toggle + label::before,
#menu-overlay-toggle + label::after {
    content: '';
}

#menu-overlay-toggle + label::before {
    top: 0;
}

#menu-overlay-toggle + label::after {
    bottom: 0;
}

#menu-overlay-toggle + label:hover {
    cursor: pointer;
}

#menu-overlay-toggle:checked + label i {
    transform: scaleX(0.001);
}

#menu-overlay-toggle:checked + label::before {
    transform: translateY(6px) rotate(45deg);
}

#menu-overlay-toggle:checked + label::after {
    transform: translateY(-6px) rotate(-45deg);
}

#menu-overlay-toggle + label i,
#menu-overlay-toggle + label::before,
#menu-overlay-toggle + label::after {
    background-color: currentColor;
}

#menu-overlay-toggle + label {
    color: currentColor !important;
}

#sticky-sub-nav {
    background-color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 201;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 15px rgba(12, 12, 12, 0.1);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, -120%, 0);
    transition: all 0.35s;
}

#sticky-sub-nav.is-visible {
    transform: none;
}

#sticky-sub-nav > ul {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

@media (max-width: 1194.98px) {
    #sticky-sub-nav > ul {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media (max-width: 1194.98px) and (orientation: portrait) {
    #sticky-sub-nav > ul {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 767.98px) {
    #sticky-sub-nav > ul {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 767.98px) {
    #sticky-sub-nav {
        padding-top: 1rem;
        height: auto;
    }
}

#sticky-sub-nav .sticky-sub-nav-trigger {
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    height: 1.85rem;
    height: 13px;
    background-color: #fff;
    color: #040f1b;
    display: block;
    width: 31px;
    text-transform: uppercase;
}

@media (max-width: 1194.98px) {
    #sticky-sub-nav .sticky-sub-nav-trigger {
        right: calc(1.5rem * 2);
    }
}

@media (max-width: 767.98px) {
    #sticky-sub-nav .sticky-sub-nav-trigger {
        display: none !important;
    }
}

#sticky-sub-nav .sticky-sub-nav-trigger > span {
    position: relative;
    display: block;
    height: 100%;
}

#sticky-sub-nav .sticky-sub-nav-trigger > span:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform-origin: top left;
    transition: transform 0.25s;
}

#sticky-sub-nav .sticky-sub-nav-trigger > span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform-origin: bottom left;
    transition: transform 0.25s;
}

#sticky-sub-nav .sticky-sub-nav-trigger > span i {
    background-color: currentColor;
    top: 6px;
    font-size: 0;
    color: currentColor;
    line-height: 0;
    position: absolute;
    left: 0;
    display: block;
    will-change: transform, background-color;
    width: 31px;
    height: 1px;
    border-radius: 0;
    transition: transform 0.25s, background-color 0.25s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
}

body.enquire-is-opened #sticky-sub-nav {
    transform: translate3d(0, -120%, 0) !important;
}

footer.footer {
    background-color: #040f1b;
    color: #fff;
    min-height: 20rem;
}

footer.footer .footer-copyright {
    text-align: center;
    flex-direction: column;
}

footer.footer .footer-copyright .copyright,
footer.footer .footer-copyright .credits {
    width: 100%;
    display: block;
    font-size: 0.9em;
}

@media (max-width: 1194.98px) {
    footer.footer .footer-right {
        padding-left: 1.5rem;
    }

    footer.footer .footer-right .newsletter-footer-container {
        padding-right: 0;
    }
}

@media (max-width: 767.98px) {
    footer.footer .footer-left,
    footer.footer .footer-center,
    footer.footer .footer-right {
        width: 100%;
    }

    footer.footer .footer-center {
        order: 0;
    }

    footer.footer .footer-left {
        margin-top: 5rem;
        order: 1;
    }

    footer.footer .footer-right {
        margin-top: 5rem;
        order: 2;
        padding-left: 0;
    }

    footer.footer .footer-bottom {
        order: 3;
    }

    footer.footer .footer-copyright {
        order: 4;
    }
}

.footer-sticky {
    height: 3.5rem;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0px 0px 15px rgba(12, 12, 12, 0.1);
}

.footer-sticky .footer-sticky-logo {
    height: 100%;
    width: auto;
    cursor: pointer;
}

.footer-sticky .footer-sticky-logo img,
.footer-sticky .footer-sticky-logo video {
    height: 100%;
    width: auto;
    position: relative;
}

.footer-sticky .footer-sticky-menu-trigger {
    display: none;
}

.footer-sticky .footer-sticky-enquire-sm {
    display: none;
}

.footer-sticky .footer-sticky-login {
    display: none;
}

@media (max-width: 1194.98px) {
    .footer-sticky {
        height: 4rem;
        flex-wrap: wrap;
        padding-top: 0.75rem;
        padding-bottom: 0.5rem;
        justify-content: center;
    }

    .footer-sticky .footer-sticky-enquire-sm {
        display: inline-block;
        z-index: 1;
        position: absolute;
        left: calc(1.5rem * 2);
        line-height: 0;
    }
}

@media (max-width: 1194.98px) and (max-width: 767.98px) {
    .footer-sticky .footer-sticky-enquire-sm {
        left: calc(1.5rem * 1);
    }
}

@media (max-width: 1194.98px) {
    .footer-sticky .col-caption {
        width: 100%;
        order: 3;
        text-align: center;
        padding: 0;
        padding-top: 0.5em;
        padding-bottom: 0;
        display: none;
    }

    .footer-sticky .col-logo {
        order: 1;
        width: auto;
        height: 30px;
    }

    .footer-sticky .col-menu {
        order: 2;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        position: absolute;
        bottom: 100%;
        height: 2rem;
        background-color: white;
        display: flex;
        align-items: center;
        box-shadow: 0px -11px 16px rgba(12, 12, 12, 0.04);
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;
    }

    .footer-sticky .col-menu ul.list-inline {
        justify-content: center;
        margin: 0 auto;
    }

    .footer-sticky .col-menu.is-active {
        opacity: 1;
        pointer-events: all;
    }

    .footer-sticky .footer-sticky-menu-trigger {
        cursor: pointer;
        position: absolute;
        right: calc(1.5rem * 2);
        height: 1.85rem;
        height: 13px;
        background-color: #fff;
        color: #040f1b;
        display: block;
        width: 31px;
        text-transform: uppercase;
        line-height: 0;
    }
}

@media (max-width: 1194.98px) and (max-width: 767.98px) {
    .footer-sticky .footer-sticky-menu-trigger {
        right: calc(1.5rem * 1);
    }
}

@media (max-width: 1194.98px) {
    .footer-sticky .footer-sticky-menu-trigger i {
        position: absolute;
        left: 0;
        display: block;
        will-change: transform, background-color;
        width: 31px;
        height: 1px;
        border-radius: 0;
        transition: transform 0.25s, background-color 0.25s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        outline: 1px solid transparent;
        top: 6px;
        top: 6px;
        color: black;
        color: currentColor;
        font-size: 0;
        line-height: 0;
        z-index: 9999;
        background: currentColor;
    }

    .footer-sticky .footer-sticky-menu-trigger > span {
        position: relative;
        display: block;
        height: 100%;
    }

    .footer-sticky .footer-sticky-menu-trigger > span:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform-origin: top left;
        transition: transform 0.25s;
    }

    .footer-sticky .footer-sticky-menu-trigger > span:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform-origin: bottom left;
        transition: transform 0.25s;
    }

    .footer-sticky .footer-sticky-menu-trigger.is-active span:before {
        transform: rotate(20deg);
    }

    .footer-sticky .footer-sticky-menu-trigger.is-active span:after {
        transform: rotate(-20deg);
    }

    .footer-sticky .footer-sticky-login {
        display: block;
    }
}

.footer-menu-container ul.menu {
    columns: 2;
    column-gap: 1rem;
}

.footer-menu-container ul.menu > li {
    color: inherit;
}

.footer-menu-container ul.menu > li a {
    line-height: 3;
    color: currentColor;
    transition: color 0.25s ease;
}

.footer-menu-container ul.menu > li a:hover {
    color: #9fb9c8;
}

.footer-menu-container .footer-social .list-social .social-item {
    padding-left: 0;
}

.footer-menu-container .footer-social .list-social .social-item > a {
    color: currentColor;
    transition: color 0.25s ease;
}

.footer-menu-container .footer-social .list-social .social-item > a:hover {
    color: #9fb9c8;
}

.hero {
    position: relative;
}

.hero .caption {
    position: relative;
}

.hero .hero-bottom {
    width: 10rem;
    position: absolute;
    bottom: calc(3.5rem + 3rem);
    left: calc(50% - 5rem);
    z-index: 10;
}

@media (max-width: 767.98px) {
    .hero .hero-bottom {
        bottom: calc(4rem + 3rem);
    }
}

.hero .hero-bottom .icon-scroll-down {
    margin-top: 1.75rem;
    margin-left: auto;
    margin-right: auto;
}

.hero .hero-scroll-down {
    cursor: pointer;
    user-select: none;
}

.hero .background {
    background-color: #040f1b;
    overflow: hidden;
}

.hero .background video,
.hero .background img {
    opacity: 0.8;
}

.icon-scroll-down {
    position: relative;
    width: 2rem;
    height: 1rem;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.865' height='12.516' viewBox='0 0 24.865 12.516'%3E%3Cg transform='translate(985.227 -28.859) rotate(90)'%3E%3Cpath d='M41.375,972.795l-.537-.592-10.656-11.84-1.323,1.184,10.12,11.248-10.12,11.248,1.323,1.184,10.656-11.84Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
    -webkit-mask-position: center top;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    background-color: currentColor;
}

.popup-container {
    position: fixed;
    background-color: #fff;
    height: 100%;
    width: 50vw;
    max-width: 60rem;
    z-index: 120;
    transition: transform 0.75s cubic-bezier(0.85, 0, 0, 1);
    transition-duration: 0;
    top: 0;
    left: 100%;
    box-shadow: 0px 0px 15px rgba(12, 12, 12, 0.1);
    transform: translate3d(5%, 0, 0);
    visibility: hidden;
}

@media (max-width: 767.98px) {
    .popup-container:not(.popup-container-small) {
        width: 100vw;
        max-width: 100vw;
    }
}

.popup-container.popup-container-small {
    width: 32rem;
    top: unset;
    left: unset;
    bottom: 5rem;
    right: 2rem;
    transform: none;
    height: auto;
}

.popup-container.popup-container-small:before {
    display: none;
}

@media (max-width: 767.98px) {
    .popup-container.popup-container-small {
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 200;
    }
}

.popup-container .popup-close {
    top: 2rem;
    left: 2rem;
    height: 42px;
    width: 42px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='42' height='42' viewBox='0 0 42 42'%3E%3Ccircle cx='21' cy='21' r='21' fill='%230c1b2a'/%3E%3Cg transform='translate(10 9)'%3E%3Cpath d='M20,2l-8.646,8.647L2.707,2,2,2.707l8.646,8.647L2,20l.707.707,8.647-8.646L20,20.707,20.707,20,12.06,11.354l8.647-8.647Z' fill='%23fff' fillRule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: absolute;
    cursor: pointer;
    transition: filter 0.25s;
}

@media (hover: hover) {
    .popup-container .popup-close:hover {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='42' height='42' viewBox='0 0 42 42'%3E%3Ccircle cx='21' cy='21' r='21' fill='%239fb9c8'/%3E%3Cg transform='translate(10 9)'%3E%3Cpath d='M20,2l-8.646,8.647L2.707,2,2,2.707l8.646,8.647L2,20l.707.707,8.647-8.646L20,20.707,20.707,20,12.06,11.354l8.647-8.647Z' fill='%23040f1b' fillRule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    }
}

@media (max-width: 1194.98px) {
    .popup-container .enquire-popup-form-container {
        padding-left: 0;
        padding-right: 0;
    }
}

.popup-container .popup-content {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.popup-container > * {
    z-index: 10;
}

.popup-container.is-open {
    transform: translate3d(-100%, 0, 0);
}

html.is-loaded .popup-container {
    transition-duration: 0.75s;
    visibility: visible;
}

html.popup-is-opened {
    overflow-y: hidden;
}

html.popup-is-opened #loader {
    background-color: rgba(4, 15, 27, 0.2);
    z-index: 120;
    pointer-events: all !important;
}

.newsletter-popup {
    display: none;
}

.newsletter-footer-container {
    display: block;
    width: 100%;
    padding-right: 2rem;
}

.newsletter-footer-container form#form-newsletter-footer {
    position: relative;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields {
    padding-bottom: 0;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field {
    padding-right: 0;
    padding-left: 0;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field.acf-field-true-false {
    padding-top: 0.5rem !important;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field.acf-field-true-false input[type='checkbox']:checked {
    color: #ece9e6;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field.acf-field-true-false input[type='checkbox']:checked + span.message {
    color: #ece9e6;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field.acf-field-true-false input[type='checkbox']:checked + span.message:before {
    content: 'ï…Š';
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='text'],
.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='email'] {
    padding-left: 0;
    color: #ece9e6;
    border-color: rgba(255, 255, 255, 0.1);
    transition: border-color 0.25s;
}

.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='text']:focus,
.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='text']:valid,
.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='email']:focus,
.newsletter-footer-container form#form-newsletter-footer .acf-fields .acf-field input[type='email']:valid {
    color: #ece9e6;
    border-color: rgba(255, 255, 255, 0.75);
}

.newsletter-footer-container form#form-newsletter-footer .acf-form-submit {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 4.75rem;
}

.newsletter-footer-container form#form-newsletter-footer .acf-form-submit .newsletter-footer-submit {
    -webkit-appearance: none;
    width: 2rem;
    height: 1.5rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.887' height='16.234' viewBox='0 0 24.887 16.234'%3E%3Cpath d='M30,6.99a.291.291,0,0,0-.136.072L22.055,14a.289.289,0,1,0,.343.466.293.293,0,0,0,.036-.032l7.33-6.517V31.575a.289.289,0,1,0,.578,0V7.921l7.33,6.517a.289.289,0,1,0,.416-.4A.3.3,0,0,0,38.053,14L30.243,7.062A.289.289,0,0,0,30,6.99Z' transform='translate(31.873 -21.937) rotate(90)' fill='%23fff' opacity='0.747'/%3E%3C/svg%3E");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 0;
    outline: none;
    background-color: transparent;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.newsletter-footer-container form#form-newsletter-footer .acf-form-submit .newsletter-footer-submit.nitro-lazy {
    background-image: none !important;
}

.block {
    position: relative;
}

body.page-template-template-blocks #main > *.block-heading.py-md:first-child,
body.page-template-template-blocks #main > *.block-heading.py-lg:first-child,
body.page-template-template-blocks #main > *.block-heading.pt-lg:first-child,
body.page-template-template-blocks #main > *.block-heading.pt-md:first-child,
body.page-template-template-gallery #main > *.block-heading.py-md:first-child,
body.page-template-template-gallery #main > *.block-heading.py-lg:first-child,
body.page-template-template-gallery #main > *.block-heading.pt-lg:first-child,
body.page-template-template-gallery #main > *.block-heading.pt-md:first-child,
body.page-template-template-catalogue #main > *.block-heading.py-md:first-child,
body.page-template-template-catalogue #main > *.block-heading.py-lg:first-child,
body.page-template-template-catalogue #main > *.block-heading.pt-lg:first-child,
body.page-template-template-catalogue #main > *.block-heading.pt-md:first-child,
body.page-template-template-instagram #main > *.block-heading.py-md:first-child,
body.page-template-template-instagram #main > *.block-heading.py-lg:first-child,
body.page-template-template-instagram #main > *.block-heading.pt-lg:first-child,
body.page-template-template-instagram #main > *.block-heading.pt-md:first-child {
    padding-top: 0;
}

.block-image-text {
    min-height: 50vh;
}

@media (max-width: 1194.98px) {
    .block-image-text {
        min-height: initial;
    }
}

@media (max-width: 767.98px) {
    .block-image-text {
        min-height: 50vh;
    }
}

.block-image-text .col-content div.text-mdlg {
    color: #888;
}

@media (max-width: 767.98px) {
    .block-image-text {
        flex-wrap: wrap;
    }

    .block-image-text .col-50 {
        width: 100%;
        min-height: 50vh;
    }

    .block-image-text .slider-image-text {
        min-height: 50vh;
    }
}

.slider-image-text {
    height: 100%;
    touch-action: none;
}

.slider-image-text .flickity-slider {
    display: flex;
}

.slider-image-text .flickity-slider .slide {
    height: 100%;
}

.slider-image-text .flickity-slider .slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-image-text .flickity-page-dots {
    bottom: 1rem;
}

.slider-image-text .flickity-page-dots .dot {
    width: 0.75rem;
    height: 0.75rem;
    margin: 0 0.5rem;
    background: #ece9e6;
    border-radius: 50%;
}

.slider-image-text .flickity-prev-next-button.next {
    right: 0.5rem;
}

.slider-image-text .flickity-prev-next-button.previous {
    left: 0.5rem;
}

.slider-image-text .flickity-prev-next-button svg path {
    fill: #ece9e6;
}

@media (max-width: 1194.98px) {
    .slider-image-text .flickity-viewport {
        min-height: 100%;
    }
}

.block-statement {
    overflow-y: hidden;
}

.block-statement .image {
    z-index: -1;
}

.block-statement .image:not(.no-parallax) {
    pointer-events: none;
}

.block-statement .image img,
.block-statement .image video {
    object-fit: cover;
    object-position: center;
}

.block-statement.has-image {
    padding-top: 0;
    padding-bottom: 0;
}

.block-statement.has-image .content {
    overflow: visible;
}

.block-statement.has-image.has-old-style .image.no-parallax {
    z-index: initial;
}

.block-statement.has-image:not(.has-old-style) .image {
    display: none;
}

@media (max-width: 767.98px) {
    .block-statement.has-image:not(.has-old-style) {
        min-height: 60vh;
    }
}

.block-statement.no-padding-top:not(.has-image) {
    padding-top: 0 !important;
    overflow-y: visible;
}

.bg-wrap {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bg-wrap .bg {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: bottom;
    object-position: bottom;
}

@media screen and (max-width: 760px) {
    .bg-wrap .bg {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 750px;
        background-size: cover;
        background-position: center center;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: bottom;
        object-position: bottom;
    }
}

.block-logo-grid .grid {
    grid-gap: 3rem;
}

.block-logo-grid .grid .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-logo-grid .grid .grid-item .image {
    width: 100%;
    height: 100%;
    max-width: 10rem;
    max-height: 6.5rem;
}

.block-logo-grid .grid .grid-item .image img,
.block-logo-grid .grid .grid-item .image video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

@media (max-width: 767.98px) {
    .block-logo-grid .grid .grid-item .image img,
    .block-logo-grid .grid .grid-item .image video {
        max-height: 4.5rem;
    }
}

.block-logo-grid .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #040f1b;
    pointer-events: none;
}

.block-logo-grid .background img,
.block-logo-grid .background video {
    opacity: 0.65;
}

.block-logo-grid.has-bg {
    overflow: hidden;
}

.block-logo-grid.has-bg .container {
    z-index: 1;
}

.block-logo-grid.has-bg .container .grid-item .image {
    filter: brightness(0) invert(1);
}

.flickity-slider,
.slide {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}

.flickity-prev-next-button {
    width: 2.5rem;
    height: 3rem;
    box-shadow: none;
    background: transparent;
}

.flickity-prev-next-button svg path {
    fill: #888;
}

.flickity-prev-next-button.previous {
    left: -2rem;
}

@media (max-width: 1194.98px) {
    .flickity-prev-next-button.previous {
        left: 0;
    }
}

.flickity-prev-next-button.next {
    right: -2rem;
}

@media (max-width: 1194.98px) {
    .flickity-prev-next-button.next {
        right: 0;
    }
}

.flickity-prev-next-button:hover {
    background: transparent;
}

.slider-testimonials .flickity-prev-next-button.next,
.slider-testimonials .flickity-prev-next-button.previous {
    top: 50%;
}

@media (max-width: 767.98px) {
    .slider-testimonials .flickity-prev-next-button.next {
        right: -1.5rem;
    }

    .slider-testimonials .flickity-prev-next-button.previous {
        left: -1.5rem;
    }
}

.slider-testimonials.has-bg svg path {
    fill: #ece9e6;
}

.flex-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-grid.flex-grid-2 .flex-grid-item {
    width: 50%;
}

@media (max-width: 767.98px) {
    .flex-grid.flex-grid-2 .flex-grid-item {
        width: 100%;
    }
}

.flex-grid.flex-grid-3 .flex-grid-item {
    width: 33.33%;
}

@media (max-width: 767.98px) {
    .flex-grid.flex-grid-3 .flex-grid-item {
        width: 100%;
    }
}

.flex-grid.flex-grid-4 .flex-grid-item {
    width: 25%;
}

@media (max-width: 1399.98px) {
    .flex-grid.flex-grid-4 .flex-grid-item {
        width: 33.33%;
    }
}

@media (max-width: 767.98px) {
    .flex-grid.flex-grid-4 .flex-grid-item {
        width: 50%;
    }
}

.flex-grid .flex-grid-item {
    text-align: center;
    padding: 3rem;
}

.flex-grid .flex-grid-item .image-round {
    width: 75%;
    max-width: 20rem;
}

.flex-grid .flex-grid-item .content {
    width: 23rem;
    max-width: 100%;
}

.image-round {
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 1194.98px) {
    .block-featured-products.is-features .features-grid-item.slide {
        padding: 0 3rem;
    }

    .block-featured-products.is-features .features-grid-item.slide .image-round {
        padding: 0 !important;
        max-width: 20rem;
    }
}

.slider-featured-destinations .slide {
    width: 860px;
}

@media (max-width: 1194.98px) {
    .slider-featured-destinations .slide {
        width: 75vw;
    }
}

.slider-featured-destinations .slide .image {
    transition: opacity 1s;
    opacity: 0.5;
}

.slider-featured-destinations .slide footer {
    transition: opacity 1s;
    opacity: 0;
}

.slider-featured-destinations .slide.is-selected .image,
.slider-featured-destinations .slide.is-selected footer {
    opacity: 1;
}

.slider-featured-nav .flickity-prev-next-button {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    background: none;
    color: inherit;
    padding: 0;
    box-shadow: none !important;
}

.slider-featured-nav .flickity-prev-next-button svg path {
    fill: currentColor;
}

ul.solutions-list {
    margin-top: 7rem !important;
    display: flex;
}

@media (max-width: 1194.98px) {
    ul.solutions-list {
        flex-direction: column;
    }
}

ul.solutions-list a {
    text-decoration: none;
    color: inherit;
}

ul.solutions-list > li {
    padding: 7rem 3rem;
    width: 100%;
    position: relative;
    opacity: 0.7;
    transition: opacity 0.25s;
}

@media (max-width: 1194.98px) {
    ul.solutions-list > li {
        padding: 3.5rem 0;
        opacity: 1;
    }
}

ul.solutions-list > li:before {
    position: absolute;
    content: '';
    height: 100%;
    left: 0;
    top: 0%;
    width: 1px;
    background-color: currentColor;
    transition: opacity 0.25s;
}

@media (max-width: 1194.98px) {
    ul.solutions-list > li:before {
        display: none;
    }
}

ul.solutions-list > li:first-child:before {
    display: none;
}

ul.solutions-list > li > a {
    position: relative;
    display: block;
}

ul.solutions-list > li > ul.sub-menu {
    font-size: 1.285rem;
    text-transform: none;
    line-height: 1;
    width: 100%;
    display: flex;
    position: relative;
    padding-top: 1rem;
    letter-spacing: 0;
    text-align: center;
    justify-content: center;
}

ul.solutions-list > li > ul.sub-menu > li {
    white-space: nowrap;
    padding: 0 1rem;
}

ul.solutions-list > li:hover {
    opacity: 1;
}

ul.solutions-list > li:hover:before {
    opacity: 0.5;
}

ul.solutions-list > li:hover > ul.sub-menu {
    opacity: 1;
    pointer-events: all;
}

.block-solutions > *,
.block-featured-projects > * {
    position: relative;
    z-index: 10;
}

.block .container-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.block .container-bg:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(4, 15, 27, 0.2);
}

.block .container-bg .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;
}

.block .container-bg .bg-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.block .container-bg .bg-item.is-active {
    opacity: 1;
}

@media (max-width: 767.98px) {
    .block-enquire,
    .block-columns {
        flex-wrap: wrap;
    }
}

.block-enquire > .content,
.block-columns > .content {
    width: 100%;
}

@media (max-width: 767.98px) {
    .block-enquire > .content,
    .block-columns > .content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.block-enquire .content:first-child,
.block-columns .content:first-child {
    border-right: 1px solid #ece9e6;
}

@media (max-width: 767.98px) {
    .block-enquire .content:first-child,
    .block-columns .content:first-child {
        border: 0;
    }
}

.block-product-options .product-section-item {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    transition: color 0.25s;
}

.block-product-options .product-section-item .image {
    width: 25%;
    transition: transform 0.25s;
}

.block-product-options .product-section-item .name {
    padding-left: 2rem;
    width: 75%;
}

.block-product-options .product-section-item:hover {
    color: #040f1b;
}

.block-product-options .product-section-item:hover .image {
    transform: scale(1.1);
}

@media (max-width: 1194.98px) {
    .block-product-options .accordion.accordion-product {
        padding-left: 0;
        padding-right: 0;
    }

    .block-product-options .accordion-content .flex-cols {
        flex-wrap: wrap;
    }

    .block-product-options .accordion-content .flex-cols .col-50.product-section {
        width: 100%;
    }

    .block-product-options .accordion-content .flex-cols .col-50.product-section + .product-section {
        margin-top: 2rem;
    }
}

.product-options-popup {
    width: 50rem;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(12, 12, 12, 0.1);
    min-height: 20rem;
    max-width: 70vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3.5rem 2.5rem;
    display: flex;
    display: none;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1194.98px) {
    .product-options-popup {
        max-width: 100%;
    }
}

@media (max-width: 767.98px) {
    .product-options-popup {
        width: 95vw;
        max-width: 95vw;
        padding: 2rem 1.5rem;
    }
}

.product-options-popup .content {
    width: 100%;
    position: relative;
}

@media (max-width: 767.98px) {
    .product-options-popup .content {
        align-items: flex-start;
    }
}

.product-options-popup .content .col-image {
    padding: 1rem;
}

.product-options-popup .content .col-image img {
    max-width: 20rem;
}

@media (max-width: 1194.98px) {
    .product-options-popup .content .col-image {
        min-width: 22rem;
    }
}

@media (max-width: 767.98px) {
    .product-options-popup .content .col-image {
        min-width: 25%;
        width: 25%;
        padding: 0 !important;
    }
}

.product-options-popup .content .col-info {
    padding-left: 3rem;
}

@media (max-width: 767.98px) {
    .product-options-popup .content .col-info {
        width: 75%;
        padding-left: 2rem;
    }
}

@media (max-width: 1194.98px) {
    .block-featured-products {
        padding-left: 0;
        padding-right: 0;
    }

    .block-featured-products h2,
    .block-featured-products h2 + div.text-body,
    .block-featured-products h2 + div#swipebox-title {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.block-featured-products .slider-featured-products:before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #ece9e6;
    height: 100%;
    left: 50%;
    top: 0;
    opacity: 1;
    transition: opacity 0.15s;
    z-index: 10;
}

@media (max-width: 767.98px) {
    .block-featured-products .slider-featured-products:before {
        content: unset;
    }
}

.block-featured-products .slider-featured-products.is-changing:before {
    opacity: 0;
}

.block-featured-products .slider-featured-products .slide.slide-featured-products {
    width: 50%;
}

@media (max-width: 767.98px) {
    .block-featured-products .slider-featured-products .slide.slide-featured-products {
        width: 100%;
    }
}

.block-featured-products .slider-featured-products .slide.slide-featured-products .image {
    padding: 0 2.5rem;
}

.block-featured-products .slider-featured-products .slide.slide-featured-products .content p.text-body-sm {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-height: calc(1.35em * 2.9);
    text-overflow: -o-ellipsis-lastline;
    -webkit-line-clamp: 3;
    height: calc(1.35em * 2.9);
}

.block-featured-products .flickity-prev-next-button svg path {
    fill: #9fb9c8;
}

@media (max-width: 1194.98px) {
    .block-featured-products .flickity-prev-next-button {
        top: 10rem;
    }
}

.block-featured-designs .featured-designs-images {
    position: relative;
    height: 25rem;
}

@media (max-width: 767.98px) {
    .block-featured-designs .featured-designs-images {
        height: 20rem;
    }
}

.block-featured-designs .featured-designs-images img {
    width: 100%;
    width: 100%;
    height: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    transition: opacity 0.5s;
    opacity: 0;
    position: absolute;
}

.block-featured-designs .featured-designs-images img.is-active {
    opacity: 1;
}

.block-featured-designs .slider-featured-designs.less-or-2 .slide {
    width: 100%;
}

@media (max-width: 767.98px) {
    .block-featured-designs .slider-featured-designs.less-or-2 .slide {
        width: 100%;
    }
}

.block-featured-designs .slider-featured-designs .slide {
    width: 33.33%;
    text-align: center;
    color: #040f1b;
    transition: color 0.5s;
}

@media (max-width: 1194.98px) {
    .block-featured-designs .slider-featured-designs .slide {
        padding-top: 5px;
    }
}

@media (max-width: 767.98px) {
    .block-featured-designs .slider-featured-designs .slide {
        padding-top: 5px;
        width: 100%;
    }
}

.block-featured-designs .slider-featured-designs .slide.is-selected {
    color: #9fb9c8;
}

.block-featured-designs .slider-featured-designs .slide.is-selected > a {
    opacity: 1;
    pointer-events: initial;
}

.block-featured-designs .slider-featured-designs .slide > * {
    color: currentColor;
}

.block-featured-designs .slider-featured-designs .slide > a {
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
}

.block-featured-designs .slider-featured-designs .flickity-prev-next-button {
    top: 0.75rem;
}

@media (max-width: 1194.98px) {
    .block-featured-designs .slider-featured-designs .flickity-prev-next-button {
        top: calc(0.75rem + 3px);
    }
}

@media (hover: none) {
    .block-features {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .block-features > .content {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .block-features .flex-grid {
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: auto;
    }

    .block-features .flex-grid .scrollbar-track-y {
        display: none !important;
    }

    .block-features .flex-grid .scrollbar-track-x {
        bottom: 0;
        left: calc(1.5rem * 2);
        width: calc(100vw - (1.5rem * 4));
        max-width: calc(100vw - (1.5rem * 4));
        height: 2px;
        background: #ece9e6;
    }

    .block-features .flex-grid .scrollbar-track-x .scrollbar-thumb {
        background: #888;
        height: 2px;
    }

    .block-features .flex-grid .scroll-content {
        display: flex;
        padding-bottom: 2rem;
    }

    .block-features .flex-grid .flex-grid-item {
        width: 70vw;
        margin-left: 1.5rem;
        padding: 2rem;
    }
}

.block-features + .block.no-padding-top.block-statement {
    padding-top: 4.65rem !important;
}

@media (max-width: 767.98px) {
    .block-features + .block.no-padding-top.block-statement {
        padding-top: 3.5rem !important;
    }
}

ul.featured-projects-list {
    margin-top: 7rem !important;
    display: flex;
    flex-direction: column;
}

ul.featured-projects-list a {
    text-decoration: none;
    color: inherit;
}

ul.featured-projects-list > li {
    width: 100%;
    position: relative;
    opacity: 0.7;
    transition: opacity 0.25s;
}

@media (max-width: 767.98px) {
    ul.featured-projects-list > li {
        opacity: 1;
    }
}

ul.featured-projects-list > li > a {
    position: relative;
    display: block;
    line-height: 1.5;
}

ul.featured-projects-list > li:hover {
    opacity: 1;
}

ul.featured-projects-list > li + li {
    margin-top: 1.75rem;
}

.block-featured-projects > .content {
    min-height: 45vh;
    display: flex;
    flex-direction: column;
}

.block-featured-projects .container-bg {
    opacity: 0.75;
}

.block-featured-projects .featured-projects-list {
    flex-grow: 1;
    justify-content: center;
}

ul.list-columns {
    column-count: 2;
    column-gap: 3rem;
}

.block-clients-columns ul.list-columns {
    margin-left: auto;
    margin-right: auto;
}

.block-film {
    border-bottom: 1px solid #ece9e6;
}

.block-film video.has-controls {
    cursor: pointer;
}

.sheet-table {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #040f1b;
}

@media (max-width: 767.98px) {
    .sheet-table {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.sheet-table .sheet-table-header,
.sheet-table .sheet-table-row {
    display: flex;
    border-bottom: 1px solid #ece9e6;
}

.sheet-table .sheet-table-header > *,
.sheet-table .sheet-table-row > * {
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.5;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
}

.sheet-table .sheet-table-header > *:last-child,
.sheet-table .sheet-table-row > *:last-child {
    padding-right: 0;
}

.sheet-table .sheet-table-header > *:first-child,
.sheet-table .sheet-table-row > *:first-child {
    padding-left: 0;
}

@media (max-width: 767.98px) {
    .sheet-table .sheet-table-header,
    .sheet-table .sheet-table-row {
        flex-direction: column;
        width: 100%;
        border-bottom: 0;
        font-size: 0.8rem !important;
    }

    .sheet-table .sheet-table-header > *,
    .sheet-table .sheet-table-row > * {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        line-height: 1.35;
    }

    .sheet-table .sheet-table-header.sheet-table-header,
    .sheet-table .sheet-table-row.sheet-table-header {
        width: 33.33%;
    }

    .sheet-table .sheet-table-header.sheet-table-row,
    .sheet-table .sheet-table-row.sheet-table-row {
        width: 66.66%;
        padding-left: 2rem;
    }
}

body.post-type-archive-products .products-archive-container + .products-archive-container {
    border-top: 1px solid #ece9e6;
}

.block-product-slider .flickity-prev-next-button svg path {
    fill: #040f1b;
}

.block-product-slider > .content .slider-product {
    height: 75rem;
    max-height: 70vh;
}

@media (max-width: 767.98px) {
    .block-product-slider > .content .slider-product {
        width: 100vw;
        margin-left: -1.5rem;
        height: calc(var(--vh, 1vh) * 50);
    }
}

.block-product-slider > .content .slider-product .slide {
    height: 100%;
}

.block-product-slider > .content .slider-product .slide .image {
    height: 100%;
}

.block-product-slider > .content .slider-product .slide .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.block-wide-image {
    overflow: hidden;
}

.h-opacity {
    transition: opacity 0.25s;
}

.h-opacity:hover {
    opacity: 0.65;
}

.share-container {
    display: flex;
}

.share-container .share-trigger {
    cursor: pointer;
    border-bottom: 1px solid;
    border-color: rgba(136, 136, 136, 0);
    transition: color 0.25s, border-color 0.25s;
    position: relative;
    display: inline-block;
}

.share-container .share-trigger:after {
    position: relative;
    content: '+';
    display: inline-block;
    padding-left: 0.25em;
}

.share-container .share-links {
    position: absolute;
    text-align: right;
    right: 0;
    padding-top: 0.75rem;
    top: 100%;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;
}

.share-container .share-links .share-item a {
    font-size: 1.25rem;
}

.share-container .share-links .share-item a:hover {
    color: #888;
}

.share-container.is-active .share-links {
    max-height: 3rem;
}

.share-container.is-active .share-trigger {
    color: #888;
    border-color: currentColor;
}

.share-container.is-active .share-trigger:after {
    content: 'â€“';
}

.product-bottom {
    position: relative;
}

.product-bottom .product-share {
    position: absolute;
    right: 0;
    bottom: 7.5rem;
    z-index: 1;
}

.product-bottom .product-share .share-trigger:hover {
    color: #888;
    border-color: currentColor;
}

@media (max-width: 1194.98px) {
    .product-bottom .product-share {
        right: 2rem;
    }
}

@media (max-width: 767.98px) {
    .product-bottom .product-share {
        right: 50%;
        bottom: 4rem;
        z-index: 1;
        transform: translateX(50%);
        display: block;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 767.98px) {
    .product-bottom {
        padding-bottom: 8rem;
    }

    .product-bottom .share-trigger {
        display: inline-block;
        margin: 0 auto;
        border-color: currentColor !important;
    }

    .product-bottom .share-links {
        text-align: center;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

ul#menu-products li.menu-item.menu-all.active {
    color: #888;
}

ul#menu-products li a {
    position: relative;
}

ul#menu-products li a:after {
    content: '';
    bottom: -0.35em;
    left: 0;
    width: 100%;
    background-color: currentColor;
    height: 1px;
    position: absolute;
    opacity: 0;
    transition: 0.25s opacity;
}

ul#menu-products li:hover a:after,
ul#menu-products li.active a:after {
    opacity: 1;
}

body.archive.post-type-archive-products ul#menu-products li a {
    position: relative;
}

body.archive.post-type-archive-products ul#menu-products li a:after {
    content: '';
    bottom: -0.35em;
    left: 0;
    width: 100%;
    background-color: currentColor;
    height: 1px;
    position: absolute;
    opacity: 0;
    transition: 0.25s opacity;
}

body.archive.post-type-archive-products ul#menu-products li:hover a:after,
body.archive.post-type-archive-products ul#menu-products li.active a:after {
    opacity: 1;
}

body.archive.tax-product_type ul#menu-products li.menu-item.menu-all.active a:after {
    opacity: 0;
}

body.archive.tax-product_type ul#menu-products li.menu-item.menu-all.active:hover a:after {
    opacity: 1;
}

.flex-grid.flex-grid-3:not(.no-border) {
    margin-right: -1px;
    margin-bottom: -1px;
}

.flex-grid.flex-grid-3:not(.no-border) .flex-grid-item {
    background-color: #fff;
    margin-bottom: 0;
    margin-bottom: -1px;
    border-top: 1px solid #ece9e6;
    border-right: 1px solid #ece9e6;
    border-left: 1px solid #ece9e6;
    margin-left: -1px;
}

.flex-grid.flex-grid-3:not(.no-border) .flex-grid-item:nth-child(-n + 3) {
    border-top: 0;
    border-bottom: 1px solid #ece9e6;
}

.flex-grid.flex-grid-3:not(.no-border) .flex-grid-item:nth-child(3n + 1) {
    border-left: 0 !important;
}

.flex-grid.flex-grid-3:not(.no-border) .flex-grid-item:nth-child(3n + 3) {
    border-right: 0 !important;
}

.flex-grid.flex-grid-3.grid-one-row:not(.no-border) {
    justify-content: center;
}

.flex-grid.flex-grid-3.grid-one-row:not(.no-border) .flex-grid-item {
    border-bottom: 0 !important;
}

.flex-grid.flex-grid-3.grid-one-row:not(.no-border) .flex-grid-item:last-child {
    border-right: 0 !important;
}

@media (max-width: 767.98px) {
    .flex-grid:not(.no-border) .flex-grid-item {
        border: 0 !important;
    }
}

.single-products .wide-image + .block-heading {
    margin-top: 3rem;
}

@media (max-width: 1194.98px) {
    .archive-products {
        padding-left: 0;
        padding-right: 0;
    }

    .archive-products .flex-grid-item.item-products {
        width: 50%;
    }
}

@media (max-width: 767.98px) {
    .archive-products .flex-grid-item.item-products {
        width: 100%;
        padding-bottom: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.contact-image {
    height: 100%;
    position: sticky !important;
    display: block;
}

.contact-image .image {
    height: 100%;
    width: 100%;
    display: block;
}

.contact-image .image img,
.contact-image .image video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-split {
    height: 100%;
    position: sticky !important;
    display: flex;
}

.slider-split .flickity-viewport {
    width: 100%;
}

.slider-split .slide.split-slide {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
}

.slider-split .slide.split-slide img,
.slider-split .slide.split-slide video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.slider-split .slide.split-slide .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 1s;
    transform: translateZ(0) scale(1.3);
}

.slider-split .slide.split-slide.is-selected .background {
    transform: translateZ(0) scale(1);
}

@media (max-width: 1194.98px) {
    .contact-image.sticky-top,
    .mh-fill.sticky-top,
    .contact-image .image img.sticky-top,
    .contact-image .image video.sticky-top,
    .slider-split .sticky-top.flickity-viewport,
    .slider-split .sticky-top.slide.split-slide,
    .slider-split .slide.split-slide img.sticky-top,
    .slider-split .slide.split-slide video.sticky-top {
        position: fixed !important;
        top: 0;
        right: 0;
    }
}

address {
    font-style: normal;
}

.pt-header,
body.page-template-template-blocks #main,
body.page-template-template-gallery #main,
body.page-template-template-catalogue #main,
body.page-template-template-instagram #main {
    padding-top: calc(14.75rem + 4.65rem);
    padding-top: calc(var(--full-header) + 4.65rem);
}

@media (max-width: 767.98px) {
    .pt-header,
    body.page-template-template-blocks #main,
    body.page-template-template-gallery #main,
    body.page-template-template-catalogue #main,
    body.page-template-template-instagram #main {
        padding-top: calc(var(--full-header) + 2.5rem);
    }
}

.mh-fill,
.contact-image .image img,
.contact-image .image video,
.slider-split .flickity-viewport,
.slider-split .slide.split-slide,
.slider-split .slide.split-slide img,
.slider-split .slide.split-slide video {
    min-height: calc(100vh - 3.5rem);
    min-height: calc((var(--vh, 1vh) * 100) - 3.5rem);
}

@media (max-width: 1194.98px) {
    .mh-fill,
    .contact-image .image img,
    .contact-image .image video,
    .slider-split .flickity-viewport,
    .slider-split .slide.split-slide,
    .slider-split .slide.split-slide img,
    .slider-split .slide.split-slide video {
        min-height: calc(100vh + 1.5rem);
        min-height: calc((var(--vh, 1vh) * 100) + 1.5rem);
    }
}

@media (max-width: 767.98px) {
    .mh-fill,
    .contact-image .image img,
    .contact-image .image video,
    .slider-split .flickity-viewport,
    .slider-split .slide.split-slide,
    .slider-split .slide.split-slide img,
    .slider-split .slide.split-slide video {
        min-height: calc(100vh - 4rem);
        min-height: calc((var(--vh, 1vh) * 100) - 4rem);
    }
}

.contact-image.sticky-top:after,
.slider-split.sticky-top:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    height: var(--full-header, 10rem);
    z-index: 1;
    display: none;
}

html.scroll-is-top .contact-image.sticky-top:after,
html.scroll-is-top .slider-split.sticky-top:after {
    display: block;
}

.distributors-list {
    width: calc(100% - 4rem);
}

.distributor-item + .distributor-item {
    border-top: 1px solid #ece9e6;
}

.distributors-select {
    width: 100%;
    max-width: 30em;
}

div.text-body p a,
div.text-body a,
div#swipebox-title a {
    color: #9fb9c8;
    text-decoration: none;
}

div.text-body p a:hover,
div.text-body a:hover,
div#swipebox-title a:hover {
    color: #040f1b;
}

@media (max-width: 767.98px) {
    .container-fluid.mh-fill .col-50,
    .contact-image .image img.container-fluid .col-50,
    .contact-image .image video.container-fluid .col-50,
    .slider-split .container-fluid.flickity-viewport .col-50,
    .slider-split .container-fluid.slide.split-slide .col-50,
    .slider-split .slide.split-slide img.container-fluid .col-50,
    .slider-split .slide.split-slide video.container-fluid .col-50 {
        width: 100%;
    }

    .container-fluid.mh-fill .col-50.sticky-top,
    .contact-image .image img.container-fluid .col-50.sticky-top,
    .contact-image .image video.container-fluid .col-50.sticky-top,
    .slider-split .container-fluid.flickity-viewport .col-50.sticky-top,
    .slider-split .container-fluid.slide.split-slide .col-50.sticky-top,
    .slider-split .slide.split-slide img.container-fluid .col-50.sticky-top,
    .slider-split .slide.split-slide video.container-fluid .col-50.sticky-top {
        display: none;
    }
}

.container-post {
    border-right: 1px solid #ece9e6;
}

.container-related {
    position: sticky !important;
    top: 0;
    padding-top: 7rem;
}

.container-related .flex-grid-item {
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3rem;
}

.flex-grid.blog-grid .flex-grid-item.item-post {
    padding-bottom: 0;
}

.flex-grid-item.item-post .image img,
.flex-grid-item.item-post .image video {
    transition: all 2s;
}

.flex-grid-item.item-post .post-title {
    transition: color 0.25s ease;
}

.flex-grid-item.item-post:hover .image img,
.flex-grid-item.item-post:hover .image video {
    filter: brightness(0.9);
    transform: scale(1.025);
}

.flex-grid-item.item-post:hover .post-title {
    color: #9fb9c8;
}

.blog-grid .blog-content {
    margin-top: -3rem;
}

.blog-grid .blog-header {
    position: sticky;
    top: 0;
}

.blog-grid .blog-header .color-white {
    z-index: 1;
}

@media (max-width: 767.98px) {
    .blog-grid .flex-grid-item {
        width: 100% !important;
    }

    .blog-grid .flex-grid-item .image {
        --aspect-ratio: 3.5/3 !important;
    }
}

.pagination {
    width: 100;
    border-top: 1px solid #ece9e6;
}

.pagination > ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.pagination > ul li {
    display: inline-block;
    list-style: none;
    margin: 0 0.5rem;
}

.pagination > ul li > a.next {
    position: absolute;
    right: 0;
    top: 0;
}

.pagination > ul li > a.prev {
    position: absolute;
    left: 0;
    top: 0;
}

.pagination > ul li > a.current {
    color: #9fb9c8;
}

.gallery-container {
    display: flex;
    flex-wrap: wrap;
}

.gallery-container > * {
    width: 14.285%;
    height: 15rem;
    padding: 2px;
}

.gallery-container > * img,
.gallery-container > * video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 767.98px) {
    .gallery-container > * {
        width: 25%;
        height: 25vw;
    }
}

.page-template-template-gallery .block.block-heading:first-of-type .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media (max-width: 767.98px) {
    .page-template-template-gallery .block.block-heading:first-of-type .content {
        flex-direction: column;
        align-items: center;
    }
}

.page-template-template-gallery .block.block-heading:first-of-type .gallery-select-container,
.page-template-template-gallery .block.block-heading:first-of-type h2 {
    position: relative;
    width: 33.33%;
    padding-right: 2rem;
    padding-left: 2rem;
}

@media (max-width: 767.98px) {
    .page-template-template-gallery .block.block-heading:first-of-type .gallery-select-container,
    .page-template-template-gallery .block.block-heading:first-of-type h2 {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.page-template-template-gallery .block.block-heading:first-of-type h2 {
    padding-left: 0;
    text-align: center;
    line-height: 1.65;
}

@media (max-width: 767.98px) {
    .page-template-template-gallery .block.block-heading:first-of-type h2 {
        padding-right: 0;
    }
}

.page-template-template-gallery .block.block-heading:first-of-type .select-container:after {
    top: 0.5em;
}

.page-template-template-gallery .block.block-heading:first-of-type select {
    width: 100%;
    color: #9fb9c8;
}

.page-template-template-gallery .block.block-heading:first-of-type select option:checked {
    text-transform: uppercase !important;
}

#swipebox-slider .slide img {
    max-width: 80rem;
    max-height: 70vh;
    object-fit: contain;
    opacity: 0;
    transition: opacity 1s;
}

@media (max-width: 767.98px) {
    #swipebox-slider .slide img {
        width: 100%;
        height: 100%;
        max-height: 80vh;
        object-fit: contain;
    }
}

#swipebox-slider .slide.current img {
    opacity: 1;
}

#swipebox-top-bar,
#swipebox-bottom-bar {
    height: 9rem;
    display: flex;
    align-items: center;
}

#swipebox-top-bar {
    top: unset;
    bottom: 0;
}

#swipebox-bottom-bar {
    bottom: 50%;
    background: transparent;
    transform: translate3d(0, 50%, 0);
    pointer-events: none;
}

#swipebox-bottom-bar #swipebox-arrows {
    padding: 0 2rem;
}

@media (max-width: 767.98px) {
    #swipebox-bottom-bar #swipebox-arrows {
        padding: 0 0.5rem;
    }
}

#swipebox-bottom-bar #swipebox-prev {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.516' height='24.865' viewBox='0 0 12.516 24.865'%3E%3Cpath d='M28.859,972.795l.537-.592,10.656-11.84,1.323,1.184-10.12,11.248,10.12,11.248-1.323,1.184L29.4,973.387Z' transform='translate(-28.859 -960.362)' fill='%230c1b2a'/%3E%3C/svg%3E") !important;
}

#swipebox-bottom-bar #swipebox-next {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.516' height='24.865' viewBox='0 0 12.516 24.865'%3E%3Cg transform='translate(-28.859 -960.362)'%3E%3Cpath d='M41.375,972.795l-.537-.592-10.656-11.84-1.323,1.184,10.12,11.248-10.12,11.248,1.323,1.184,10.656-11.84Z' fill='%230c1b2a'/%3E%3C/g%3E%3C/svg%3E") !important;
}

#swipebox-bottom-bar #swipebox-next,
#swipebox-bottom-bar #swipebox-prev {
    pointer-events: all !important;
    width: 1.5rem;
    height: 2rem;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

#swipebox-close {
    top: 2rem;
    right: 2rem;
    height: 3rem;
    width: 3rem;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='42' height='42' viewBox='0 0 42 42'%3E%3Ccircle cx='21' cy='21' r='21' fill='%230c1b2a'/%3E%3Cg transform='translate(10 9)'%3E%3Cpath d='M20,2l-8.646,8.647L2.707,2,2,2.707l8.646,8.647L2,20l.707.707,8.647-8.646L20,20.707,20.707,20,12.06,11.354l8.647-8.647Z' fill='%23fff' fillRule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

@media (max-width: 1194.98px) {
    #swipebox-close {
        width: 42px;
        height: 42px;
    }
}

@media (hover: hover) {
    #swipebox-close:hover {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='42' height='42' viewBox='0 0 42 42'%3E%3Ccircle cx='21' cy='21' r='21' fill='%239fb9c8'/%3E%3Cg transform='translate(10 9)'%3E%3Cpath d='M20,2l-8.646,8.647L2.707,2,2,2.707l8.646,8.647L2,20l.707.707,8.647-8.646L20,20.707,20.707,20,12.06,11.354l8.647-8.647Z' fill='%23040f1b' fillRule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
    }
}

#swipebox-title {
    color: #040f1b;
}

.instagram-container {
    display: flex;
    flex-direction: column;
}

#instafeed {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

#instafeed .instafeed-item.flex-grid-item {
    width: 33.33%;
    padding: 1rem;
}

.instagram-heading a {
    color: #9fb9c8;
    text-decoration: none;
}

.instagram-heading a:hover {
    color: #040f1b;
}

@media (max-width: 767.98px) {
    body.single-post section.container:first-child {
        flex-wrap: wrap;
    }

    body.single-post section.container:first-child .col-75 {
        width: 100%;
        border-right: 0;
        padding-right: 0;
    }

    body.single-post section.container:first-child .col-25.container-related {
        width: 100vw;
        padding-left: 0;
        min-width: 100vw;
        margin-left: -1.5rem;
    }

    body.single-post section.container:first-child .col-25.container-related .related-posts-container {
        display: flex;
        width: 100%;
        overflow-x: auto;
    }

    body.single-post section.container:first-child .col-25.container-related .related-posts-container > * {
        min-width: 60vw;
        width: 60vw;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    body.single-post section.container:first-child .post-featured-image {
        width: 100vw;
        margin-left: -1.5rem;
    }
}

@media (max-width: 1194.98px) {
    .container-downloads {
        flex-wrap: wrap;
        margin-bottom: 5rem;
    }

    .container-downloads .downloads-archive-taxonomies {
        width: 100%;
        border: 0;
    }

    .container-downloads .downloads-archive-taxonomies + .downloads-archive-taxonomies {
        margin-top: 3.5rem;
        padding-top: 3.5rem;
        border: 0;
        border-top: 1px solid #ece9e6;
    }
}

@media (max-width: 767.98px) {
    body.blog .archive-category .blog-header {
        display: none;
    }

    body.blog .archive-category .blog-content {
        width: 100%;
        padding-left: 0;
    }
}

#form-request .acf-field.acf-field-true-false {
    text-align: left;
}

#loginform label {
    display: none;
}

#loginform input:not([type='submit']) {
    width: 20rem;
}

#loginform .login-submit {
    padding-top: 15px;
    margin-top: 2.5rem;
}

@media (max-width: 767.98px) {
    #loginform .login-submit {
        padding-top: 15px;
        margin-top: 2.5rem;
    }
}

body.post-type-archive-downloads .container-downloads {
    margin-bottom: 8rem;
}

.downloads-archive-taxonomies + .downloads-archive-taxonomies {
    border-left: 1px solid #ece9e6;
}

ul.downloads-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 20px;
}

ul.downloads-list li {
    text-align: left;
}

ul.downloads-list li a {
    text-decoration: none;
}

ul.downloads-list li + li {
    margin-top: 0.5rem;
}

ul.downloads-list li:first-child {
    margin-top: 2.5rem;
}

@media (max-width: 767.98px) {
    ul.downloads-list li:first-child {
        margin-top: 2.5rem;
    }
}

@media (max-width: 767.98px) {
    .container-downloads {
        flex-wrap: wrap;
    }

    .container-downloads > .col-50 {
        width: 100%;
    }
}

.container-buttons > a + a {
    margin-left: 2rem;
}

@media (max-width: 767.98px) {
    .container-buttons > a + a {
        margin-left: 0;
        margin-top: 1.5rem;
    }
}

#cookie-law-info-bar {
    bottom: calc(3.5rem + 2rem) !important;
    font-size: 13px;
    width: 32rem;
    max-width: 100%;
    left: 2rem;
    box-shadow: 0px 0px 15px rgba(12, 12, 12, 0.1);
    background-color: #fff;
    color: #888 !important;
    padding: 1.5rem;
    text-align: left;
    z-index: 120 !important;
}

@media (max-width: 767.98px) {
    #cookie-law-info-bar {
        bottom: 4rem !important;
        width: 100%;
        left: 0;
    }
}

#cookie-law-info-bar > span {
    display: block;
    width: 100%;
}

#cookie-law-info-bar > span #cookie_action_close_header {
    display: block !important;
    text-align: center;
    margin-top: 1.5rem;
    color: #040f1b !important;
    background-color: unset !important;
    font-weight: bold;
    padding: 0;
    transition: color 0.25s ease;
}

#cookie-law-info-bar > span #cookie_action_close_header:hover {
    color: #9fb9c8 !important;
}

#cookie-law-info-bar > span #CONSTANT_OPEN_URL {
    display: inline;
    font: inherit !important;
    color: #040f1b;
    transition: color 0.25s ease;
    text-decoration: none !important;
}

#cookie-law-info-bar > span #CONSTANT_OPEN_URL:hover {
    color: #9fb9c8;
}

.project-gallery {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.project-gallery .image {
    max-height: calc(100vh - 3.5rem);
    max-height: calc((var(--vh, 1vh) * 100) - 3.5rem);
    height: calc((var(--vh, 1vh) * 100) - 3.5rem);
}

@media (max-width: 767.98px) {
    .project-gallery .image {
        max-height: calc(100vh - 4rem);
        max-height: calc((var(--vh, 1vh) * 100) - 4rem);
        height: calc((var(--vh, 1vh) * 100) - 4rem);
    }
}

.project-gallery .image.horizontal {
    width: 100%;
}

.project-gallery .image.vertical {
    width: 50%;
}

@media (max-width: 767.98px) {
    .project-gallery .image.vertical {
        width: 100%;
    }
}

.project-gallery .image.vertical:nth-child(odd) .bg-wrap .bg {
    width: 50%;
    left: unset;
}

@media (max-width: 767.98px) {
    .project-gallery .image.vertical:nth-child(odd) .bg-wrap .bg {
        width: 100% !important;
    }
}

.project-gallery .image.vertical:nth-child(even) .bg-wrap .bg {
    width: 50%;
    right: 0;
    left: initial;
}

@media (max-width: 767.98px) {
    .project-gallery .image.vertical:nth-child(even) .bg-wrap .bg {
        width: 100% !important;
    }
}

@media (max-width: 767.98px) {
    body.home #sticky-sub-nav {
        display: none !important;
    }
}

@media (max-width: 1194.98px) {
    body.home #sticky-sub-nav {
        display: none !important;
    }

    #sticky-sub-nav .sticky-sub-nav-trigger {
        display: none !important;
    }
}

@media (max-width: 1194.98px) {
    .m-col-reverse {
        flex-direction: column-reverse !important;
    }
}

ul.solutions-list > li > ul.sub-menu > li {
    white-space: nowrap !important;
    padding: 0px !important;
}

ul.solutions-list ul.sub-menu li:after {
    content: 'â€¢' !important;
    font-size: 12px !important;
    padding: 0 1rem !important;
}

ul.solutions-list ul.sub-menu li:last-child:after {
    display: none !important;
}

.mh-fill,
.contact-image .image img,
.contact-image .image video,
.slider-split .flickity-viewport,
.slider-split .slide.split-slide,
.slider-split .slide.split-slide img,
.slider-split .slide.split-slide video {
    min-height: 100vh !important;
}

@media (max-width: 1194.98px) {
    .mh-fill,
    .contact-image .image img,
    .contact-image .image video,
    .slider-split .flickity-viewport,
    .slider-split .slide.split-slide,
    .slider-split .slide.split-slide img,
    .slider-split .slide.split-slide video {
        min-height: calc(100vh + 1.5rem);
        min-height: calc((var(--vh, 1vh) * 100) + 1.5rem);
    }
}

@media (max-width: 767.98px) {
    .mh-fill,
    .contact-image .image img,
    .contact-image .image video,
    .slider-split .flickity-viewport,
    .slider-split .slide.split-slide,
    .slider-split .slide.split-slide img,
    .slider-split .slide.split-slide video {
        min-height: calc(100vh - 4rem);
        min-height: calc((var(--vh, 1vh) * 100) - 4rem);
    }
}

@media (max-width: 767.98px) {
    body.page-template-template-home .block.block-image-text.f-cols.reverse,
    body.page-template-template-home .block.block-image-text.flex-cols.reverse,
    body.page-template-template-home .block.block-image-text.flex-cols,
    body.products-template-default .block.block-image-text.f-cols.reverse,
    body.products-template-default .block.block-image-text.flex-cols.reverse,
    body.products-template-default .block.block-image-text.flex-cols,
    body.post-type-archive-projects .block.block-image-text.f-cols.reverse,
    body.post-type-archive-projects .block.block-image-text.flex-cols.reverse,
    body.post-type-archive-projects .block.block-image-text.flex-cols {
        flex-direction: row !important;
    }

    body.page-template-template-home .block.block-image-text.f-cols.reverse .col-img,
    body.page-template-template-home .block.block-image-text.flex-cols.reverse .col-img,
    body.page-template-template-home .block.block-image-text.flex-cols .col-img,
    body.products-template-default .block.block-image-text.f-cols.reverse .col-img,
    body.products-template-default .block.block-image-text.flex-cols.reverse .col-img,
    body.products-template-default .block.block-image-text.flex-cols .col-img,
    body.post-type-archive-projects .block.block-image-text.f-cols.reverse .col-img,
    body.post-type-archive-projects .block.block-image-text.flex-cols.reverse .col-img,
    body.post-type-archive-projects .block.block-image-text.flex-cols .col-img {
        order: 1 !important;
    }

    body.page-template-template-home .block.block-image-text.f-cols.reverse .col-content,
    body.page-template-template-home .block.block-image-text.flex-cols.reverse .col-content,
    body.page-template-template-home .block.block-image-text.flex-cols .col-content,
    body.products-template-default .block.block-image-text.f-cols.reverse .col-content,
    body.products-template-default .block.block-image-text.flex-cols.reverse .col-content,
    body.products-template-default .block.block-image-text.flex-cols .col-content,
    body.post-type-archive-projects .block.block-image-text.f-cols.reverse .col-content,
    body.post-type-archive-projects .block.block-image-text.flex-cols.reverse .col-content,
    body.post-type-archive-projects .block.block-image-text.flex-cols .col-content {
        order: 0 !important;
    }
}
