.preloader-container {
    position: fixed;
    z-index: 10000;
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: containerup 0.6s forwards;
    overflow: hidden;
    animation-delay: 3.5s;
    svg{
      height: 55px;
      width: 130px;
    }
}

@keyframes containerup {
    from {
        top: 0px;
    }
    to {
        top: -100vh;
    }
}

.progress-bar {
    position: absolute;
    z-index: 1001;
    top: 0px;
    left: 0px;
    height: 6px;
    background: #804E3B;
    animation: progress 3.5s forwards;
}

@keyframes progress {
    from {
        width: 0px;
    }
    to {
        width: 100vw;
    }
}
