@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;1,400;1,500&display=swap');
// @import url('https://fonts.cdnfonts.com/css/neutraface-text');

@font-face {
    font-family: Neutraface Text;
    src: url('./assets/Neutraface2Text-Book.otf') format('opentype');
}

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.App {
    // overflow: hidden;
}

body {
    color: #0c0c0c;
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.35;
    height: auto;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    font-variant-numeric: lining-nums;
    overscroll-behavior: none;
}

.cs-input {
    position: relative;
    transition: 0.4s;
    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 115%;
        letter-spacing: 0.05em;
        color: #191919;
        transition: 0.2s;
        height: 10px;
    }

    input {
        border: none;
        background: transparent;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.05em;
        width: 100%;
        height: 40px;
        position: relative;

        &:hover ~ span {
            opacity: 1;
            width: 100%;
            transition: 0.2s;
            background: #686868;
        }
        &:focus {
            outline: none;
        }
        &:focus ~ span {
            background: #686868;
            opacity: 1;
            width: 100%;
            transition: 0.2s;
        }
    }
    .static-span {
        position: absolute;
        left: 0;
        top: 45px;
        transition: 0.2s;
        width: 100%;
        opacity: 1;
        height: 1px;
        background: #c0bebe;
    }
    span {
        position: absolute;
        left: 0;
        top: 45px;
        transition: 0.2s;
        width: 0;
        opacity: 1;
        height: 1px;
        background: #c0bebe;
    }
}

.cs-solid-button {
    height: fit-content;
    min-width: 331px;
    padding: 15px 65px;
    // background: #804E3B;
    background-color: transparent;
    border: none;
    color: white;
    box-shadow: none;
    font-family: 'Neutraface Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 136.5%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: inline-block;
    z-index: 100;
    max-height: 60px;
    &:hover {
        &::after {
            top: 0px;
            transition: 0.5s;
        }
    }
}

.cs-solid-button::after {
    content: '';
    position: absolute;
    top: 60px;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #382824; /* Replace with your desired background color */
    z-index: -1;
    transition: 0.5s;
}
.cs-solid-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #804e3b; /* Replace with your desired background color */
    z-index: -1;
}

.custom-buttom {
    height: 45px;
    width: 250px;
    padding: 13.5px 20px;
    padding-top: 14px;
    padding-bottom: 13px;
    background: transparent;
    border: none;
    color: white;
    box-shadow: none;
    font-family: 'Neutraface Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 136.5%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: inline-block;
    z-index: 100;
    max-height: 60px;
    &:hover {
        &::after {
            top: 0px;
            transition: 0.5s;
        }
    }
}
.custom-buttom::after {
    content: '';
    position: absolute;
    top: 60px;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #382824; /* Replace with your desired background color */
    z-index: -1;
    transition: 0.5s;
}
.custom-buttom::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #804e3b; /* Replace with your desired background color */
    z-index: -1;
}

.extra-padding {
    padding: 14px 65px !important;
}

.cs-solid-button-form {
    height: fit-content;
    min-width: 350px;
    padding: 12px 65px;
    padding-top: 13.5px;
    padding-bottom: 12px;
    background: transparent;
    border: none;
    color: white;
    box-shadow: none;
    font-family: 'Neutraface Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: inline-block;
    z-index: 100;
    max-height: 60px;
    &:hover {
        &::after {
            top: 0px;
            transition: 0.5s;
        }
    }
}
.cs-solid-button-form::after {
    content: '';
    position: absolute;
    top: 60px;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #382824; /* Replace with your desired background color */
    z-index: -1;
    transition: 0.5s;
}
.cs-solid-button-form::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: #804e3b; /* Replace with your desired background color */
    z-index: -1;
}

.cs-outline-button {
    height: fit-content;
    min-width: 331px;
    padding: 15px 65px;
    background: white;
    border: none;
    box-shadow: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 134.5%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #804e3b;
    border: 1px solid #804e3b;

    cursor: pointer;
}

.button-wrapper {
    margin-bottom: 42px;
}

.action-wrapper {
    .ant-form-item-label {
        padding: 0px !important;
    }

    label {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.98px;
        color: #686868 !important;
        transition: 0.2s;
    }
}

.ant-form-item-control-input-content textarea {
    padding-top: 5px !important;
}

.slick-dots-bottom {
    bottom: 60px !important;
}

.ant-upload-wrapper {
    margin-bottom: 12px;
}

.tesdasd {
    padding-bottom: 50px;
}

.texttt {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #774f3e;
    font-family: 'Montserrat';
    line-height: 140%;
    letter-spacing: 0.7px;

    svg {
        margin-right: 12px;
    }

    margin-bottom: 4px;
}

@media screen and (max-width: 450px) {
    .slick-dots-bottom {
        bottom: 30px !important;
    }
}

.margin-top-bottom-60 {
    @media screen and (min-height: 780px) {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    @media screen and (max-height: 780px) {
        margin-top: 0px;
        margin-bottom: 20px;
    }
}

.dragger-custom {
    border-radius: 0px;
}

.desktopV {
    @media screen and (min-width: 766px) {
        display: block;
    }

    @media screen and (max-width: 766px) {
        display: none;
    }
}

.mobileV {
    @media screen and (min-width: 768px) {
        display: none;
    }

    // @media screen and (max-width: 768px) {
    //   display: block;
    // }
}

.sl-prev-back {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    width: 7vw;
    height: 795px;

    position: absolute;
    top: 0px;
    z-index: 2;
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;

    @media screen and (min-width: 1900px) {
        height: 976px;
    }
    @media screen and (max-width: 1300px) {
        width: 16vw;
    }

    @media screen and (max-width: 760px) {
        height: 623px;
    }
}

.sl-bottom {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 1000;
    transition: 0.5s;
    bottom: 0;
}

.sl-next-back {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    width: 7vw;
    height: 795px;
    position: absolute;
    top: 0px;
    z-index: 2;
    right: 0;
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
    @media screen and (min-width: 1900px) {
        height: 976px;
    }
    @media screen and (max-width: 1300px) {
        width: 16vw;
    }

    @media screen and (max-width: 760px) {
        height: 623px;
    }
}

.sl-prev {
    position: absolute;
    top: calc(50% - 12px);
    z-index: 3;
    left: 64px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
    transition: 0.5s;
}

.sl-next {
    position: absolute;
    top: calc(50% - 12px);
    z-index: 3;
    right: 64px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
}

.hover-show-controls:hover .sl-next,
.hover-show-controls:hover .sl-next-back,
.hover-show-controls:hover .sl-prev,
.hover-show-controls:hover .sl-prev-back {
    opacity: 1;
    transition: 0.5s;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 10px 0px !important;
}
.ant-upload-wrapper .ant-upload-drag {
    height: auto;
}

.appear-bottom {
    bottom: 0px;
    transition: 0.4s ease-in;
}

.appear-top {
    top: 0px;
    transition: 0.4s ease-out;
}

.pos-absolute {
    position: absolute;
    z-index: 1000;
}

.padding-30 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;

    background: white;
}

@media screen and (min-width: 767px) and (max-width: 1000px) {
    .bg-wood {
        height: 1000px !important;
    }
}

@media screen and (max-width: 768px) {
    .fixed-mobile {
        position: fixed;
        bottom: 0px;
        left: 0px;
        background: #fff;
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
        padding-top: 17px;
        padding-bottom: 15px;
        width: 100%;
    }
}

.cs-input-2 {
    position: relative;
    transition: 0.4s;

    &:hover span {
        opacity: 1;
        width: 100%;
        transition: 0.2s;
        background: #686868;
    }

    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 115%;
        letter-spacing: 0.05em;
        color: #191919;
        transition: 0.2s;
        height: 10px;
    }
    .ant-form-item-control-input-content {
        width: 100% !important;
    }
    .ant-form-item-control-input {
        display: flex;
        flex-direction: column;
    }

    textarea {
        border: none;
        background: transparent;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.05em;
        width: 100%;
        height: 30px;
        position: relative;
        padding: 0px;
        border: none;
        background: transparent;
        font-size: 14px;
        letter-spacing: 0.05em;
        width: 100%;
        border-radius: 0px;
        box-shadow: none !important;

        &:hover ~ span {
            opacity: 1;
            width: 100%;
            transition: 0.2s;
            background: #686868;
            margin-top: -25px;
        }
        &:focus {
            outline: none;
        }
        &:focus ~ span {
            background: #686868;
            opacity: 1;
            width: 100%;
            transition: 0.2s;
            margin-top: -25px;
        }
    }

    .static-span-form-textarea {
        // position: static;
        // display: block;
        // transition: 0.2s;
        // width: 100% !important;
        // opacity: 1;
        // height: 1px;
        // background: #c0bebe;
        // margin-top: -25px;

        position: absolute;
        left: 0;
        top: 30px;
        transition: 0.2s;
        width: 100%;
        opacity: 1;
        height: 1px;
        background: #c0bebe;
    }
    .static-span-form-textarea-2 {
        position: static;
        display: block;

        transition: 0.2s;
        // width: 0;
        opacity: 1;
        // height: 1px;
        background: transparent !important;
        // margin-top: -1px;
        margin-bottom: 60px;
    }

    input {
        border: none;
        background: transparent;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.05em;
        width: 100%;
        height: 30px;
        position: relative;
        padding: 0px;
        border: none;
        background: transparent;
        font-size: 14px;
        letter-spacing: 0.05em;
        width: 100%;
        border-radius: 0px;
        box-shadow: none !important;

        &:focus {
            outline: none;
        }
        &:focus ~ span {
            background: #686868;
            opacity: 1;
            width: 100%;
            transition: 0.2s;
        }
    }
    .static-span-form {
        position: absolute;
        left: 0;
        top: 30px;
        transition: 0.2s;
        width: 100%;
        opacity: 1;
        height: 1px;
        background: #c0bebe;
    }
    span {
        position: absolute;
        left: 0;
        top: 30px;
        transition: 0.2s;
        width: 0;
        opacity: 1;
        height: 1px;
        background: #c0bebe;
    }
}

.mont-font {
    font-family: 'Montserrat' !important;
}

.slider-image {
    position: relative;
    width: 100% !important;
    // height: 0px;
    overflow: hidden;
    background-color: #f7f7f7; // f7f7f7
}

.zIndexBlock {
    position: absolute;
    width: 100% !important;
    height: 720px;
    top: 0px;

    // @media (max-width: 1300px) {
    //     height: 100%;

    //     .slider-image {
    //         height: 100% !important;
    //     }

    //     img {
    //         height: 100% !important;
    //     }
    // }
}

.service-desktop {
    // height: 0px;
    position: absolute;
    width: 900px !important;
    @media screen and (max-width: 1450px) {
        width: 680px !important;
    }
    object-fit: cover;
    object-position: bottom;
    transform: scale(1.15);
    // transition: all 0.8s ease-out 0s;
}

@keyframes top-bottom {
    from {
        height: 0px;
    }
    to {
        height: 720px;
    }
}

@keyframes bottom-top {
    from {
        height: 720px;
    }
    to {
        height: 0px;
    }
}

@keyframes top-bottom-mobile {
    from {
        height: 0px;
    }
    to {
        height: 290px;
    }
}

@keyframes bottom-top-mobile {
    from {
        height: 290px;
    }
    to {
        height: 0px;
    }
}

.text-under {
    cursor: pointer;
    text-decoration: underline;
    color: #686868;
}

@media screen and (min-width: 768px) {
    .desktopV {
        display: block !important;
    }
    .mobileV {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .desktopV {
        display: none !important;
    }
    .mobileV {
        display: block !important;
    }
}

@media screen and (max-width: 370px) {
    .super-width {
        // width: 93%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.bottom-40 {
    margin-bottom: 40px !important;
}

.ant-form-item-control div::nth-child(2n) {
    height: 0px;
}
