.carousel_item {
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 795px;
    // object-position: center;
    // transition: width 0.6s ease;
    // animation-timing-function: linear;

    img {
        position: absolute;
        object-fit: cover;
        // overflow: hidden;
        // left: 0;
        width: 100vw;
        max-width: none;
        transition: all 0.6s ease-out;
        // transition: transform 0.5s linear 0.2s, height 0.5s linear;
        bottom: 0;
        // object-position: right;
    }

    @media screen and (min-width: 1900px) {
        height: 976px;
    }
    @media screen and (max-width: 760px) {
        height: 623px;
    }

    &.collapse-left {
        position: relative;
        width: 0%;

        img {
            object-position: right;
        }
    }
    &.collapse-right {
        position: relative;
        width: 100%;

        img {
            object-position: left;
        }
    }
}

.carousel_container {
    height: 795px;
    position: relative;

    @media screen and (min-width: 1900px) {
        height: 976px;
    }

    @media screen and (max-width: 760px) {
        height: 623px;
    }
}

.carousel_image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &_wrapper {
        position: absolute;
        width: 0%;
        height: 100%;
        overflow: hidden;

        &_first {
            width: 100%;
        }
    }

    &_scaled {
        transform: scale(1.15);
    }
}

.carousel__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
}

.carousel-content {
    background-color: #fff;
    padding: 20px;
    color: #1a1a1a;
    position: absolute;
    bottom: 40px;
    left: 50px;
    font-family: Arial;

    h2 {
        font-size: 28px;
        letter-spacing: -0.7px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin: 0 0 15px 0;
    }
}

.carousel_wrapper {
    position: relative;
    height: 795px;
    overflow: hidden;
    position: relative;
    @media screen and (min-width: 1900px) {
        height: 976px;
    }
    @media screen and (max-width: 760px) {
        height: 623px;
    }
}

.carousel {
    &-dots {
        display: flex;
        justify-content: center;
        column-gap: 10px;

        &-wrapper {
            position: absolute;
            bottom: 55px;
            z-index: 9999;
            width: 100%;
        }
    }

    &-dot {
        width: 45px;
        height: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            width: 34px;
        }

        span {
            display: block;
            height: 3px;
            background-color: #fff;
            width: 100%;
            opacity: 0.3;
            @media screen and (max-width: 768px) {
                height: 2px;
            }
        }

        &-active {
            span {
                opacity: 1;
            }
        }
    }
}

.nav-btn {
    background-color: #fff;
    font-size: 20px;
    border: 1px solid #ddd;
    padding: 10px 10px 8px 10px;
    margin: 0;
    line-height: 18px;
    border-radius: 0;
    cursor: pointer;
    border-radius: 3px 0 0 3px;
    transition: background-color 0.25s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: #fafafa;
    }

    &:last-child {
        border-left: none;
        border-radius: 0px 3px 3px 0px;
    }
}

.nav-controls {
    display: flex;
    justify-content: flex-start;
}

.points {
    &-window {
        width: 320px;
        max-width: 320px;
        height: 30px;
        margin: 0 auto;
        display: block;
        overflow: hidden;
        position: relative;
    }

    &-track {
        display: flex;
        position: absolute;
        top: 0;
        height: 30px;

        span {
            width: 320px;
            height: 30px;
            text-align: center;
        }
    }
}

@keyframes hideLeftSlide {
    from {
        left: 0;
        width: 100%;
    }
    to {
        left: 0;
        width: 0%;
        transform: scale(1);
    }
}

@keyframes hideRightSlide {
    from {
        right: 0;
        width: 100%;
    }
    to {
        right: 0;
        width: 0%;
        transform: scale(1);
    }
}

@keyframes animateToFirst {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(-320px);
    }
}

@keyframes animateToLast {
    from {
        transform: translateX(-1920px);
    }
    to {
        transform: translateX(-1600px);
    }
}

@keyframes animateToLastMobile {
    from {
        transform: translateX(-3200px);
    }
    to {
        transform: translateX(-2880px);
    }
}
