.large-desktop {
    display: inherit;
}

.desktop {
    display: inherit;
}

.tablet {
    display: inherit;
}

.mobile {
    display: inherit;
}

@media screen and (min-width: 1920px) {
    .large-desktop {
        display: inherit;
    }

    .desktop {
        display: none;
    }

    .tablet {
        display: none;
    }

    .mobile {
        display: none;
    }

    .section-three-wrapper {
        // .text-col {
        //     padding: 60px 0px 0px 152px !important;
        // }

        img {
            // width: 900px !important;
            height: 540px;

            &:nth-child(2) {
                float: right !important;
            }
        }
    }

    .section-five-wrapper {
        .text-col {
            padding: 60px 0px 60px 152px !important;
        }

        img {
            width: 900px !important;
            // height: 975px !important;
        }
    }

    .section-six-wrapper {
        .action-wrapper {
            padding: 0px 212px !important;
        }
    }

    .section-nine-wrapper {
    }
}

@media screen and (min-width: 1301px) and (max-width: 1919px) {
    .large-desktop {
        display: none;
    }

    .desktop {
        display: inherit;
    }

    .tablet {
        display: none;
    }

    .mobile {
        display: none;
    }
}

@media screen and (min-width: 451px) and (max-width: 1300px) {
    .large-desktop {
        display: none;
    }

    .desktop {
        display: none;
    }

    .tablet {
        display: inherit;
    }

    .mobile {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .large-desktop {
        display: none;
    }

    .desktop {
        display: none;
    }

    .tablet {
        display: none;
    }

    .mobile {
        display: inherit;
    }

    .section-four-wrapper {
        .text {
            // padding: 0px 43px !important;
            width: unset !important;
        }

        .action {
            flex-direction: column !important;
        }
    }

    .section-five-wrapper {
        img {
            height: 290px !important;
        }
    }

    .sl-prev-back {
        display: none;
    }

    .sl-next-back {
        display: none;
    }
}

.ant-carousel .slick-dots li {
    width: 45px !important;
    @media screen and (max-width: 768px) {
        width: 34px !important;
    }
}
.ant-carousel .slick-dots li button {
    border-radius: 0px;
}

.ant-upload-drag {
    border: 1px solid #ababab !important;
    border-radius: 0px !important;
    background: white !important;
}
