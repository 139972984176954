.policy {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    padding: 0 60px;
    margin: 96px auto 100px auto;

    // @media (max-width: 1920px) {
    //     max-width: 1380px;
    // }
    @media (max-width: 1440px) {
        padding: 0 30px;
    }
    @media (max-width: 1024px) {
        padding: 0 20px;
        max-width: 676px;
    }

    @media (max-width: 500px) {
        margin: 50px auto 70px auto;
    }

    &__header {
        display: grid;
        justify-content: space-between;
        column-gap: 20px;
        grid-template-columns: 32.6% 62%;
        height: 100%;

        @media (max-width: 1440px) {
            grid-template-columns: 34% 64%;
        }

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
            justify-content: center;
            text-align: center;
            row-gap: 80px;
        }

        @media (max-width: 500px) {
            row-gap: 70px;
        }

        &_head {
            color: #804e3b;
            font-family: Neutraface Text;
            font-size: 34px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 2.38px;
            text-transform: uppercase;
            margin-bottom: 14px;
            max-width: 300px;

            @media (max-width: 1024px) {
                margin: 0 auto;
            }
        }

        &_date {
            color: #191919;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.56px;
        }

        &_right {
            color: #686868;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.56px;
            max-width: 870px;
            margin-right: 17%;

            @media (max-width: 1200px) {
                margin-right: 0;
            }

            @media (max-width: 500px) {
                text-align: left;
            }
        }
    }

    &__section {
        display: grid;
        justify-content: space-between;
        column-gap: 20px;
        grid-template-columns: 32.6% 62%;

        @media (max-width: 1440px) {
            grid-template-columns: 34% 64%;
        }
        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }

        &_points {
            margin-top: 130px;
            overflow: visible;
            position: relative;
            height: 100%;

            @media (max-width: 1024px) {
                display: none;
            }

            ul {
                border-top: solid 1px #a0a0a0;
                padding-top: 30px;
                position: sticky;
                top: 30px;
                left: 0;
                margin: 0;
                display: block;
                position: -webkit-sticky;
            }
        }

        &_point {
            color: #191919;
            font-family: Neutraface Text;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.98px;
            text-transform: uppercase;
            margin-bottom: 20px;
            list-style-type: none;
            cursor: pointer;

            &-active {
                color: #804e3b;
                position: relative;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: block;
                    width: 4px;
                    height: 21px;
                    background: #804e3b;
                    position: absolute;
                    left: -60px;

                    transition: all 0.5s ease;

                    @media (max-width: 1440px) {
                        left: -30px;
                    }
                }
            }
        }

        &_block {
            margin-bottom: 100px;
        }

        &_item {
            list-style-type: none;
            border-top: solid 1px #a0a0a0;
            margin-top: 130px;

            @media (max-width: 1024px) {
                margin-top: 100px;
            }

            @media (max-width: 500px) {
                margin-top: 80px;
            }

            p {
                color: #191919;
                font-family: Neutraface Text;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 1.54px;
                text-transform: uppercase;
                margin-bottom: 60px;
                max-width: 820px;
                margin-right: 17%;
                padding-top: 30px;

                @media (max-width: 1200px) {
                    margin-right: 0;
                }
            }

            span {
                display: block;
                color: #191919;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.56px;
                margin-bottom: 30px;
                max-width: 820px;
                margin-right: 17%;

                @media (max-width: 1200px) {
                    margin-right: 0;
                }
            }

            &-text {
                color: #686868;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.56px;
                margin-bottom: 30px;
                max-width: 820px;
                margin-right: 17%;

                &-table {
                    margin-right: 0;

                    @media (max-width: 500px) {
                        margin: 80px 0 50px 0;
                    }
                }

                @media (max-width: 1200px) {
                    margin-right: 0;
                }
            }

            &-bullet {
                list-style: none !important;
                padding-left: 20px !important;

                li::before {
                    content: '\2022';
                    color: #804e3b;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }

            &-table {
                list-style: none !important;

                &-row {
                    p {
                        color: #686868;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        letter-spacing: 0.56px;
                        text-transform: none;
                        margin-top: 0;

                        @media (max-width: 1024px) {
                            margin-bottom: 80px;
                        }
                        @media (max-width: 500px) {
                            margin-bottom: 30px;
                            padding-top: 15px !important;
                        }
                    }
                }

                &-title {
                    color: #191919;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    letter-spacing: 0.56px;

                    @media (max-width: 1024px) {
                        margin-bottom: 0 !important;
                    }
                }

                &-header {
                    color: #804e3b;
                    font-family: Neutraface Text;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 1.54px;
                    text-transform: uppercase;
                    padding-top: 30px;

                    @media (max-width: 500px) {
                        padding-bottom: 80px;
                    }
                }

                &-titles {
                    display: grid;
                    column-gap: 55px;

                    @media (min-width: 1024px) {
                        display: none;
                        border-top: none;
                    }

                    @media (max-width: 1024px) {
                        border-top: solid 1px #a0a0a080;
                        justify-content: space-between;
                        grid-template-columns: 24% 49%;
                        padding-top: 30px;

                        div:nth-child(1) {
                            grid-area: 1 / 1 / 2 / 2;
                        }
                        div:nth-child(2) {
                            grid-area: 1 / 2 / 2 / 3;
                        }
                        div:nth-child(3) {
                            grid-area: 2 / 2 / 3 / 3;
                        }
                    }

                    @media (max-width: 500px) {
                        grid-template-columns: 1fr;

                        div:nth-child(1) {
                            grid-area: 1 / 1 / 2 / 2;
                        }
                        div:nth-child(2) {
                            grid-area: 2 / 1 / 3 / 2;
                        }
                        div:nth-child(3) {
                            grid-area: 3 / 1 / 4 / 2;
                        }
                    }

                    &-inner {
                        border-top: solid 1px #a0a0a080;
                        display: grid;
                        grid-template-columns: 18% 30% 36%;

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }

                    &-wide {
                        display: grid;
                        grid-template-columns: 18% 30% 36%;

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
