.footer-wrapper {
    padding: 60px 60px 130px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 100%;
    gap: 60px;

    .footer-wrapper-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .heading {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #191919;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-bottom: 11px;
    }

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #686868;
        display: block;
        text-decoration: none;
        margin-bottom: 4px;

        &:first-child {
            margin-bottom: 7px;
        }
    }

    .social {
        display: flex;
        justify-content: left;
        @media screen and (max-width: 1300px) {
            justify-content: center;
        }
        gap: 15px;

        a {
            svg path {
                transition: 0.3s;
            }
            &:hover {
                svg path {
                    fill: #804e3b;
                    transition: 0.3s;
                }
            }
        }
        svg {
            height: 22px;
            width: 22px;
        }
    }
}

@media screen and (min-width: 451px) and (max-width: 1300px) {
    .footer-wrapper {
        padding: 60px 60px 130px 60px;

        .footer-wrapper-item {
            gap: 60px;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            text-align: center;
            align-items: center;
        }
    }

    .mt16 {
        margin-top: 16px;
    }
}

@media screen and (max-width: 451px) {
    .logo-mobile {
        width: 90px;
        height: 38.002px;
    }
    .footer-wrapper {
        padding: 80px 0px 130px 0px;
        flex-direction: column;

        .footer-wrapper-item {
            gap: 60px;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            text-align: center;
            align-items: center;
        }

        .item {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #f7f7f7;
            padding-bottom: 40px;
            padding-top: 40px;
        }

        .heading {
            font-size: 14px;
        }

        .item-logo {
            display: flex;
            justify-content: center;
            padding-top: 20px;
        }

        .text {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }

    .mt16 {
        margin-top: 16px;
    }
}
