.main-section {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);

    &-orange {
        height: 120px;
        background: none;

        .contact-us-main-button {
            color: #000 !important;

            @media (max-width: 500px) {
                display: none !important;
            }
        }
    }

    .logo {
        position: absolute;
        top: 30px;
        text-align: center;
        width: 100%;
        z-index: 4;
    }

    img {
        width: 100%;
        object-position: 50% 80%;
        height: 100vh;
        object-fit: cover;
        transform: scale(1.2);
        animation: scaleBack 300ms ease-out forwards;
        animation-delay: 4s;
        @media screen and (min-width: 500px) and (max-width: 900px) {
            height: 50vh;
        }
    }
}

.graaa {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    height: 30vh;
    position: absolute;
    z-index: 3;
    width: 100%;
}

.contact-us-wrapper {
    min-width: 210px;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 4;
    a {
        text-decoration: none;
        color: black;
        transition: 0.3s;

        &:hover {
            color: #774f3e;
            transition: 0.3s;
        }
    }

    .contact-us-button {
        cursor: pointer;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 16px;
        leading-trim: both;
        text-edge: cap;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000000;
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 134.5%;
        transition: 0.3s;
        height: 52px;

        &:hover {
            color: #774f3e;
            transition: 0.3s;
            svg path {
                fill: #774f3e;
                transition: 0.3s;
            }
            a {
                text-decoration: none;
                color: black;
                transition: 0.3s;
                color: #774f3e;
            }
        }
    }

    .contact-us-main-button {
        cursor: pointer;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        gap: 12px;
        leading-trim: both;
        text-edge: cap;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 134.5%;

        svg {
            @media screen and (min-width: 768px) {
                margin-top: -5px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contact-us-wrapper {
        right: 30px;
        min-width: 0px;
    }
    .contact-us-main-button {
        padding: 15px 0px !important;
    }
}
@media screen and (max-width: 500px) {
    .contact-us-wrapper {
        min-width: 200px;
        position: absolute;
        bottom: 0px !important;
        top: unset;
        right: 0px;
        width: 100%;

        #path1 {
            stroke: #000000;
        }

        .contact-us-button {
            cursor: pointer;
            background: #ffffff;
            margin-left: 20px;
            margin-right: 20px;

            .contact-us-into {
                border-bottom: 1px solid #1919192e;
                padding-bottom: 16px;
                padding-top: 18px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 100%;

                svg {
                    margin-top: -3px;
                }
                a {
                    text-decoration: none;
                    color: black;
                }
            }
            .contact-us-into-bottom {
                border-bottom: 0px solid #1919192e;
                padding-bottom: 16px;
                padding-top: 18px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 100%;

                svg {
                    margin-top: -3px;
                }
            }
        }

        .contact-us-main-button {
            cursor: pointer;
            background: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 20px 25px;
            padding-top: 22px;
            gap: 16px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #000000;
            font-family: 'Neutraface Text';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 90%;
            margin-top: 12px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

            svg {
                margin-top: -4px;
            }
        }
    }
}

@keyframes scaleBack {
    to {
        transform: scale(1);
    }
}
