.main-wrapper {
    position: relative;
    display: block;
    height: auto;
    min-height: 100vh;
    width: 100%;
}

.section-one-wrapper {
    padding: 130px 0px;
    text-align: center;

    .mobileV {
        @media screen and (max-width: 760px) {
            display: block;
        }

        @media screen and (min-width: 760px) {
            display: none;
        }
    }

    .desktopV {
        @media screen and (min-width: 760px) {
            display: block;
        }

        @media screen and (max-width: 760px) {
            display: none;
        }
    }

    .text {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 47.6px;
        text-align: center;
        text-transform: uppercase;
        color: #804e3b;

        @media screen and (max-width: 767px) {
            font-size: 20px !important;
            width: 350px;
            margin-left: auto;
            margin-right: auto;
            line-height: 140%;
            letter-spacing: 1.4px;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 34px !important;
        }
    }
}

.section-two-wrapper {
}

.section-three-wrapper {
    padding: 60px;

    img {
        width: 100%;
        height: 0%;
        object-fit: cover;
        object-position: 50% 96%;
        bottom: 0;
        position: absolute;
        transition: height 0.5s ease-out, transform 0.5s ease-in 0.1s;
    }

    &-image {
        height: 540px;
        position: relative;
        overflow: hidden;
        background-color: #f7f7f7; // f7f7f7

        @media (max-width: 450px) {
            height: 290px;
        }
    }

    .text-col {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (min-width: 1300px) {
        .desktop-margin-top {
            margin-top: -60px;
        }
        .desktop-margin-bottom {
            // height: calc(100% + 60px);
            margin-top: 60px;
        }
    }

    .mb32 {
        margin-bottom: 30px;
    }

    .mb16 {
        margin-bottom: 16px;
    }

    .heading {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #191919;
        width: 536px;

        @media screen and (max-width: 767px) {
            width: 320px;
        }
    }

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #686868;
        max-width: 485px;
        margin-right: 51px;

        @media screen and (max-width: 767px) {
            width: 320px;
            margin-right: 0px;
        }
        @media screen and (max-width: 1300px) {
            margin-right: 0px;
        }
    }
    .highlight-container {
        width: 536px;
        display: flex;
        justify-content: left;
        height: 30px;
        @media screen and (max-width: 1300px) {
            justify-content: center;
        }
    }

    .highlight {
        letter-spacing: 0.98px;

        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        color: #804e3b;
        width: auto;
        cursor: pointer;
        position: relative;
        white-space: nowrap;

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0;
            left: 0;
            top: 23px;
            width: 0;
            background: #804e3b;
            opacity: 0;
            transition: 0.4s;
            @media screen and (max-width: 768px) {
                width: 100%;
                opacity: 1;
            }
        }
        &:hover {
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                bottom: 0;
                left: 0;
                top: 23px;
                width: 100%;
                background: #804e3b;
                opacity: 1;
                transition: 0.4s;
            }
        }
    }
}

.section-four-wrapper {
    background: #f7f7f7;
    padding: 130px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        color: #804e3b;
        margin-bottom: 100px;
        height: 43px;
        width: 450px;

        @media screen and (max-width: 768px) {
            width: 320px;
        }
        @media screen and (min-width: 767px) and (max-width: 1000px) {
            width: 495px;
        }
    }

    .action {
        display: flex;
        gap: 62px;

        @media screen and (max-width: 768px) {
            width: 320px;
            flex-direction: column;
        }
        @media screen and (min-width: 767px) and (max-width: 1000px) {
            width: auto;
            flex-direction: row;
            gap: 31px;
        }

        input {
            min-width: 320px;
        }

        button {
            width: 250px;
            margin-left: 35px;
        }
    }
}

.section-five-wrapper {
    padding: 60px;
    @media screen and (min-width: 767px) and (max-width: 1050px) {
        padding-left: 60px;
        padding-right: 20px;
    }

    .mb32 {
        margin-bottom: 32px;
    }

    .mb16 {
        margin-bottom: 16px;
    }

    .heading {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 140%;
        text-transform: uppercase;
        // color: #854d3b;
        flex: none;
        order: 0;
        flex-grow: 0;
        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        // color: #686868;
        flex: none;
        order: 0;
        flex-grow: 0;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.64px;

        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
        @media screen and (min-width: 768px) {
            width: fit-content;
        }
    }

    .active-text {
        color: #854d3b !important;
    }

    .highlight {
        letter-spacing: 0.98px;
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        width: 158px;
        cursor: pointer;
        position: relative;
        white-space: nowrap;
        height: 30px;

        &_mobile {
            color: #804e3b;
        }

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0;
            left: 0;
            top: 23px;
            width: 0;
            background: #804e3b;
            opacity: 0;
            transition: 0.4s;
            @media screen and (max-width: 768px) {
                width: 100%;
                opacity: 1;
            }
        }
        &:hover {
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                bottom: 0;
                left: 0;
                top: 23px;
                width: 100%;
                background: #804e3b;
                opacity: 1;
                transition: 0.4s;
            }
        }
    }

    .text-col {
        padding: 60px 0px 60px 60px;
        @media screen and (max-width: 1050px) {
            padding: 65px 0px 60px 0px;
        }
        @media screen and (max-width: 768px) {
            padding: 20px 0px 60px 0px;
        }
        text-align: left;
    }
}

.section-six-wrapper {
    padding: 130px 0px;

    .action-wrapper {
        position: relative;
        color: white;
        text-align: center;
        padding: 0px 60px;

        .heading {
            font-family: 'Neutraface Text';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 140%;
            text-transform: uppercase;
            color: #ffffff;
            margin-bottom: 100px;
        }

        .text {
            font-family: 'Neutraface Text';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 140%;
            text-transform: uppercase;
            color: #ffffff;
        }

        .text-wrapper {
            display: flex;
            justify-content: space-around;
        }

        .text-br {
            border-left: 0.5px solid #a0a0a0;
        }
    }
}

.carousel__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    position: relative;
    // transform: none !important;
}

.swiper-slide {
    // position: relative !important;
    // top: 0px;
    // left: 0;
    // width: 100% !important;
    // height: 100% !important;
    // overflow: hidden;
    // transition: width 0.6s ease-in-out;

    // &-prev {
    //     position: relative !important;

    //     width: 0% !important;
    // }
}

.swiper-pagination {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 10;
    bottom: 60px;
    column-gap: 10px;
    cursor: pointer;

    &-bullet {
        width: 45px;
        height: 15px;
        background-color: transparent;
        opacity: 0.3;
        display: block;
        display: flex;
        align-items: center;
        position: relative;

        &-active {
            opacity: 1;
        }

        span {
            display: block;
            width: 45px;
            height: 3px;
            background-color: #fff;
            top: 50%;
            position: absolute;
        }
    }
}

.bullet {
    width: 45px;
    height: 3px;
    background-color: #fff;
    display: block;
}

.section-seven-wrapper {
    img {
        width: 100vw;
        height: 795px;
        @media screen and (min-width: 1900px) {
            height: 976px;
        }
        @media screen and (max-width: 760px) {
            height: 623px;
        }
        object-fit: cover;
    }

    .text {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        letter-spacing: 0.72px;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
    }
}

.section-eight-wrapper {
    padding: 130px 0px;
    text-align: center;

    @media screen and (max-width: 768px) {
        padding: 80px 0px;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .mb64 {
        margin-bottom: 60px;
    }

    .mb8 {
        margin-bottom: 8px;
    }

    .mb16 {
        margin-bottom: 16px;
    }

    .heading {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 140%;
        text-transform: uppercase;
        color: #804e3b;
        flex: none;
        order: 0;
        flex-grow: 0;
        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #686868;
        letter-spacing: 0.64px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
        @media screen and (min-width: 767px) and (max-width: 1000px) {
            letter-spacing: 1.54px;
        }
    }

    .support {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #686868;
        margin-top: 20px;
        letter-spacing: 0.56px;
        @media screen and (max-width: 768px) {
            width: 317px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .text-active {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        text-transform: uppercase;
        color: #854d3b;
        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }

    .highlight {
        letter-spacing: 0.98px;

        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        // color: #804e3b;
        width: 60px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        position: relative;
        white-space: nowrap;
        height: 30px;

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0;
            left: 0;
            top: 23px;
            width: 0;
            background: #804e3b;
            opacity: 0;
            transition: 0.4s;
            @media screen and (max-width: 768px) {
                width: 100%;
                opacity: 1;
            }
        }
        &:hover {
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                bottom: 0;
                left: 0;
                top: 23px;
                width: 100%;
                background: #804e3b;
                opacity: 1;
                transition: 0.4s;
            }
        }
    }
}

.section-nine-wrapper {
    background: #f7f7f7;
    padding: 130px 0px;

    @media screen and (max-width: 768px) {
        padding: 80px 0px;
        .section-nine-wrapper {
            padding: 130px 22px;
            width: 341px !important;
            max-width: 341px;
        }
    }

    .ant-col {
        text-align: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .heading {
        font-family: 'Neutraface Text';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        text-transform: uppercase;
        color: #191919;
        margin-bottom: 30px;
    }

    .text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #686868;
        margin-bottom: 30px;
        max-width: 643px;

        @media screen and (max-width: 768px) {
            width: 339px !important;
            max-width: 303px;
        }
    }
}

.enquire-drawer-wrapper {
    .ant-modal-content {
        border-radius: 0px;
        // height: 100%;
        min-height: 100vh;
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 768px) {
            padding-left: 115px;
            padding-right: 115px;
        }
    }

    .enquire-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .close-wrapper {
            border-radius: 1000px;

            align-self: flex-end;
            margin-bottom: 42px;
            position: absolute;
            top: 20px;
            right: 20px;
            height: 42px;
            width: 42px;
        }

        .close-wrapper-left {
            align-self: flex-start;
            margin-bottom: 42px;
        }

        .close {
            border-radius: 1000px;
            cursor: pointer;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 42px;
            width: 42px;
            svg {
                border-radius: 1000px;
                margin-top: -4px;
                margin-left: -4px;
            }
        }

        .heading {
            font-family: 'Neutraface Text';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 140%;
            text-transform: uppercase;
            color: #191919;
            margin-bottom: 16px;
            @media screen and (max-width: 760px) {
                width: 286px;
                margin-top: 62px;
            }
        }

        .ant-checkbox {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 160%;
            text-align: center;
            color: #686868;
        }

        .text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: #686868;
            margin-bottom: 60px;
            max-width: 430px;
            text-align: center;
            @media screen and (max-width: 760px) {
                width: 341px;
            }
        }

        .action-wrapper {
            text-align: left;
            margin-bottom: 42px;
        }
    }
}

.mobile-margin {
    height: 60px;
    width: 100%;
}

@media screen and (min-width: 451px) and (max-width: 1300px) {
    .section-one-wrapper {
        .text {
            font-size: 36px;
        }
    }

    .section-three-wrapper {
        padding: 0px;

        .text-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 130px 0px;
        }

        .text-col-left {
            padding-left: 0px;
        }
    }

    .section-four-wrapper {
        input,
        .cs-solid-button {
            min-width: 220px !important;
        }
    }

    .section-six-wrapper {
        padding: 160px 0px;
    }
}

@media screen and (max-width: 450px) {
    .section-five-wrapper {
        img {
            height: 290px !important;
        }
    }

    .test {
        .ant-drawer-content-wrapper {
            width: 100vw !important;
        }

        .close-wrapper {
            margin-bottom: 32px !important;
        }

        .heading {
            font-size: 18px !important;
        }
    }

    .section-one-wrapper {
        padding: 111px 0px;

        .text {
            font-size: 26px;
        }
    }

    .section-three-wrapper {
        img {
            width: 100%;
            height: 290px;
        }

        padding: 0px;

        .text-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 80px 20px;
        }

        .ant-col {
            width: 100%;
        }

        // .mb32 {
        //     margin-bottom: 32px;
        // }

        // .mt32 {
        //     margin-top: 32px;
        // }

        .heading {
            font-size: 18px;
        }

        .highlight {
            font-family: 'Neutraface Text';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #804e3b;
            max-width: 435px;
            cursor: pointer;
            white-space: nowrap;
        }
    }

    .section-five-wrapper {
        padding: 0px;
        text-align: center;

        .text {
            text-align: center;
        }

        .heading {
            margin-top: 60px;
            text-align: center;
        }

        .highlight {
            text-align: center;
            cursor: pointer;

            margin-bottom: 12px;
            margin: auto;

            white-space: nowrap;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .section-six-wrapper {
        margin-top: 20px;

        .text-wrapper {
            flex-direction: column;
            align-items: center;
            gap: 30px;

            .text-br {
                width: 64px;
                border-top: 1px solid white;
            }
        }

        .section-seven-wrapper {
            .text {
                bottom: 60px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .section-eight-wrapper {
            padding: 80px 20px;

            .heading {
                font-size: 18px;
            }

            .text {
                font-size: 16px;
            }
        }

        .section-nine-wrapper {
            padding: 80px 20px;

            .mobile-padding {
                // margin-top: 60px;
            }
        }

        .section-four-wrapper {
            .action {
                flex-direction: column;
            }

            .text {
                padding: 0px 20px;
            }
        }
    }

    .mb36 {
        margin-top: 36px;
    }

    .text-under {
        text-decoration: underline;
    }
}

.animated-text-wrapper {
    overflow: hidden;
}

.animated-text {
    display: inline;
    position: relative;
    top: 0px;
    animation: fade-in-brown 1s forwards;
    float: left;
    // margin: 0 auto;
    width: 100%;
    color: #fff;

    &-disabled {
        color: #fff !important;
    }
    &-disabled-gray {
        color: #f7f7f7 !important;
    }

    &-gray {
        color: #f7f7f7;
    }

    &-default {
        animation: fade-in-default 1s forwards;
    }

    &-black {
        animation: fade-in-black 1s forwards;
    }

    &-right {
        animation: fade-in-right 1s forwards;
    }

    &-right-brown {
        animation: fade-in-right-brown 1s forwards;
    }

    &-brown-dark {
        animation: fade-in-brown-dark 1s forwards;
    }
}

@keyframes fade-in-brown {
    0%,
    20% {
        transform: translateY(100%);
        color: #804e3b;
    }
    80%,
    100% {
        transform: translateY(0);
        color: #804e3b;
    }
}

@keyframes fade-in-default {
    0%,
    20% {
        transform: translateY(100%);
        color: #686868;
    }
    80%,
    100% {
        transform: translateY(0);
        color: #686868;
    }
}

@keyframes fade-in-brown-dark {
    0%,
    20% {
        transform: translateY(100%);
        color: #854d3b;
    }
    80%,
    100% {
        transform: translateY(0);
        color: #854d3b;
    }
}

@keyframes fade-in-black {
    0%,
    20% {
        transform: translateY(100%);
        color: #191919;
    }
    80%,
    100% {
        transform: translateY(0);
        color: #191919;
    }
}

@keyframes fade-in-right {
    0%,
    20% {
        transform: translateX(-20%);
        color: #fff;
    }
    80%,
    100% {
        transform: translateX(0);
        color: #686868;
    }
}

@keyframes fade-in-right-brown {
    0%,
    20% {
        transform: translateX(-20%);
        color: #fff;
    }
    80%,
    100% {
        transform: translateX(0);
        color: #804e3b;
    }
}
